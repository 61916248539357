import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import moment from 'moment';
import './ChallanList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { parseDate } from '@telerik/kendo-intl';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Grid, GridColumn as Column, } from '@progress/kendo-react-grid';
import { getter } from "@progress/kendo-react-common";
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';
import { formatNumber } from '../../utils/UtilityFunctions';

import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import editIcon from '../../images/icons/edit.svg';
import {getCorrectionChallans} from '../../api/challanAPI';
import AddChallan from './AddChallan';
import EditChallan from './EditChallan';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import EFilingConnectorForImportChallan from './EFilingConnectorForImportChallan';
import Skeleton12ColumnGrid from '../../components/Skeleton/Skeleton12ColumnGrid';



const ChallanList = ({ history, ...props }) => {
  const { consoStatus } = props;
  let { deductorId } = props.match.params;

  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);

  if (params.deductor_id) {
    deductorId = params.deductor_id;
  }

  const DATA_ITEM_KEY = "challan_id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  let nextPageUrl = '/';
  let paramsVals = '';
  let returnID = '';
  if (location.state && location.state.nextPageUrl) {
    nextPageUrl = location.state.nextPageUrl;
  }

  if (location.state) {
    if (location.state.paramsVals) {
      paramsVals = location.state.paramsVals;
    } else {
      paramsVals = location.state.paramVals;
    }
    if (paramsVals) {
      const firstReplace = paramsVals.replace('?', '');
      const firstSplit = firstReplace.split('&');
      firstSplit.map((key) => {
        const arrayValue = key.split('=');
        if (arrayValue[0] && arrayValue[0] === 'return_id') {
          returnID = arrayValue[1];
        }
        return true;
      });
    }
  }
  if (returnID !== '') {
    let obj = {
      "return_id": returnID,
      "nextPageUrl": nextPageUrl
    }
    localStorage.setItem('values', JSON.stringify(obj));
  }

  if (params.return_id) {
    returnID = params.return_id;
  }


  const [port, setPort] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const [challanList, setChallanList] = useState([]);
  const [skeletonList, setSkeletonList] = useState(true);
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const [filters] = useState({
    nature_of_payment_id: [],
    from_deposit_date: '',
    to_deposit_date: '',
    from_total_amtp: null,
    to_total_amtp: null,
    from_total_unconsumed_amtp: null,
    to_total_unconsumed_amtp: null,
    verified_status: [],
  });

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editChallanInfo, setEditChallanInfo] = useState({});

  const [checkedChallanData, setCheckedChallanData] = useState({});
  const [allCheckData, setAllCheckData] = useState(false);
  const [selectedChallanCount, setSelectedChallanCount] = useState(0);
  const [showImportChallan, setShowImportChallan] = useState(false);
  const [showDateRangeBox, setShowDateRangeBox] = useState(false);
  const [showAmountRangeBox, setShowAmountRangeBox] = useState(false);
  const [showUnmappedAmountRangeBox, setShowUnmappedAmountRangeBox] = useState(false);

  const [filterPostData, setFilterPostData] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [dataState, setDataState] = useState({});
  const [statusFilter, setStatusFilter] = useState([]);
  const [challanSummary, setChallanSummary] = useState({});
  const [currentChallan, setCurrentChallan] = useState(0);
  const [horizontalView, setHorizontalView] = useState(false);
  // const [startImportOltasChallans, setStartImportOltasChallans] = useState(false);
  const limit = 15;

  const [startEfilingProcess, setStartEfilingProcess] = useState(false);
  const [startTracesProcess, setStartTracesProcess] = useState(false);

  const getFilterStr = (filtersObj) => {
    let filterStr = '?page=1';
    _.map(filtersObj, (value, key) => {
      if (key === 'nature_of_payment_id' || key === 'verified_status') {
        if (value.length > 0) {
          const val = value.join(',');
          filterStr += `&${key}=${val}`;
        }
        return;
      }

      if (value) {
        filterStr += `&${key}=${value}`;
      }
    });
    return filterStr;
  };

  const fetchRecords = () => {
    if (
      (filters.from_total_amtp > 0 && filters.to_total_amtp > 0)
      && (parseFloat(filters.from_total_amtp) > parseFloat(filters.to_total_amtp))
    ) {
      setError('From total amount can not be greater than to amount.');
      setShowAmountRangeBox(true);
      return;
    }
    if (
      (filters.from_total_unconsumed_amtp > 0 && filters.to_total_unconsumed_amtp > 0)
      && (parseFloat(filters.from_total_unconsumed_amtp) > parseFloat(filters.to_total_unconsumed_amtp))
    ) {
      //console.log('to ', filters.to_total_unconsumed_amtp);
      setError('From unmapped amount can not be greater than to amount.');
      setShowUnmappedAmountRangeBox(true);
      return;
    }
    const filterStr = getFilterStr(filters);
    history.push(filterStr);
  };

  const onClose = (reload, msg = '', FormPrintOpen) => {
    if (FormPrintOpen !== true) {
      setShowCreateForm(false);
    }
    setShowEditForm(false);
    if (msg) {
      setSuccess(msg);
    }

    if (reload) {
      setTimeout(() => {
        history.go();
      }, 3000);
    }
  };

  const updateChallanInfo = (challanInfo, view = false) => {
    setLoading(true);
    setShowEditForm(false);
    setEditChallanInfo(challanInfo);
    setHorizontalView(view);
    setTimeout(function () { setShowEditForm(true); setLoading(false); }, 1);
  };

  const toggleAllSelect = (checkedD = {}) => {
    const unMatchedSelect = { ...checkedChallanData, ...checkedD };
    _.map(unMatchedSelect, (value, key) => {
      unMatchedSelect[key] = !allCheckData;
    });

    if (!allCheckData) {
      setSelectedChallanCount(Object.keys(unMatchedSelect).length);
    } else {
      setSelectedChallanCount(0);
    }

    setCheckedChallanData(unMatchedSelect);
    setAllCheckData(!allCheckData);
  };


  const handleChallanToggle = (event) => {
    if (event && event.dataItem) {
      let key = event.dataItem.challan_id;
      const newValue = !checkedChallanData[key];
      setCheckedChallanData({ ...checkedChallanData, [key]: newValue });
      let allMatched = true;

      _.map(checkedChallanData, (value, key1) => {
        if (key1 !== key && value !== newValue) {
          allMatched = false;
        }
      });

      if (newValue) {
        setSelectedChallanCount(selectedChallanCount + 1);
      } else {
        setSelectedChallanCount(selectedChallanCount - 1);
      }

      if (allMatched && newValue) {
        setAllCheckData(true);
      } else {
        setAllCheckData(false);
      }
    }
  };

  const getChallanList = (data) => {
    async function fetchEverything() {
      async function fetchChallans() {
        const result = await getCorrectionChallans(data);
        let new_challanList = [];
        if(challanList?.length > 0) new_challanList = challanList;
        if (result?.data?.cursor?.current_page > 1) {
          new_challanList = new_challanList.concat(result?.data?.data ?? []);
        } else {
          new_challanList = result?.data?.data ?? [];
        }

        setChallanList(new_challanList);
        setCursor(result?.data?.cursor ?? {});

        setChallanSummary(result?.data?.summary ? result.data.summary : {
          total_num_rows: '',
          total_num_rows_color: '',
          no_of_deductee_transactions: '',
          no_of_deductee_transactions_color: '',
          total_unconsumed_amtp: '',
          total_unconsumed_amtp_color: ''
        });

        const checkedData = {};
        // const keyWiseData = {};
        // const selectedData = []; // unused keys then commited

        new_challanList.map((challan) => {
          checkedData[challan.challan_id] = false;
          // keyWiseData[challan.challan_id] = challan;
          // selectedData.push(challan.challan_id);
          return true;
        });

        setCheckedChallanData(checkedData);
      }
      try {
        await Promise.all([
          fetchChallans(),
          // fetchPaymentCodes(),
        ]);
        setError(null);
      } catch (err) {
        console.error('error: ', err);
        setError(err.message);
      } finally {
        setLoading(false);
        setShowMoreLoading(false);
        setSkeletonList(false)
      }
    }
    data.page && data.page > 1 ? setShowMoreLoading(true) : setLoading(true);
    fetchEverything();
  };


  useEffect(() => {
    const postData = getParamsHistoryUrl();

    getChallanList(postData);

    if (params.open && params.open === 'import-challan') {
      setShowImportChallan(true);
      delete params.open;
    }

    //eslint-disable-next-line
  }, [searchStr]);


  const getParamsHistoryUrl = () => {

    const postData = {
      deductor_id: deductorId,
    };

    if (params.return_id) {
      postData.return_id = params.return_id;
    }

    if (params.sort) {
      dataState.sort = JSON.parse(atob(params.sort));
      postData.sort = JSON.parse(atob(params.sort));
    }
    if (params.filters) {

      // IN getParamsHistoryUrl
      let frs = JSON.parse(atob(params.filters));
      for (let i in frs) {
        for (let j in frs[i].filters) {
          if (frs[i].filters[j].field === 'deposit_date') {
            frs[i].filters[j].value = parseDate(frs[i].filters[j].value);
          }
        }
      }

      dataState.filter = { filters: [] };
      dataState.filter.filters = frs;
      postData.filters = frs;
    }
    if (params.page) {
      postData.page = params.page;
    }
    if (params.action) {
      postData.action = params.action.split(",");
    }
    postData.limit = params.limit ?? limit;
    setDataState(dataState);
    setFilterPostData(postData);
    return postData;
  };

  const handleOnClickPage = (e) => {
    const e1 = document.getElementById('date-range-box');
    const e2 = document.getElementById('amount-range-box');
    const e3 = document.getElementById('unmapped-amount-range-box');
    if (e.target.id !== 'date-picker-day') {
      if (!(e1 && e1.contains(e.target))) {
        if (showDateRangeBox) {
          fetchRecords();
        }
        setShowDateRangeBox(false);
      }
      if (!(e2 && e2.contains(e.target))) {
        if (showAmountRangeBox) {
          fetchRecords();
        }
        setShowAmountRangeBox(false);
      }
      if (!(e3 && e3.contains(e.target))) {
        if (showUnmappedAmountRangeBox) {
          fetchRecords();
        }
        setShowUnmappedAmountRangeBox(false);
      }
    }
  };


  const dataStateChange = (dataState) => {
    setDataState(dataState);
    createHistoryUrl(dataState);
  };

  const createHistoryUrl = (dataState = [], action = '') => {
    let filterStr = `?deductor_id=${deductorId}`;

    if (params.return_id) {
      filterStr += `&return_id=${params.return_id}`;
    }

    if (params.tab) {
      filterStr += `&tab=${params.tab}`;
    }

    if (params.action) {
      let all_status = params.action.split(",");
      let array_index = all_status.indexOf(action);
      if (array_index !== -1) {
        all_status.splice(array_index, 1);
      }
      else {
        all_status.push(action);
      }
      filterStr += all_status.length > 0 ? `&action=${all_status.join(",")}` : '';
    }
    else {
      filterStr += `&action=${action}`;
    }

    if (dataState.filter) {
      filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
    }
    if (dataState.sort && dataState.sort.length > 0) {
      filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
    }
    history.push(filterStr);
  };

  const editInfo = (props) => (
    <td className="saldtddeleditsect text-center">
      {' '}
      <span
        onClick={() => { setCurrentChallan(props.dataIndex); updateChallanInfo(props.dataItem) }}
      >
        <img src={editIcon} alt="" />
      </span>
    </td >
  )

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };

  useEffect(() => {
    let action = params.action ? params.action.split(",") : [];
    setStatusFilter(action);
    //eslint-disable-next-line
  }, [searchStr]);


  const statusColor = (dataItem, className) => {
    const action = dataItem.action;
    if (action === 'ADDED') {
      className = `${className} green_color`;
    }
    else if (action === 'UPDATED') {
      className = `${className} blue_color`;
    }
    else {
      className = `${className} black_color`;
    }
    return className;
  };

  const renderSubTextTitle = (title, subText, color) => {
    return (
      <div title={title + ': ' + subText}>
        {title} {' '}
        {subText && (<div className="text-right">
          <span title={title + ': ' + subText} className="b2btbtrthtextclr" style={{ color: `#${color}` }}>{subText}</span>
        </div>)}
      </div>
    );
  }

  return (
    <div onClick={handleOnClickPage}>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {showCreateForm
        ? (
          <AddChallan
            onClose={onClose}
            deductorId={deductorId}
          />
        )
        : null}

      {showEditForm
        ? (
          <EditChallan
            onClose={onClose}
            deductorId={deductorId}
            challanId={editChallanInfo.challan_id}
            componentImport={true}
            updateChallanInfo={updateChallanInfo}
            challanList={challanList}
            currentChallan={currentChallan}
            setCurrentChallan={setCurrentChallan}
            horizontalView={horizontalView}
          />
        )
        : null}

      {
        showImportChallan
          ? <ConnectorAdapter
            show={showImportChallan}
            handleClose={() => setShowImportChallan(false)}
            setPort={setPort}
            handleProceed={() => setStartEfilingProcess(true)}
            title={'Login at Income Tax Portal'}
            section={'EFILING'}
          />
          : null
      }

      {startEfilingProcess && port
        ?
        <EFilingConnectorForImportChallan
          deductorId={deductorId}
          returnId={params.return_id ?? ''}
          handleProceed={() => {
            setStartTracesProcess(true);
            setStartEfilingProcess(false);
          }}
          port={port}
        />
        : null
      }

      {startTracesProcess && port
        ?
        <TracesConnector
          deductorId={deductorId}
          returnId={params.return_id}
          handleProceed={() => {
            setShowImportChallan(false);
            setStartTracesProcess(false);
            setStartEfilingProcess(false);
          }}
          port={port}
          setCaptchaRef={() => null}
          setVerifyCreds={() => null}
          creds={null}
        />
        : null
      }

      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-xs-12 pt-2">
          </div>
          <div className="col-xl-4 col-lg-3 col-md-3 col-sm-5 col-xs-12 pt-1 text-center listchcorleftpadd">
            <h4 className="listofchallanfs text-center">
              List Of Challans
            </h4>
          </div>

          <div className="col-md-4 text-right pt-1">
            <div className="form-check-inline" onClick={() => { createHistoryUrl(dataState, 'UPDATED') }}>
              <label className="form-check-label" for="check1">
                <input type="checkbox" className="form-check-input" checked={statusFilter.includes('UPDATED') ? true : false} style={{ verticalAlign: 'middle' }} /> Updated
              </label>
            </div>

          </div>
        </div>
      </div>

      <div className="container-fluid mt-2">
        <div className="card padding_top_manage">
          {skeletonList ? <Skeleton12ColumnGrid rowNo={10} />
            :
              <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                <Tooltip anchorElement="pointer" position="right">
                  <Grid
                    className="table table-striped listofsalarydetailstb"
                    style={{ height: '300px', overflowX: 'hidden' }}
                    data={challanList.map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: checkedChallanData[idGetter(item)],
                    }))}
                    {...dataState}
                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                      enabled: true,
                      drag: false,
                      cell: false,
                    }}
                    onSelectionChange={handleChallanToggle}
                    onHeaderSelectionChange={() => toggleAllSelect()}
                    filterOperators={{
                      text: [
                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                      ],
                      numeric: [
                        { text: 'grid.filterGteOperator', operator: 'gte' },
                        { text: 'grid.filterLteOperator', operator: 'lte' },
                        { text: 'grid.filterLtOperator', operator: 'lt' },
                        { text: 'grid.filterGtOperator', operator: 'gt' },
                        { text: 'grid.filterEqOperator', operator: 'eq' },
                        { text: 'grid.filterNotEqOperator', operator: 'neq' },
                      ],
                      date: [
                        { text: 'grid.filterGteOperator', operator: 'gte' },
                        { text: 'grid.filterLteOperator', operator: 'lte' },
                        { text: 'grid.filterLtOperator', operator: 'lt' },
                        { text: 'grid.filterGtOperator', operator: 'gt' },
                        { text: 'grid.filterEqOperator', operator: 'eq' },
                      ],
                      textWithEmpty: [
                        { text: 'grid.filterContainsOperator', operator: 'contains' },
                        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                      ],
                      dropdown: [
                        { text: 'grid.filterContainsOperator', operator: 'in' },
                      ]
                    }}
                    sortable={{
                      allowUnsort: true,
                    }}
                  >

                    <Column
                      field="challan_no"
                      headerClassName={isColumnActive('challan_no') ? 'active' : ''}
                      title={renderSubTextTitle('Challan No', challanSummary.total_num_rows, challanSummary.total_num_rows_color)}
                      width="200"
                      columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                      cell={(event) => (
                        <td className={statusColor(event.dataItem, "")}>
                          {event.dataItem.challan_no}
                        </td>
                      )}
                      sortable={false}
                    />

                    <Column
                      field="bsr_code"
                      headerClassName={isColumnActive('bsr_code') ? 'active' : ''}
                      title="BSR Code"
                      width="217"
                      columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                      cell={(event) => (
                        <td className={statusColor(event.dataItem, "")}>
                          {event.dataItem.bsr_code}
                        </td>
                      )}
                    />

                    <Column
                      field="deposit_date"
                      headerClassName={isColumnActive('deposit_date') ? 'active' : ''}
                      title="Deposit Date"
                      width="217"
                      columnMenu={(props) => <ColumnMenu {...props} />}
                      cell={(event) => (
                        <td className={statusColor(event.dataItem, "text-center")}> {event.dataItem.deposit_date ? moment(event.dataItem.deposit_date).format('DD/MM/YYYY') : '-'}</td>
                      )}
                      filter="date"
                    />

                    <Column
                      field="verified_status"
                      headerClassName={isColumnActive('verified_status') ? 'active' : ''}
                      title="Verified?"
                      // width="150"
                      width="180"
                      filter="dropdown"
                      columnMenu={(props) => <ColumnMenu hideSecondFilter filterList={[
                        { label: 'Matched', value: 'MATCHED' },
                        { label: 'Un-Matched', value: 'UNMATCHED' },
                        { label: 'Unverified', value: 'UNVERIFIED' },
                      ]} {...props} />}
                      cell={(event) => (
                        <td className={statusColor(event.dataItem, "text-center")} style={event.dataItem && event.dataItem.verified_status && event.dataItem.verified_status.color ? { color: `#${event.dataItem.verified_status.color}` } : null}>{event.dataItem.verified_status ? event.dataItem.verified_status.status : null}</td>
                      )}
                    />

                    <Column
                      field="total_unconsumed_amtp"
                      headerClassName={isColumnActive('total_unconsumed_amtp', dataState) ? 'active' : ''}
                      // title="Unconsumed Amt"
                      title={renderSubTextTitle('Unconsumed Amt', formatNumber(challanSummary.total_unconsumed_amtp), challanSummary.total_unconsumed_amtp_color)}
                      width="260"
                      filter="numeric"
                      cell={(event) => (
                        <td className={statusColor(event.dataItem, "text-right")} title={formatNumber(parseFloat(event.dataItem.total_unconsumed_amtp).toFixed(2))}> {formatNumber(parseFloat(event.dataItem.total_unconsumed_amtp).toFixed(2))}</td>
                      )}
                      columnMenu={(props) => <ColumnMenu {...props} />}
                    />

                    <Column
                      field="no_of_deductee_transactions"
                      headerClassName={isColumnActive('no_of_deductee_transactions', dataState) ? 'active' : ''}
                      // title="Transactions"
                      title={renderSubTextTitle('Transactions', formatNumber(challanSummary.no_of_deductee_transactions), challanSummary.no_of_deductee_transactions_color)}
                      filter="numeric"
                      columnMenu={(props) => <ColumnMenu {...props} />}
                      cell={(event) => (
                        <td className={statusColor(event.dataItem, "text-right")}>{event.dataItem.no_of_deductee_transactions}</td>
                      )}
                      width="120"
                    />

                    <Column
                      field="editIcon" sortable={false}
                      title="Action"
                      cell={editInfo}
                      width="120"
                    />

                  </Grid>
                </Tooltip>

                <ShowMorePagination
                  cursor={cursor}
                  fetchData={getChallanList}
                  postData={filterPostData}
                  loading={showMoreLoading}
                />

              </div>
          }

          <div className="form-check-inline ml-3">
            <label className="form-check-label">
              <span style={{ color: '#0000ff' }}><FontAwesomeIcon icon={faSquare} /></span>
              <span className="pl-1">Updated</span>
            </label>
          </div>

          <div className="form-check-inline" style={{ display: 'inline-block', width: '98%', textAlign: 'right' }}>
            <label className="form-check-label">
              <span className="pl-1 text-warning">{consoStatus && consoStatus.conso_file_status === 'IMPORT_IN_PROGRESS' ? 'IMPORT IN PROGRESS PLEASE WAIT....' : null}</span>
            </label>
          </div>

        </div>
      </div>

    </div >
  );
};

export default ChallanList;