/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { BASE_PATH } from '../../app/constants';
import './ReturnFilingSecondStep.css';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import LateFeeAmountPopup from './LateFeeAmountPopup';
import LateDeductionInterestAmountPopup from './LateDeductionInterestAmountPopup';
import {
  getCorrectionTDScomputationSumary
} from '../../api/interestFeesCalculatorAPI';
import {
  getCorrectionTransactionOverview
} from '../../api/returnAPI';
import ShowAlert from '../../components/ShowAlert';
import AddChallan from '../challanList/AddChallan';

const CorrectionTDSComputation = (props) => {
  const { consoStatus } = props;
  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);
  const headerDataDetails = props.headerDataDetails;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tdsComputation, setTdsComputation] = useState({});
  const [showChallanCreateForm, setShowChallanCreateForm] = useState(false);
  const [showLateFeeAmountPopup, setShowLateFeeAmountPopup] = useState(false);
  const [showLateDeductionInterestAmountPopup, setLateDeductionInterestAmountPopup] = useState(false);

  const [lateDeductionInterestAmtp, setLateDeductionInterestAmtp] = useState('0');
  const [latePaymentInterestAmtp, setLatePaymentInterestAmtp] = useState('0');

  const [transactionData, setTransactionData] = useState({
    total_deductee_transactions: '',
    total_challans: '',
    total_tax_deducted_amtp: '',
    total_tax_adjusted_amtp: '',
    total_tax_remaining_to_be_adjusted_amtp: '',
  });

  const showLateDeductionInterestAmountPopupModel = () => {
    setShowLateFeeAmountPopup(false);
    setLateDeductionInterestAmountPopup(true);
  };

  const showLateFeeAmountPopupModel = () => {
    setLateDeductionInterestAmountPopup(false);
    setShowLateFeeAmountPopup(true);
  };


  const fetchTransactionOverView = async () => {
    try {
      const result = await getCorrectionTransactionOverview(`?deductor_id=${params.deductor_id}&return_id=${params.return_id}`);
      setTransactionData(result.data);
    } catch (err) {
    }
  }

  const getTDScomputationFunc = (filterStr) => {
    async function fetchEverything() {
      async function fetchTDScomputation() {
        const result = await getCorrectionTDScomputationSumary(filterStr);
        setTdsComputation(result.data);
        if (result.data && result.data.late_deduction_interest_amtp) {
          setLateDeductionInterestAmtp(result.data.late_deduction_interest_amtp);
        }

        if (result.data && result.data.late_payment_interest_amtp) {
          setLatePaymentInterestAmtp(result.data.late_payment_interest_amtp);
        }
      }

      try {
        await Promise.all([
          fetchTDScomputation(),
        ]);
        setError(null);
      } catch (err) {
        // console.error('error: ', err);
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  useEffect(() => {
    fetchTransactionOverView();
    getTDScomputationFunc(searchStr);
  }, [searchStr]);

  const onClose = () => {
    setShowChallanCreateForm(false);
  };

  return (
    <div className="step2">
      {loading ? <LoadingIndicator /> : null}
      {showChallanCreateForm
        ? (
          <AddChallan
            deductorId={params.deductor_id}
            onClose={onClose}
            tdsComputation={tdsComputation}
          />
        )
        : null}
      <ShowAlert error={error} onClose={() => setError(null)} />

      <LateDeductionInterestAmountPopup
        onHide={() => {
          setLateDeductionInterestAmountPopup(false);
          setShowLateFeeAmountPopup(false);
        }}
        deductorId={params.deductor_id}
        returnId={params.return_id}
        show={showLateDeductionInterestAmountPopup}
        closeModal={() => { setLateDeductionInterestAmountPopup(false); }}
        lateDeductionInterestAmtp={lateDeductionInterestAmtp}
        latePaymentInterestAmtp={latePaymentInterestAmtp}
      />
      <LateFeeAmountPopup
        onHide={() => {
          setLateDeductionInterestAmountPopup(false);
          setShowLateFeeAmountPopup(false);
        }}
        tdsComputation={tdsComputation}
        show={showLateFeeAmountPopup}
        closeModal={() => { setShowLateFeeAmountPopup(false); }}
      />

      <div className="container-fluid">
        <div className="container mr10Step2">
          <div className="row mt-0">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <fieldset className="ovfsdsborblue pt-0 mt-0 mb-0 pb-0">
                <legend className="lengcorroverview">
                  <div className="corroverviewinndev">
                    <div className="corroverviewlefts">
                      <h4>Overview</h4>
                    </div>
                    <div className="corroverviewinner"></div>
                    <div className="corroverviewright">
                      <h4 className="" style={{ cursor: 'pointer' }}>
                        PRN Number:
                        <span> {transactionData.prn_no}</span>
                      </h4>
                    </div>
                    <div className="corroverviewtopbor"></div>
                  </div>
                </legend>

                <table className="table table-striped table-responsive ovtbpanno mb-1 pb-1">
                  <tbody>
                    <tr>
                      <td>No. of Deductee Transactions</td>
                      <td >No. of Challan</td>
                      <td>{(headerDataDetails && headerDataDetails.return_type === 'CORRECTION' && headerDataDetails.form_type === '27EQ') ? "Total Tax Collected" : "Total Tax Deducted"}</td>
                      <td>Total Tax Adjusted</td>
                      <td>Total Tax Remaining to be Adjusted</td>
                    </tr>
                    <tr>
                      <td className="text-center">{transactionData.total_deductee_transactions}</td>
                      <td className="text-center">{transactionData.total_challans}</td>
                      <td className="text-center">
                        {transactionData.total_tax_deducted_amtp > '0' && transactionData.total_tax_deducted_amtp !== '' ? (
                          <>

                            {transactionData.total_tax_deducted_amtp}
                          </>
                        ) : '0'}
                      </td>
                      <td className="text-center">
                        {transactionData.total_tax_adjusted_amtp > '0' && transactionData.total_tax_adjusted_amtp !== '' ? (
                          <>

                            {' '}
                            {transactionData.total_tax_adjusted_amtp}
                          </>
                        ) : '0'}
                      </td>
                      <td className="text-center">
                        {transactionData.total_tax_remaining_to_be_adjusted_amtp > '0' && transactionData.total_tax_remaining_to_be_adjusted_amtp !== '' ? (
                          <>

                            {' '}
                            {transactionData.total_tax_remaining_to_be_adjusted_amtp}
                          </>
                        ) : '0'}

                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
              <div className="card timel2patop">
                <div className="table-responsive">
                  <table className="table table-striped timelst3errortb mb-1">
                    <thead>
                      <tr>
                        <th>Particulars</th>
                        <th>Original Correction Statement</th>
                        <th>Updated Correction Statement</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr className="timel2tdheadting">
                        <td>
                          {' '}
                          {' '}
                          {' '}
                          {tdsComputation.report ? tdsComputation.report.summary.title : null}
                          {' '}
                        </td>
                        <td />
                        <td />
                      </tr>
                      {' '}
                      {tdsComputation.report ? tdsComputation.report.summary.data.map((tdsComputationLiabilitiesDetail, keys) => (
                        tdsComputationLiabilitiesDetail.key !== 'liabilities_fees' ?
                          <tr key={keys}>
                            <td>
                              {' '}
                              {tdsComputationLiabilitiesDetail.title}
                              {' '}
                              {' '}
                              {' '}
                            </td>
                            <td align="right">
                              {tdsComputationLiabilitiesDetail.old_value2_amtp
                                ? tdsComputationLiabilitiesDetail.old_value2_amtp > 0 ? (
                                  <div>
                                    {' '}

                                    <span className="pr-1">
                                      {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                    </span>
                                    {' '}
                                    {tdsComputationLiabilitiesDetail.old_value2_amtp}

                                  </div>
                                ) : tdsComputationLiabilitiesDetail.old_value2_amtp === '0' || tdsComputationLiabilitiesDetail.old_value2_amtp === null ? (
                                  <div>
                                    {' '}
                                    0
                                    {' '}
                                  </div>
                                ) : (
                                      <div>
                                        {' '}
                                        <span className="pr-1">
                                          {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                        </span>
                                        {' '}
                                    (
                                        {' '}
                                        {Math.abs(tdsComputationLiabilitiesDetail.old_value2_amtp)}
                                        {' '}
                                    )
                                      </div>
                                    )
                                : null}
                            </td>
                            {/* <td /> */}
                            {' '}
                            {tdsComputationLiabilitiesDetail.key === 'liabilities_interest' ? (
                              <td align="right">
                                {' '}
                                {tdsComputationLiabilitiesDetail.value2_amtp > 0 ? (
                                  <span className="link" onClick={() => showLateDeductionInterestAmountPopupModel()}>
                                    <span className="pr-1">
                                      {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                    </span>
                                    {tdsComputationLiabilitiesDetail.value2_amtp}
                                  </span>
                                ) : tdsComputationLiabilitiesDetail.value2_amtp === '0' || tdsComputationLiabilitiesDetail.value2_amtp === null ? (
                                  <div>
                                    {' '}
                                    0
                                    {' '}
                                  </div>
                                ) : (
                                      <div>
                                        {' '}
                                        <span className="pr-1">
                                          {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                        </span>
                                        {' '}
                                    (
                                        {' '}
                                        {Math.abs(tdsComputationLiabilitiesDetail.value2_amtp)}
                                        {' '}
                                    )
                                      </div>
                                    )}
                              </td>
                            ) : tdsComputationLiabilitiesDetail.key === 'liabilities_fees' ? (
                              <td align="right">
                                {' '}
                                {tdsComputationLiabilitiesDetail.value2_amtp > 0 ? (
                                  <span className="link" onClick={() => showLateFeeAmountPopupModel()}>
                                    <span className="pr-1">
                                      {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                    </span>
                                    {tdsComputationLiabilitiesDetail.value2_amtp}
                                  </span>
                                ) : tdsComputationLiabilitiesDetail.value2_amtp === '0' || tdsComputationLiabilitiesDetail.value2_amtp === null ? (
                                  <div>
                                    {' '}
                                    0
                                    {' '}
                                  </div>
                                ) : (
                                      <div>
                                        {' '}
                                        <span className="pr-1">
                                          {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                        </span>
                                        {' '}
                                    (
                                        {' '}
                                        {Math.abs(tdsComputationLiabilitiesDetail.value2_amtp)}
                                        {' '}
                                    )
                                      </div>
                                    )}
                              </td>
                            ) : (
                                  <td align="right">
                                    {' '}
                                    <span className="pr-1">
                                      {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                    </span>
                                    {' '}
                                    {(tdsComputationLiabilitiesDetail.value2_amtp && tdsComputationLiabilitiesDetail.value2_amtp > 0) ? tdsComputationLiabilitiesDetail.value2_amtp : '0'}
                                  </td>
                                )}
                          </tr>
                          : null
                      )) : null}
                    </tbody>

                    <tbody>

                      <tr className="timel2tdheadting">
                        <td>{tdsComputation.report ? tdsComputation.report.challan_summary.title : null}</td>
                        <td />
                        <td />
                      </tr>

                      {tdsComputation.report ? tdsComputation.report.challan_summary.data.map((tdsChallanSummaryDetail, key) => (
                        <tr key={key}>
                          <td>{tdsChallanSummaryDetail.title}</td>
                          <td align="right">
                            {tdsChallanSummaryDetail.old_value1_amtp
                              ? tdsChallanSummaryDetail.old_value1_amtp > 0 ? (
                                <div>
                                  {' '}

                                  <span className="pr-1">
                                    {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                  </span>
                                  {' '}
                                  {tdsChallanSummaryDetail.old_value1_amtp}

                                </div>
                              ) : tdsChallanSummaryDetail.old_value1_amtp === '0' || tdsChallanSummaryDetail.old_value1_amtp === null ? (
                                <div>
                                  {' '}
                                  0
                                  {' '}
                                </div>
                              ) : (
                                    <div>
                                      {' '}
                                      <span className="pr-1">
                                        {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                      </span>
                                      {' '}
                                      {' '}
                                      {Math.abs(tdsChallanSummaryDetail.old_value1_amtp)}
                                      {' '}
                                    </div>
                                  )
                              : (
                                <div>
                                  {' '}
                                  <span className="pr-1">
                                    {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                  </span>
                                  {' '}
                                  {' '}
                                  {Math.abs(tdsChallanSummaryDetail.old_value2_amtp)}
                                  {' '}
                                </div>
                              )}
                          </td>
                          <td align="right">
                            {tdsChallanSummaryDetail.value1_amtp
                              ? tdsChallanSummaryDetail.value1_amtp > 0 ? (
                                <div>
                                  {' '}
                                  <span className="pr-1">
                                    {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                  </span>
                                  {' '}
                                  {tdsChallanSummaryDetail.value1_amtp}
                                </div>
                              ) : tdsChallanSummaryDetail.value1_amtp === '0' || tdsChallanSummaryDetail.value1_amtp === null ? (
                                <div>
                                  {' '}
                                  0
                                  {' '}
                                </div>
                              ) : (
                                    <div>
                                      {' '}
                                      <span className="pr-1">
                                        {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                      </span>
                                      {' '}
                                      {' '}
                                      {Math.abs(tdsChallanSummaryDetail.value1_amtp)}
                                      {' '}
                                    </div>
                                  )
                              : <div>
                                {' '}
                                <span className="pr-1">
                                  {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                </span>
                                {' '}
                                {' '}
                                {Math.abs(tdsChallanSummaryDetail.value2_amtp)}
                                {' '}
                              </div>}
                          </td>
                        </tr>
                      )) : null}

                      {/* {challanAllocationList && challanAllocationList.challan_summary && challanAllocationList.challan_summary.data ? challanAllocationList.challan_summary.data.map((tdsChallanSummaryDetail, key) => (
                        <tr key={key}>
                          <td>{tdsChallanSummaryDetail.title}</td>
                          <td align="right">
                            {tdsChallanSummaryDetail.value1_amtp
                              ? tdsChallanSummaryDetail.value1_amtp > 0 ? (
                                <div>
                                  {' '}

                                  <span className="link" onClick={() => showMappedChallanPopupModel()}>
                                    <span className="pr-1">
                                       <FontAwesomeIcon icon={faRupeeSign} /> 
                                    </span>
                                    {' '}
                                    {tdsChallanSummaryDetail.value1_amtp}
                                  </span>

                                </div>
                              ) : tdsChallanSummaryDetail.value1_amtp === '0' || tdsChallanSummaryDetail.value1_amtp === null ? (
                                <div>
                                  {' '}
                                  -
                                  {' '}
                                </div>
                              ) : (
                                <div>
                                  {' '}
                                  <span className="pr-1">
                                    <FontAwesomeIcon icon={faRupeeSign} />
                                  </span>
                                  {' '}
                                  (
                                  {' '}
                                  {Math.abs(tdsChallanSummaryDetail.value1_amtp)}
                                  {' '}
                                  )
                                </div>
                              )
                              : null}
                          </td>
                          <td align="right">
                            {tdsChallanSummaryDetail.value2_amtp
                              ? tdsChallanSummaryDetail.value2_amtp > 0 ? (
                                <div>
                                  {' '}
                                  <span className="pr-1">
                                    <FontAwesomeIcon icon={faRupeeSign} />
                                  </span>
                                  {' '}
                                  {tdsChallanSummaryDetail.value2_amtp}
                                </div>
                              ) : tdsChallanSummaryDetail.value2_amtp === '0' || tdsChallanSummaryDetail.value2_amtp === null ? (
                                <div>
                                  {' '}
                                  -
                                  {' '}
                                </div>
                              ) : (
                                <div>
                                  {' '}
                                  <span className="pr-1">
                                    <FontAwesomeIcon icon={faRupeeSign} />
                                  </span>
                                  {' '}
                                  (
                                  {' '}
                                  {Math.abs(tdsChallanSummaryDetail.value2_amtp)}
                                  {' '}
                                  )
                                </div>
                              )
                              : null}
                          </td>
                        </tr>
                      )) : null} */}

                    </tbody>

                    <tbody>
                      <tr className="timel2tdheadting">
                        <td>{tdsComputation.report ? tdsComputation.report.deductee_transaction_summary.title : null}</td>
                        <td />
                        <td />
                      </tr>

                      {tdsComputation.report ? tdsComputation.report.deductee_transaction_summary.data.map((deducteeTransactionSummaryVal, key) => (

                        deducteeTransactionSummaryVal.key === 'total_tax_payable' ?
                          (
                            <tr key={`deducteeTransactionSummaryValTR_${key}`}>
                              <td>{deducteeTransactionSummaryVal.key === 'total_tax_payable' ? deducteeTransactionSummaryVal.title : null}</td>
                              <td align="right">
                                {deducteeTransactionSummaryVal.old_value1_amtp
                                  ? deducteeTransactionSummaryVal.old_value1_amtp > 0 ? (
                                    <div>
                                      {' '}
                                      <span className="pr-1">
                                        {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                      </span>
                                      {' '}
                                      {deducteeTransactionSummaryVal.old_value1_amtp}
                                    </div>
                                  ) : deducteeTransactionSummaryVal.old_value1_amtp === '0' || deducteeTransactionSummaryVal.old_value1_amtp === null ? (
                                    <div>
                                      {' '}
                                      0
                                      {' '}
                                    </div>
                                  ) : (null
                                        // <div>
                                        //   {' '}
                                        //   <span className="pr-1">
                                        //     {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                        //   </span>
                                        //   {' '}
                                        //   (
                                        //   {' '}
                                        //   {Math.abs(deducteeTransactionSummaryVal.old_value1_amtp)}
                                        //   {' '}
                                        //   )
                                        // </div>
                                      )
                                  : null}
                              </td>
                              <td align="right">
                                {deducteeTransactionSummaryVal.value1_amtp ?
                                  // ? deducteeTransactionSummaryVal.value1_amtp > 0 ? (
                                  <div>
                                    {' '}
                                    <span className="pr-1">
                                      {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                    </span>
                                    {' '}
                                    {(deducteeTransactionSummaryVal.value1_amtp && deducteeTransactionSummaryVal.value1_amtp > 0) ? deducteeTransactionSummaryVal.value1_amtp : (deducteeTransactionSummaryVal.key === 'total_tax_payable') ? '0' : null}
                                  </div>
                                  // ) : (null
                                  //  deducteeTransactionSummaryVal.old_value1_amtp === '0' || deducteeTransactionSummaryVal.old_value1_amtp === null ? (
                                  //   <div>
                                  //     {' '}
                                  //     -
                                  //     {' '}
                                  //   </div>
                                  // ) : (null
                                  // <div>
                                  //   {' '}
                                  //   <span className="pr-1">
                                  //     {/* <FontAwesomeIcon icon={faRupeeSign} /> */}
                                  //   </span>
                                  //   {' '}
                                  //   (
                                  //   {' '}
                                  //   {Math.abs(deducteeTransactionSummaryVal.value1_amtp)}
                                  //   {' '}
                                  //   )
                                  // </div>
                                  // )
                                  : null}
                              </td>
                            </tr>
                          ) : null
                      )) : null}
                    </tbody>

                  </table>
                </div>
                <div className="col-lg text-center mb-1">

                </div>
              </div>

              <div className="row">
                <div className="col-lg-7 text-right mb-1">
                  <Link
                    className="fffClr mt-1 mb-1"
                    to={`${BASE_PATH}correction?deductor_id=${params.deductor_id}&return_id=${params.return_id}&tab=FVU`}
                    tabIndex="-1"
                  >
                    <button className="btn btn-default timelst2bgbtnpfv">Proceed to Generate FVU</button>
                  </Link>
                </div>

                <div className="col-lg-5 text-center mb-1">

                  <span className="form-check-inline" style={{ display: 'inline-block', width: '98%', textAlign: 'right' }}>
                    <label className="form-check-label">
                      <span className="pl-1 text-warning">{consoStatus && consoStatus.conso_file_status === 'IMPORT_IN_PROGRESS' ? 'IMPORT IN PROGRESS PLEASE WAIT....' : null}</span>
                    </label>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CorrectionTDSComputation;
