import React from 'react';
import './App.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import EditDeductor from './features/editDeductor/EditDeductor';
import { BASE_PATH, IS_MAINTENANCE_MODE_ENABLED } from './app/constants';
import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './routes/PrivateRoute';
import AuthenticationRoute from './routes/AuthenticationRoute';
import Header from './features/header/Header';
import DeducteeListV2 from './features/deducteeList/DeducteeListV2';
import DeducteeTransactions from './features/deducteeTransactions/TransactionWiseListing';
import DeducteeWiseTransactions from './features/deducteeTransactions/DeducteeWiseTransactionListing';
import SectionWiseListing from './features/deducteeTransactions/SectionWiseListing';
import ChallanListV2 from './features/challanList/ChallanListV2';
import ReturnFilingSecondStepV2 from './features/deducteeTransactionsAndChallan/ReturnFilingSecondStepV2';
import CorrectionDashboard from './features/returnDashboard/CorrectionDashboard';
import NotFound from './components/NotFound';
import UserList from './features/userManagement/UserList';
import ManageClients from './features/userManagement/ManageClients';
import ClientUserMapListing from './features/userManagement/ClientUserMapListing';
import Profile from './features/profile/Profile';
import RaiseRequestList from './features/returnRaise/RaiseRequestList';
import DeductorCorrectionDashboard from './features/deductorCorrectionDashboard/DeductorCorrectionDashboard';
import EmployeeSalaryList from './features/deductorDashboard/employeeDashboard/employeeSalaryList/EmployeeSalaryList';
import UserProfile from './features/userManagement/UserProfile';
import LoginScreen from './features/authentication/LoginScreen';
import SignUpScreen from './features/authentication/SignUpScreen';
import ForgetPass from './features/authentication/ForgetPass';
import ResetPass from './features/authentication/ResetPass';
import Subscription from './features/profile/Subscription';
import Plans from './features/Plans/Plans';
// import TdsTcsCertificate from './features/IssueCertificates/TdsTcsCertificate';
import DownloadTdsTcsCertificate from './features/IssueCertificates/DownloadTdsTcsCertificate';
import PendingRequestList from './features/deductorList/PendingRequestList';
import Maintenance from './Maintenance';

import DeductorRecentList from './features/deductorList/DeductorRecentList';
import DeductorAllList from './features/deductorList/DeductorAllList';
import CreateDeductorV2 from './features/createDeductor/CreateDeductorV2';
import NoticeOrders from './features/deductorList/NoticeOrders';
import NoticeOrdersReport from './features/deductorList/noticeOrderReport/NoticeOrdersReport';

import ReturnWiseList from './features/returnRegister/ReturnWiseList';
import ClientWiseList from "./features/returnRegister/ClientWiseList";

import DeductorDashboardV2 from './features/deductorDashboard/DeductorDashboardV2';
import BulkPANVerificationV2 from './features/bulkPANVerification/BulkPANVerificationV2';
import RaisedRequestFormV2 from './features/deductorDashboard/returnsDashboardV2/RaisedRequestFormV2';
import ChallanStatusList from './features/deductorDashboard/returnsDashboardV2/ChallanStatusList';
import ReturnFilingTdsMapping from './features/deducteeTransactionsAndChallan/returnfilingmapping/ReturnFilingTdsMapping';
import ReturnFilingInterstMapping from './features/deducteeTransactionsAndChallan/returnfilingmapping/ReturnFilingInterestMapping';
import DeductorIncompleteList from './features/deductorList/DeductorIncompleteList';
import FVUGenerationV1 from './features/deducteeTransactionsAndChallan/fvuGeneration/FVUGenerationV1';

import ImportedSalaryDetails from './features/deductorDashboard/salaryTdsCalculator/ImportedSalaryDetails.jsx';
import EmployeesWiseMonthlyTds from './features/deductorDashboard/salaryTdsCalculator/EmployeesWiseMonthlyTds.jsx';
import EmailSalaryTdsCalculationsDetails from './features/deductorDashboard/salaryTdsCalculator/EmailSalaryTdsCalculationsDetails';

import PanWiseTanDetails from './features/form3CD/PanWiseTanDetails';
import ListAllDeductors from './features/form3CD/ListAllDeductors'
import TanWiseReturnSummary from './features/form3CD/TanWiseReturnSummary'
import ReportClause34a from './features/form3CD/ReportClause34a'
import ReportClause34b from './features/form3CD/ReportClause34b'
import ReportClause34c from './features/form3CD/ReportClause34c'
import SalaryAnnexure2 from './features/SalaryAnnexure/SalaryAnnexure2.jsx';
import TdsTcsCertificateV1 from './features/IssueCertificates/TdsTcsCertificateV1.jsx';
import PanWiseDeductor from './features/form3CD/PanWiseDeductor.jsx';


export const LOGIN_PATHS = [
  `${BASE_PATH}login`,
  `${BASE_PATH}signup`,
  `${BASE_PATH}forgetpass`,
  `${BASE_PATH}resetpass`,
  `${BASE_PATH}maintenance`,
];

export const MISC_PATHS = [
  `${BASE_PATH}profile`,
  `${BASE_PATH}plans`,
  `${BASE_PATH}subscription`,
  `${BASE_PATH}user-management`,
  `${BASE_PATH}user-profile`,
]

export const CHALLAN_SYNC_BAR_EXCLUDE_PATHS = [
  `${BASE_PATH}correction`,  
];

function App() {

  if (IS_MAINTENANCE_MODE_ENABLED) {
    return (
      <Switch>
        <Route path={`${BASE_PATH}maintenance`} component={Maintenance} />
        <Redirect to={`${BASE_PATH}maintenance`} />
      </Switch>
    );
  }
  return (
    <main>
      <ScrollToTop />
      <Header />
      <Switch>
        <PrivateRoute path={`${BASE_PATH}list/recent`} component={DeductorRecentList} />
        <PrivateRoute path={`${BASE_PATH}list/all`} component={DeductorAllList} />
        <PrivateRoute path={`${BASE_PATH}list/incomplete`} component={DeductorIncompleteList} />
        <PrivateRoute path={`${BASE_PATH}create-deductor`} component={CreateDeductorV2} />
        <PrivateRoute path={`${BASE_PATH}edit-deductor/:deductorId`} component={EditDeductor} />
        <PrivateRoute path={`${BASE_PATH}deductor/bulk-pan-verification`} component={BulkPANVerificationV2} />
        <PrivateRoute path={`${BASE_PATH}deductee-list`} component={DeducteeListV2} />
        
        <PrivateRoute path={`${BASE_PATH}deductee-transactions`} component={DeducteeTransactions} />
        <PrivateRoute path={`${BASE_PATH}deductee-wise-transactions`} component={DeducteeWiseTransactions} />       
        <PrivateRoute path={`${BASE_PATH}section-wise-transactions`} component={SectionWiseListing} />
        <PrivateRoute path={`${BASE_PATH}challans`} component={ChallanListV2} />
        <PrivateRoute exact path={`${BASE_PATH}deductor`} component={DeductorDashboardV2} />
        <PrivateRoute path={`${BASE_PATH}return-filing-step2`} component={ReturnFilingSecondStepV2} />
        <PrivateRoute path={`${BASE_PATH}return-filing-step3`} component={FVUGenerationV1} />
        <PrivateRoute path={`${BASE_PATH}correction`} component={CorrectionDashboard} />
        <PrivateRoute path={`${BASE_PATH}profile`} component={Profile} exact />
        <PrivateRoute path={`${BASE_PATH}subscription`} component={Subscription} exact />
        <PrivateRoute path={`${BASE_PATH}plans`} component={Plans} exact />
        <PrivateRoute path={`${BASE_PATH}user-management`} component={UserList} exact />
        <PrivateRoute path={`${BASE_PATH}user-management/manage-clients`} component={ManageClients} exact />
        <PrivateRoute path={`${BASE_PATH}user-management/client-user-map`} component={ClientUserMapListing} exact />        
        <PrivateRoute path={`${BASE_PATH}user-profile`} component={UserProfile} />
        
        <PrivateRoute exact path={`${BASE_PATH}raised-requests-list/:deductorId`} component={RaiseRequestList} />
        <PrivateRoute exact path={`${BASE_PATH}issue-tds-tcs-certificates/:deductorId`} component={TdsTcsCertificateV1} />
        {/* <PrivateRoute exact path={`${BASE_PATH}issue-tds-tcs-certificates/:deductorId`} component={TdsTcsCertificate} /> */}
        <PrivateRoute exact path={`${BASE_PATH}download-tds-tcs-certificates/:deductorId`} component={DownloadTdsTcsCertificate} />
        <PrivateRoute exact path={`${BASE_PATH}pending-requests`} component={PendingRequestList} />
        <PrivateRoute exact path={`${BASE_PATH}correction-return-dashboard/:deductorId/return/:returnId`} component={DeductorCorrectionDashboard} />
        <PrivateRoute path={`${BASE_PATH}employee-salary`} component={EmployeeSalaryList} />         

        <PrivateRoute path={`${BASE_PATH}return-register/return-wise`} component={ReturnWiseList} />
        <PrivateRoute path={`${BASE_PATH}return-register/client-wise`} component={ClientWiseList} />
        <PrivateRoute path={`${BASE_PATH}notice-orders`} component={NoticeOrders} />
        <PrivateRoute path={`${BASE_PATH}notice-orders-report`} component={NoticeOrdersReport} />
        <PrivateRoute path={`${BASE_PATH}return-raise-request`} component={RaisedRequestFormV2} />
        <PrivateRoute exact path={`${BASE_PATH}challan-list/:status`} component={ChallanStatusList} />
        <PrivateRoute path={`${BASE_PATH}return-filing-tds-mapping`} component={ReturnFilingTdsMapping} />
        <PrivateRoute path={`${BASE_PATH}return-filing-interest-mapping`} component={ReturnFilingInterstMapping} />

        <PrivateRoute exact path={`${BASE_PATH}imported-salary-details`} component={ImportedSalaryDetails} />
        <PrivateRoute exact path={`${BASE_PATH}monthwise-salary-details`} component={EmployeesWiseMonthlyTds} />
        <PrivateRoute exact path={`${BASE_PATH}email-salary-details`} component={EmailSalaryTdsCalculationsDetails} />

        <PrivateRoute path={`${BASE_PATH}form3CD/pan-wise-tan-details`} component={PanWiseTanDetails} />
        <PrivateRoute path={`${BASE_PATH}form3CD/incomplete-deductors`} component={ListAllDeductors} />
        <PrivateRoute path={`${BASE_PATH}form3CD/return-summary`} component={TanWiseReturnSummary} />
        <PrivateRoute path={`${BASE_PATH}form3CD/report-clause-34a`} component={ReportClause34a} />
        <PrivateRoute path={`${BASE_PATH}form3CD/report-clause-34b`} component={ReportClause34b} />
        <PrivateRoute path={`${BASE_PATH}form3CD/report-clause-34c`} component={ReportClause34c} />
        <PrivateRoute path={`${BASE_PATH}pan-wise-deductor`} component={PanWiseDeductor} />

        <PrivateRoute path={`${BASE_PATH}salary-annexure`} component={SalaryAnnexure2} />


        <AuthenticationRoute path={`${BASE_PATH}login`} component={LoginScreen} />
        <AuthenticationRoute path={`${BASE_PATH}signup`} component={SignUpScreen} />
        <AuthenticationRoute path={`${BASE_PATH}forgetpass`} component={ForgetPass} />
        <AuthenticationRoute path={`${BASE_PATH}resetpass`} component={ResetPass} />
        <Route path={`${BASE_PATH}maintenance`}>
          {!IS_MAINTENANCE_MODE_ENABLED ? <Redirect to={BASE_PATH} /> : <Maintenance />}
        </Route>
        <Route path={BASE_PATH}>
          {BASE_PATH ? <Redirect to={`${BASE_PATH}list/recent`} /> : <NotFound />}
        </Route>
        <Route component={NotFound} />
        
      </Switch>
    </main>
  );
}

export default App;
