import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import './UserList.css';
import "../../components/profile/Profile.css"
import "./UserManagement.scss"
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import queryString from 'query-string';
import { gocallApi } from '../../api/issueCertificateAPI';
import checkIcon from "../../images/checkicon.png"
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { DEDUCTOR_CATEGORIES, DEDUCTOR_CATEGORIES_TYPES } from '../../app/constants';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';

const ListClients = () => {

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [deductorList, setDeductorList] = useState([]);
  const [deducteeSummary, setDeducteeSummary] = useState([]);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [dataState, setDataState] = useState({});
  const userId = params?.user_id ?? ""
  const searchStr = location.search;
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const [filterPostData, setFilterPostData] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const limit = 15;

  const getAssignedClientList = async (postData) => {
    postData.page && postData.page > 1 ? setShowMoreLoading(true) : setLoading(true);
    let url = `/api/v1/user/deductor/list`
    try {
      const result = await gocallApi("post", url, postData)

      let new_DeductorList = [];
      if (deductorList?.length > 0) new_DeductorList = deductorList;
      if (result?.data?.cursor?.current_page > 1) {
        new_DeductorList = new_DeductorList.concat(result?.data?.list ?? []);
      } else {
        new_DeductorList = result?.data?.list ?? [];
      }
      
      setDeductorList(new_DeductorList);
      setCursor(result?.data?.cursor ?? {});     
     
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
      setShowMoreLoading(false);
    }
  }

  const getSummaryAssignedClient = async () => {
    let url = `/api/v1/user/deductor/summary`
    let payload = {
      assigned: 1,
      user_id: userId,
    }
    try {
      const result = await gocallApi("post", url, payload)
      setDeducteeSummary(result?.data ?? 0)
    } catch (err) {
      setError(err.message);
    }
  }


  useEffect(() => {

    const postData = getParamsHistoryUrl();
    getAssignedClientList(postData);

    getSummaryAssignedClient();
    // eslint-disable-next-line
  }, [searchStr]);



  const getParamsHistoryUrl = () => {

    const postData = {
      user_id: params.user_id,
      assigned: 1,
    };

    if (params?.sort_on) {
      dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
      postData.sort_on = params.sort_on ? params.sort_on : '';
      postData.order_by = params.order_by ? params.order_by : "";
    }

    if (params.filters) {
      dataState.filter = { filters: [] };
      dataState.filter.filters = JSON.parse(atob(params.filters));
      postData.filters = JSON.parse(atob(params.filters));
    }

    if (params.page) {
      postData.page = params.page;
    } else {
      postData.page = 1;
    }
    postData.limit = params.limit ?? limit;

    setDataState(dataState);
    setFilterPostData(postData);
    return postData;
  };

  const rowRender = (trElement, props) => {
    let dataItem = props.dataItem ?? {};
    const key = trElement._owner.index;
    const city = dataItem.city ? dataItem.city?.toLowerCase() : "";
    if (dataItem?.deductor_category === "Z") {
      dataItem.deductor_category = "";
    }

    return (
      <tr key={key}>

        <td>
          <img src={checkIcon} width="16" className="cursorpointer" alt="" />
        </td>
        <td className="text-left sd_usermanage_textoverflow">
          <span data-toggle="tooltip" data-placement="top" title={dataItem?.deductor_name}>
            {dataItem?.deductor_name}
          </span>
        </td>
        <td className="text-center">
          {dataItem?.tan}
        </td>
        <td className="text-left textoverflow overflowtooltipconttdsamt text-capitalize">
          {city}
        </td>
        <td className="text-left">
          {dataItem?.deductor_category && <span>{DEDUCTOR_CATEGORIES?.[dataItem.deductor_category] ?? "-"}</span>}
        </td>
        <td className="text-center">
          {dataItem?.mobile_no}
        </td>
        <td className="text-left">
          {dataItem?.email}
        </td>

      </tr>
    )
  };


  const createHistoryUrl = (filters) => {

    let filterStr = `?page=1&tab=1`
    if (params.user_id) {
      filterStr += `&user_id=${params.user_id}`;
    }

    if (filters.filter) {
      filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
    }

    if (filters.sort && filters.sort.length > 0) {
      filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
    }

    history.push(filterStr);
  };

  const dataStateChange = (dataState) => {
    createHistoryUrl(dataState);
    setDataState(dataState);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };

  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      <div className="row">
        <div className="col-md-12">
          <p className="sd_usermanage_zeroclients_ass_textfs pt-1">
            {`${deducteeSummary} Deductors Assigned`}
          </p>
        </div>

        <div className="col-md-12">
          <div className="card " style={{ padding: '0px' }}>

            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
              <Grid
                className="table table-striped assigned-user-list mb-0"
                data={deductorList}
                rowRender={rowRender}
                {...dataState}
                sortable
                onDataStateChange={(event) => dataStateChange(event.dataState)}
                filterOperators={{
                  text: [
                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                  ],
                  dropdown: [
                    { text: 'grid.filterEqOperator', operator: 'in' },
                  ],
                  numeric: [
                    { text: 'grid.filterGteOperator', operator: 'gte' },
                    { text: 'grid.filterLteOperator', operator: 'lte' },
                    { text: 'grid.filterLtOperator', operator: 'lt' },
                    { text: 'grid.filterGtOperator', operator: 'gt' },
                    { text: 'grid.filterEqOperator', operator: 'eq' },
                    { text: 'grid.filterNotEqOperator', operator: 'neq' },
                  ],
                  textWithEmpty: [
                    { text: 'grid.filterContainsOperator', operator: 'contains' },
                    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                  ],
                }}
                style={{ maxHeight: '555px', overflowX: 'hidden' }}
              >
                <Column
                  width="20"
                  field=""
                />
                <Column
                  width="250"
                  field="deductor_name"
                  filter="text"
                  title="Deductor Name"
                  columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                  headerClassName={isColumnActive('deductor_name') ? 'active' : ''}
                />

                <Column
                  width="100"
                  field="tan"
                  filter="text"
                  title='TAN'
                  columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                  headerClassName={isColumnActive('tan') ? 'active' : ''}
                />

                <Column
                  width="120"
                  field="city"
                  filter="text"
                  title='Location'
                  columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                  headerClassName={isColumnActive('city') ? 'active' : ''}

                />
                <Column
                  width="140"
                  field="deductor_category"
                  title='Company Type'
                  filter="dropdown"
                  columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter filterList={DEDUCTOR_CATEGORIES_TYPES} {...props} />)}
                  headerClassName={isColumnActive('deductor_category') ? 'active' : ''}
                />

                <Column
                  width="120"
                  field="mobile_no"
                  title="Mobile No."
                  filter="text"
                  columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                  headerClassName={isColumnActive('mobile_no') ? 'active' : ''}
                />

                <Column
                  width="180"
                  field="email"
                  filter="text"
                  title="Email Address"
                  columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                  headerClassName={isColumnActive('email') ? 'active' : ''}
                />
              </Grid>
            </div>
            <ShowMorePagination
              cursor={cursor}
              fetchData={getAssignedClientList}
              postData={filterPostData}
              loading={showMoreLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListClients;
