/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDeductorEfilingCredentials } from '../../../api/deductorAPI';
import ShowAlert from '../../../components/ShowAlert';
import UpdateEFilingCredsWithoutVerify from '../../challanList/UpdateEFilingCredsWithoutVerify';
import { selectHeaderData } from '../../header/headerSlice';
import { fvuRegularDownloadCSI } from '../../../api/FVUGeneration';

const DownloadCSIFromEFilingPortalForFVU = ({ history, ...props }) => {
  const { handleClose, handleProceed, deductorId, callGenerateFVUFile ,setLoading} = props;

  const [error, setError] = useState(null);
  const [showCredentialPopup, setShowCredentialPopup] = useState(false);
  const headerDataDetails = useSelector(selectHeaderData);
  const [pswrd, setPswrd] = useState("");

  const csiForFVUGeneration = (deductorId) => {
    async function fetchEverything() {

      try {
        setLoading(true);
        const result = await getDeductorEfilingCredentials(`?deductor_id=${deductorId}`, false);

        setPswrd(result.data.itdportal_password ?? "")

        const requestParams = {
          "deductor_id": deductorId,
          "password": result.data.itdportal_password
        }

        await fvuRegularDownloadCSI(requestParams);

        callGenerateFVUFile();

        handleProceed();

      } catch (err) {
        setLoading(false);
        if (err.code === 424) {
          setShowCredentialPopup(true);
          setError(err.message)
        } else {
          setError(err.message);
          //handleClose();
        }
      } finally {
        //setLoading(false);
      }
    }
    fetchEverything();
  }

  useEffect(() => {
    csiForFVUGeneration(deductorId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deductorId]);

  return (
    <>
      {error &&
        <ShowAlert
          error={error}
          onClose={() => setError(null)}
        />
      }

      {showCredentialPopup &&
        <UpdateEFilingCredsWithoutVerify
          show={showCredentialPopup}
          handleClose={handleClose}
          deductorId={deductorId ?? ''}
          tan={headerDataDetails.tan ?? ''}
          handleProceed={() => {
            csiForFVUGeneration(deductorId);
            setShowCredentialPopup(false);
          }}
          password={pswrd}
        />}
    </>
  );
};

export default DownloadCSIFromEFilingPortalForFVU;