
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './employeeSalaryList.css';
import Button from 'react-bootstrap/Button';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { BASE_PATH } from '../../../../app/constants';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../../../components/grid/ColumnMenu';
import { AddSalaryCell } from '../../../../components/grid/CustomCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSquare
} from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from '../../../../components/loadingIndicator/LoadingIndicator';
import ShowMorePagination from '../../../../components/pagination/ShowMorePagination';
import ShowAlert from '../../../../components/ShowAlert';
import ConfirmPopup from '../../../../components/ConfirmPopup';
//import ImportEmployeeSalary from './importEmployeeSalary/ImportEmployeeSalary';
import AddSalary from '../addSalary';
import editIcon from '../../../../images/icons/edit.svg';
import deleteIcon from '../../../../images/icons/delete.svg';
import { selectHeaderData } from '../../../header/headerSlice';
import { getEmployeeSalaryList, deleteEmployeeSalary, getCorrectionEmployeeSalaryList, deleteCorrectionEmployeeSalary, getEmployeeSalarySummary, getCorrectionEmployeeSalarySummary } from '../../../../api/EmployeeApi';
import { getCurrentFinancialYear, formatNumber, deepClone } from '../../../../utils/UtilityFunctions';
import EditDeductee from '../../../deducteeList/EditDeductee';
import ImportReturnFromExcel from '../../../returnDashboard/importReturnFromExcel/ImportReturnFromExcel';
import { downloadExcelTemplate } from '../../../../api/deductorAPI';
import AddDeductee from '../../../deducteeList/AddDeductee';
import Skeleton12ColumnGrid from '../../../../components/Skeleton/Skeleton12ColumnGrid';


const EmployeeSalaryList = ({ history, ...props }) => {
    const { componentImport, consoStatus } = props;
    const location = useLocation();
    const searchStr = location.search;
    const params = queryString.parse(location.search);
    const [salaryList, setSalaryList] = useState([]);
    const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
    const [dataState, setDataState] = useState({});
    const [filterPostData, setFilterPostData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [salaryPopup, setSalaryPopup] = useState(false);
    const [salaryId, setSalaryId] = useState(false);
    const [deleteData, setDeleteData] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const [showDedcuteeEditForm, setShowDedcuteeEditForm] = useState(true);
    const [deducteeId, setDeducteeId] = useState(false);
    const [statusFilter, setStatusFilter] = useState([]);
    const [currentSalary, setCurrentSalary] = useState(0);

    const [salarySummary, setSalarySummary] = useState(null);
    const [prevStatePostData, setPrevStatePostData] = useState(null);
    const [showSkeleton, setShowSkeleton] = useState(true);

    const [showImportReturnFromExcel, setShowImportReturnFromExcel] = useState(false);
    const [showImportReturnFromTxt, setShowImportReturnFromTxt] = useState('');

    const [showCreateForm, setShowCreateForm] = useState(false);

    const limit = 25;

    if (history.action !== "PUSH") {
        localStorage.setItem('historyAction', history.action);
    }

    if (location.state && location.state.prevPageUrl) {
        localStorage.setItem('historyAction', location.state.prevPageUrl);
    }

    var prevPagePath = localStorage.getItem('historyAction');

    const headerDataDetails = useSelector(selectHeaderData);
    if (headerDataDetails.financial_year_formatted) {
        let getYearIndex = headerDataDetails.financial_year_formatted.split('-');
        var getOtherFinancialYear = parseInt(getYearIndex[0]);
    }
    const financialYear = getOtherFinancialYear ? getOtherFinancialYear : getCurrentFinancialYear();

    const getEmployeeSalaryListData = (data) => {
        async function fetchEverything() {
            async function fetchEmployeeSalaryList() {
                if (componentImport) {
                    // Correction
                    const result = await getCorrectionEmployeeSalaryList(data);
                    if (result.data.cursor.current_page > 1) {
                        setSalaryList(salaryList.concat(result.data.list));
                    } else {
                        setSalaryList(result.data.list);
                    }
                    setCursor(result.data.cursor);

                } else {
                    const result = await getEmployeeSalaryList(data);
                    if (result.data.cursor.current_page > 1) {
                        setSalaryList(salaryList.concat(result.data.list));
                    } else {
                        setSalaryList(result.data.list);
                    }
                    setCursor(result.data.cursor);
                }
            }
            try {
                await Promise.all([
                    fetchEmployeeSalaryList(),
                ]);
                setError(null);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
                setShowMoreLoading(false);
                setShowSkeleton(false);
            }
        }
        if (data.page && data.page > 1) { setShowMoreLoading(true); } else { setLoading(true); }
        setFilterPostData(data);
        fetchEverything();
    };

    const getEmployeeSalarySummaryData = async (data) => {
        setLoading(true);
        try {
            if (componentImport) {
                const result = await getCorrectionEmployeeSalarySummary(data);
                setSalarySummary(result.data);
            } else {
                const result = await getEmployeeSalarySummary(data);
                setSalarySummary(result.data);
            }
        } catch (err) {
            setError(error.message);
        } finally {
            setLoading(false);            
        }
    };

    const deleteSalary = async () => {
        setShowConfirm(false);
        setLoading(true);
        try {
            componentImport ?
                await deleteCorrectionEmployeeSalary(params.deductor_id, deleteData.salary_id, params.return_id) :
                await deleteEmployeeSalary(params.deductor_id, deleteData.salary_id);
            getEmployeeSalaryListWithSummary(filterPostData);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
        setDeleteData(false);
    };

    const getEmployeeSalaryListWithSummary = (data) => {
        getEmployeeSalaryListData(data);
        getEmployeeSalarySummaryData(data);
    };

    useEffect(() => {
        const postData = getParamsHistoryUrl();
        getEmployeeSalaryListData(postData);

        if (salarySummary === null || 
            prevStatePostData === null ||
            JSON.stringify(postData?.sort) === JSON.stringify(prevStatePostData?.sort)
          ) {        
            getEmployeeSalarySummaryData(postData);
        }
        setPrevStatePostData(deepClone(postData));

    }, [searchStr]);

    const getParamsHistoryUrl = () => {
        const postData = {
            deductor_id: params.deductor_id,
            return_id: params.return_id,
        };

        if (params.sort) {
            dataState.sort = JSON.parse(atob(params.sort));
            postData.sort = JSON.parse(atob(params.sort));
        }
        if (params.filters) {
            dataState.filter = { filters: [] };
            dataState.filter.filters = JSON.parse(atob(params.filters));
            postData.filters = JSON.parse(atob(params.filters));
        }
        if (params.page) {
            postData.page = params.page;
        }

        if (params.action) {
            postData.action = params.action.split(",");
        }
        postData.limit = params.limit ?? limit;
        setDataState(dataState);
        setFilterPostData(postData);
        return postData;
    };

    const createHistoryUrl = (dataState = [], action = '') => {
        let filterStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
        if (params.tab) {
            filterStr += `&tab=${params.tab}`;
        }

        if (params.action) {
            let all_status = params.action.split(",");
            let array_index = all_status.indexOf(action);
            if (array_index !== -1) {
                all_status.splice(array_index, 1);
            }
            else {
                all_status.push(action);
            }
            filterStr += all_status.length > 0 ? `&action=${all_status.join(",")}` : '';
        }
        else {
            if (action) {
                filterStr += `&action=${action}`;
            }
        }
        if (dataState.filter) {
            filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
        }
        if (dataState.sort && dataState.sort.length > 0) {
            filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
        }
        history.push(filterStr);
    };

    const dataStateChange = (dataState) => {
        setDataState(dataState);
        createHistoryUrl(dataState);
    };


    const handleSalaryPopup = (info = false) => {
        setLoading(true);
        setSalaryPopup(false);
        setSalaryId(info.salary_id);
        setTimeout(function () { setSalaryPopup(true); setLoading(false); }, 1);
    }

    const showExcelPopup = () => {
        setShowImportReturnFromExcel(true);
        setShowImportReturnFromTxt('');
    }

    const editInfo = (props) => (

        <td className="saldtddeleditsect text-center">

            {
                props.dataItem.action !== 'DELETED' ? (
                    <span>
                        <span onClick={() => { setCurrentSalary(props.dataIndex); handleSalaryPopup(props.dataItem) }}>
                            <img src={editIcon} alt="" />
                        </span>
                        <span className="border_left mx-1"></span>
                        <span onClick={() => { setShowConfirm(true); setDeleteData(props.dataItem); }}>
                            <img src={deleteIcon} alt="" />
                        </span>
                    </span>



                ) : null}
            {/**
            <FontAwesomeIcon icon={faPencilAlt} onClick={() => { handleSalaryPopup(props.dataItem) }} />
            <FontAwesomeIcon icon={faTrash} style={{ marginLeft: '10px' }} onClick={() => { setShowConfirm(true); setDeleteData(props.dataItem); }} />
            */}
        </td >
    )

    const isColumnActive = (field) => {
        let active = false;
        if (dataState.filter) {
            dataState.filter.filters.map((filter, index) => {
                if (filter.filters[0].field === field) {
                    active = true;
                }
                return true;
            })
        }
        return active;
    };

    //const amountCell = (val) => (<td className='align-right'> {formatNumber(parseFloat(val).toFixed(2))}</td>);
    const amountCell = (val) => { return formatNumber(parseFloat(val).toFixed(2)) };

    useEffect(() => {
        let action = params.action ? params.action.split(",") : [];
        setStatusFilter(action);
    }, [searchStr]);

    const rowRender = (trElement, props) => {
        const action = props.dataItem.action;
        const green = {
            color: "#08C802",
        };
        const blue = {
            color: "#0000ff",
        };

        const black = {
            color: "#00000",
        };

        const deletedRecord = {
            opacity: "0.5",
            backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQIW2NkYGCQBAAAIwAbDJgTxgAAAABJRU5ErkJggg==')`,
            backgroundRepeat: ' repeat-x',
            backgroundPosition: ' 50% 50%',
        }

        let trProps = {};
        if (componentImport) {
            trProps = {
                style: action === 'ADDED' ? green : action === 'UPDATED' ? blue : action === 'DELETED' ? deletedRecord : black,
            };
        }
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    const renderSubTextTitle = (title, subText, color) => {
        return (
            <div title={title + ': ' + subText}>
                {title} {' '}
                {subText && (<div className="text-right">
                    <span title={title + ': ' + subText} className="b2btbtrthtextclr" style={{ color: `#0000ff` }}>{subText}</span>
                </div>)}
            </div>
        );
    }

    const importExelError = (data) => {
        setShowImportReturnFromExcel(false);
        //setFailImportChallanListErrors(data);
        //setShowFailImportChallanList(true);
    };

    const downloadExcelTemp = async () => {
        setLoading(true);
        try {
            let params = `?form_type=${headerDataDetails.form_type}&quarter=${headerDataDetails.quarter}&return_type=REGULAR`;
            const result = await downloadExcelTemplate(params);
            const csiData = new Blob([result], { type: 'application/xls' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(csiData);
            let formType = headerDataDetails.form_type;
            link.setAttribute('download', 'ExpressTDS_' + formType + '_Template.xlsx');
            document.body.appendChild(link);
            link.click();

        } catch (err) {
            console.error('Error in export: ', err);
        }
        setLoading(false);
    };

    const fetchTransactionOverView = () => { return null; };

    let width1 = document.body.clientWidth;


    return (
        <div>
            <ShowAlert
                error={error}
                success={success}
                onClose={() => {
                    setError(null);
                    setSuccess(null);
                }}
            />
            <ConfirmPopup
                show={showConfirm}
                handleProceed={() => deleteSalary()}
                confirmText={`Are you sure that you want to delete the salary data for the ${deleteData.deductee_name} ? Please note that this process is irreversible. Click Delete button to continue.`}
                confirmButtonText="Delete"
                handleClose={() => {
                    setDeleteData(false);
                    setShowConfirm(false);
                }}
            />

            <ImportReturnFromExcel
                show={showImportReturnFromExcel}
                handleClose={(refresh) => {
                    setShowImportReturnFromExcel(false);
                    if (refresh) {
                        history.go();
                    }
                }}
                deductorId={params.deductor_id}
                returnId={params.return_id}
                importExelError={importExelError}
                showImportReturnFromTxt={showImportReturnFromTxt}
                downloadExcelTemp={downloadExcelTemp}
                fetchTransactionOverView={fetchTransactionOverView}
            />

            {
                showCreateForm ? (
                    <AddDeductee
                        onClose={() => setShowCreateForm(false)}
                        deductorId={params.deductor_id}
                        showDeducteeFormLeft={true}
                        componentImport={componentImport}
                        left={width1 - 1850}
                    />
                )
                    : null
            }

            {/* <ImportEmployeeSalary
                show={showImportEmployee}
                deductor_id={params.deductor_id}
                return_id={params.return_id}
                financialYear={financialYear}
                headerDataDetails={headerDataDetails}
                handleClose={(refresh) => {
                    setShowImportEmployee(false);
                    if (refresh) {
                        history.go();
                    }
                }}

            /> */}

            {salaryPopup &&
                <AddSalary
                    show={salaryPopup}
                    onHide={setSalaryPopup}
                    salaryId={salaryId}
                    deductor_id={params.deductor_id}
                    return_id={params.return_id}
                    getEmployeeData={getEmployeeSalaryListWithSummary}
                    filterPostData={filterPostData}
                    setListSucess={setSuccess}
                    financialYear={financialYear}
                    componentImport={componentImport}
                    salaryList={salaryId ? salaryList : false}
                    setCurrentSalary={setCurrentSalary}
                    currentSalary={currentSalary}
                    handleSalaryPopup={handleSalaryPopup}
                    setShowCreateForm={setShowCreateForm}
                />}

            {showDedcuteeEditForm
                ? (
                    <EditDeductee
                        onClose={(reload, msg = '') => {
                            setShowDedcuteeEditForm(false);
                            if (msg) {
                                setSuccess(msg);
                            }
                            if (reload) {
                                setTimeout(() => {
                                    getEmployeeSalaryListWithSummary(filterPostData);
                                }, 300);
                            }
                        }}
                        deductorId={params.deductor_id}
                        deducteeId={deducteeId}
                        deducteeInfo={false}
                        headerNotUpdate={true}
                        componentImport={props.componentImport}
                    />
                )
                : null}

            {loading ? <LoadingIndicator /> : null}
            <div className="container-fluid mt-2">
                <div className="row">
                    <div className={props.componentImport ? "col-xl-0" : "col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-12"}>
                        <div className="float-left pt-2">
                            {(prevPagePath === "transaction" && !componentImport) ?
                                <Button
                                    className="f-12 btnblmrsm backtoclr"
                                    variant="link"
                                    style={{ color: '#FC7D72' }}
                                    // onClick={() => history.goBack()}
                                    onClick={() => history.push({
                                        pathname: `${BASE_PATH}deductee-transactions`,
                                        search: `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`,
                                    })}
                                >
                                    {'< '}Back to Deductee Transactions
                                </Button>
                                : !componentImport ? (
                                    <Button
                                        className="f-12 btnblmrsm backtoclr "
                                        variant="link"
                                        style={{ color: '#FC7D72' }}
                                        onClick={() => history.push({
                                            pathname: `${BASE_PATH}deductor?deductor_id=${params.deductor_id}&financial_year=${financialYear}`,
                                        })}>
                                        {'< '}Back to Return Dashboard
                                    </Button>
                                ) : null
                            }
                        </div>
                    </div>
                    <div className={props.componentImport ? "col-xl-9 col-lg-8 col-md-7 col-sm-8 col-xs-12 text-right salarytitlerpadd" : "col-xl-8 col-lg-8 col-md-8 col-sm-4 col-xs-12 text-center"}>
                        <h4>
                            List of Salary Details
                        </h4>

                    </div>
                    {componentImport ? (
                        <div className={props.componentImport ? "col-xl-3 col-lg-4 col-md-5 col-sm-4 text-right pt-2" : "col-md-4 mt-2 text-right"}>

                            <div className="form-check-inline" onClick={() => { createHistoryUrl(dataState, 'ADDED') }}>
                                <label className="form-check-label" for="check1">
                                    <input type="checkbox" className="form-check-input verticalaligtext" checked={statusFilter.includes('ADDED') ? true : false} /> New Added
                                </label>
                            </div>
                            <div className="form-check-inline" onClick={() => { createHistoryUrl(dataState, 'UPDATED') }}>
                                <label className="form-check-label" for="check1">
                                    <input type="checkbox" className="form-check-input verticalaligtext" checked={statusFilter.includes('UPDATED') ? true : false} /> Updated
                                </label>
                            </div>
                            <div className="form-check-inline" onClick={() => { createHistoryUrl(dataState, 'DELETED') }}>
                                <label className="form-check-label" for="check1">
                                    <input type="checkbox" className="form-check-input verticalaligtext" checked={statusFilter.includes('DELETED') ? true : false} /> Deleted
                                </label>
                            </div>
                        </div>
                    ) : null}
                    {!componentImport ? (
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-12 mt-2">
                            <div className="float-right attextclr btnAhref">
                                <span className="importSexcelbtn" onClick={(e) => showExcelPopup()}>  Import Salary using Excel</span>
                            </div>
                        </div>
                    ) : null}

                </div>

                <div className="mt-2">
                    <div className="card padding_top_manage">
                        <div className="row">
                            <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                                {
                                    showSkeleton ? <Skeleton12ColumnGrid /> :
                                        <>
                                            <Tooltip anchorElement="pointer" position="right">
                                                <Grid
                                                    className="table table-striped listofsalarydetailstb"
                                                    style={componentImport ? { height: '300px', overflowX: 'auto' } : { height: '400px', overflowX: 'auto' }}
                                                    data={salaryList}
                                                    {...dataState}
                                                    rowRender={rowRender}
                                                    onDataStateChange={(event) => dataStateChange(event.dataState)}
                                                    filterOperators={{
                                                        text: [
                                                            { text: 'grid.filterContainsOperator', operator: 'contains' },
                                                        ],
                                                        numeric: [
                                                            { text: 'grid.filterGteOperator', operator: 'gte' },
                                                            { text: 'grid.filterLteOperator', operator: 'lte' },
                                                            { text: 'grid.filterLtOperator', operator: 'lt' },
                                                            { text: 'grid.filterGtOperator', operator: 'gt' },
                                                            { text: 'grid.filterEqOperator', operator: 'eq' },
                                                            { text: 'grid.filterNotEqOperator', operator: 'neq' },
                                                        ],
                                                    }}
                                                    sortable
                                                >
                                                    <Column
                                                        field="deductee_name"
                                                        headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                                                        title={<AddSalaryCell
                                                            field="deductee_name"
                                                            subtextData={{ subText: salarySummary?.total_num_rows, color: '0000ff' }}
                                                            dataState={dataState}
                                                            dataStateChange={dataStateChange}
                                                            handleClick={() => handleSalaryPopup()} />
                                                        }
                                                        width="150"
                                                        cell={(event) => (
                                                            <td title={event.dataItem.deductee_name} style={{ pointerEvents: event.dataItem.action === 'DELETED' ? "none" : "" }} className="deductee_link" onClick={() => { setDeducteeId(event.dataItem.deductee_id); setShowDedcuteeEditForm(true); }}>
                                                                {' '}
                                                                {componentImport ? (
                                                                    <span style={{ color: event.dataItem.action === 'ADDED' ? "#08C802" : event.dataItem.action === 'UPDATED' ? '#0000ff' : '#000000', verticalAlign: 'sub' }}>
                                                                        {event.dataItem.deductee_name}
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        {event.dataItem.deductee_name}
                                                                    </span>
                                                                )
                                                                }
                                                            </td>
                                                        )}
                                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                        sortable={false}
                                                    />
                                                    <Column
                                                        field="pan"
                                                        headerClassName={isColumnActive('pan') ? 'active' : ''}
                                                        title="PAN"
                                                        width="150"
                                                        columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                                                        cell={(event) => (
                                                            <td className='text-center'>
                                                                {' '}
                                                                <span style={{ verticalAlign: 'sub' }}>
                                                                    {event.dataItem.pan}
                                                                </span>
                                                            </td>
                                                        )}
                                                    />
                                                    <Column
                                                        field="net_salary_amtp"
                                                        headerClassName={isColumnActive('net_salary_amtp') ? 'active' : ''}
                                                        title={renderSubTextTitle('Net Salary',
                                                            componentImport ? formatNumber(salarySummary?.total_net_salary_amtp ?? 0)
                                                                : formatNumber(salarySummary?.sum_net_salary_amtp ?? 0),
                                                            '')}

                                                        filter="numeric"
                                                        cell={(event) => (
                                                            <td className='text-right'>
                                                                {' '}
                                                                {amountCell(event.dataItem.net_salary_amtp)}
                                                            </td>
                                                        )}
                                                        width="150"
                                                        columnMenu={ColumnMenu}
                                                    />

                                                    <Column
                                                        field="total_deduction_vi_a_amtp"
                                                        headerClassName={isColumnActive('total_deduction_vi_a_amtp') ? 'active' : ''}
                                                        title={renderSubTextTitle('Ded VI-A',
                                                            componentImport ? formatNumber(salarySummary?.total_deduction_vi_a_amtp ?? 0)
                                                                : formatNumber(salarySummary?.sum_total_deduction_vi_a_amtp ?? 0),
                                                            '')}

                                                        filter="numeric"
                                                        cell={(event) => (
                                                            <td className='text-right'>
                                                                {' '}
                                                                {amountCell(event.dataItem.total_deduction_vi_a_amtp)}
                                                            </td>
                                                        )}
                                                        width={componentImport ? "155" : "150"}
                                                        columnMenu={ColumnMenu}
                                                    />
                                                    <Column
                                                        field="total_net_taxable_income_amtp"
                                                        headerClassName={isColumnActive('total_net_taxable_income_amtp') ? 'active' : ''}
                                                        title={renderSubTextTitle('Net Taxable Inc',
                                                            componentImport ? formatNumber(salarySummary?.total_net_taxable_income_amtp ?? 0)
                                                                : formatNumber(salarySummary?.sum_total_net_taxable_income_amtp ?? 0),
                                                            '')}
                                                        filter="numeric"
                                                        cell={(event) => (
                                                            <td className='text-right'>
                                                                {' '}
                                                                {amountCell(event.dataItem.total_net_taxable_income_amtp)}
                                                            </td>
                                                        )}
                                                        width={componentImport ? "155" : "150"}
                                                        columnMenu={ColumnMenu}
                                                    />
                                                    <Column
                                                        field="total_tax_computed_amtp"
                                                        headerClassName={isColumnActive('total_tax_computed_amtp') ? 'active' : ''}
                                                        title={renderSubTextTitle('Total Tax',
                                                            componentImport ? formatNumber(salarySummary?.total_tax_computed_amtp ?? 0)
                                                                : formatNumber(salarySummary?.sum_total_tax_computed_amtp ?? 0),
                                                            '')}
                                                        filter="numeric"
                                                        cell={(event) => (
                                                            <td className='text-right'>
                                                                {' '}
                                                                {amountCell(event.dataItem.total_tax_computed_amtp)}
                                                            </td>
                                                        )}
                                                        width={componentImport ? "155" : "150"}
                                                        columnMenu={ColumnMenu}
                                                    />
                                                    <Column
                                                        field="total_tds_deducted_amtp"
                                                        headerClassName={isColumnActive('total_tds_deducted_amtp') ? 'active' : ''}
                                                        title={renderSubTextTitle('TDS Ded',
                                                            componentImport ? formatNumber(salarySummary?.total_tds_deducted_amtp ?? 0)
                                                                : formatNumber(salarySummary?.sum_total_tds_deducted_amtp ?? 0),
                                                            '')}

                                                        filter="numeric"
                                                        cell={(event) => (
                                                            <td className='text-right'>
                                                                {' '}
                                                                {amountCell(event.dataItem.total_tds_deducted_amtp)}
                                                            </td>
                                                        )}
                                                        width={componentImport ? "155" : "150"}
                                                        columnMenu={ColumnMenu}
                                                    />
                                                    <Column
                                                        field="excess_short_tds_amtp"
                                                        headerClassName={isColumnActive('excess_short_tds_amtp') ? 'active' : ''}
                                                        title={renderSubTextTitle('Excess/Short TDS',
                                                            componentImport ? formatNumber(salarySummary?.excess_short_tds_amtp ?? 0)
                                                                : formatNumber(salarySummary?.sum_excess_short_tds_amtp ?? 0),
                                                            '')}
                                                        filter="numeric"
                                                        cell={(event) => (
                                                            <td className='text-right'>
                                                                {' '}
                                                                {amountCell(event.dataItem.excess_short_tds_amtp)}
                                                            </td>
                                                        )}
                                                        width={componentImport ? "155" : "180"}
                                                        columnMenu={ColumnMenu}
                                                    />
                                                    <Column
                                                        field="editIcon" sortable={false}
                                                        title=" " cell={editInfo}
                                                        width={componentImport ? "90" : "87"}
                                                    />
                                                </Grid>
                                            </Tooltip>
                                        </>
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <form>
                                            {componentImport ? (
                                                <span className="pl-3">
                                                    <div className="form-check-inline">
                                                        <label className="form-check-label">
                                                            <span style={{ color: '#0000ff' }}><FontAwesomeIcon icon={faSquare} /></span>
                                                            <span className="pl-1">Updated</span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline">
                                                        <label className="form-check-label">
                                                            <span style={{ color: 'cyan' }}><FontAwesomeIcon icon={faSquare} /></span>
                                                            <span className="pl-1" style={{ textDecoration: 'line-through' }}>Deleted</span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline">
                                                        <label className="form-check-label">
                                                            <span style={{ color: '#08C802' }}><FontAwesomeIcon icon={faSquare} /></span>
                                                            <span className="pl-1">New Added</span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check-inline">
                                                        <label className="form-check-label">
                                                            <span style={{ color: '#333' }}><FontAwesomeIcon icon={faSquare} /></span>
                                                            <span className="pl-1">Original</span>
                                                        </label>
                                                    </div>
                                                </span>
                                            ) : null}
                                            <div className={componentImport ? "form-check-inline col-md-7" : "form-check-inline col-md-12"} >
                                                <ShowMorePagination
                                                    cursor={cursor}
                                                    fetchData={getEmployeeSalaryListData}
                                                    postData={filterPostData}
                                                    loading={showMoreLoading}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {componentImport ? (
                                    <div className="form-check-inline" style={{ display: 'inline-block', width: '98%', textAlign: 'right' }}>
                                        <label className="form-check-label">
                                            <span className="pl-1 text-warning">{consoStatus && consoStatus.conso_file_status === 'IMPORT_IN_PROGRESS' ? 'IMPORT IN PROGRESS PLEASE WAIT....' : null}</span>
                                        </label>
                                    </div>
                                ) : null}

                                {/* <ShowMorePagination
                                    cursor={cursor}
                                    fetchData={getEmployeeData}
                                    postData={filterPostData}
                                    loading={showMoreLoading}
                                /> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};

export default EmployeeSalaryList;
