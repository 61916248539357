/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Button from 'react-bootstrap/Button';
import { BASE_PATH, HEADER_CONNECTOR_DIGEST_KEY, CONNECTOR_ERRORS_MAPPING } from '../../../app/constants';
import {
    getReturnsFilingData, getVerifiedChallanCountData,
    getVerifiedPanCountData, updateFiling,
    getLateFeeData,
    getLowerDeductionCertificateData,
} from '../../../api/interestFeesCalculatorAPI';
import { editDeductor, editCorrectionDeductor, getCorrectionDeductorById, getDeductorById } from '../../../api/deductorAPI';
import { checkFVUFileStatus, downloadFVUFile, generateFVUFile, generateRegularFVUFileWithConnector } from '../../../api/FVUGeneration';
import { geteFilingStatusFromConnector, uploadReturnFromConnector } from '../../../api/localConnectorAPI';
import { fvuGenerationFormFields } from './FVUGenerationFormHelper';
import ShowAlert from '../../../components/ShowAlert';
import { selectHeaderData } from '../../header/headerSlice';
import EditDeductee from '../../deducteeList/EditDeductee';
import EditChallan from '../../../features/challanList/EditChallan';
import { getDeducteeTransactionDetail, getCorrectionDeducteeTransactionDetail, getTransactionV3Summary } from '../../../api/deducteeTransactionsAPI';
import AddSalary from '../../../features/deductorDashboard/employeeDashboard/addSalary';
import { getCurrentFinancialYear } from '../../../utils/UtilityFunctions';
import DownloadCSIForFVU from './DownloadCSIForFVU';
import DownloadCSIFromEFilingPortalForFVU from './DownloadCSIFromEFilingPortalForFVU';
import ConfirmPopupNew from '../../../components/ConfirmPopupNew';
import eFilingStatusSubmittedIcon from '../../../images/check.gif';
import eFilingStatusFailedIcon from '../../../images/tds1-close-icon.gif';
import eFilingStatusInProgressIcon from '../../../images/working-inprogress-img.png';
import ConnectorAdapter from '../../../components/kdkConnector/ConnectorAdapter';
import UpdateEFilingCredsWithoutVerify from '../../challanList/UpdateEFilingCredsWithoutVerify';
import ReturnFlowTimeLine from '../../deducteeTransactions/ReturnFlowTimeLine';
import EditDeducteeTransactionsV2 from '../../deducteeTransactions/EditDeducteeTransactionsV2';
import LoadingIndicator from '../../../components/loadingIndicator/LoadingIndicator';
import { getFormObject, getDeductorObject } from '../../../components/createDeductorForm/DeductorFormHelper';
import EditDeductorForm from '../returnHealth/EditDeductorForm';
import AddChallan from '../../challanList/AddChallan';
import LowerDeductionCertPopup from '../returnHealth/LowerDeductionCertPopup';
import { gocallApi } from '../../../api/issueCertificateAPI';
import AuthorizedSignatoryV1 from './AuthorizedSignatoryV1';
import PrevFilingDetV1 from './PrevFilingDetV1';
import LikelyDefault from './LikelyDefault';
import ReviewAndFixError from './ReviewAndFixError';
import ReturnProgressBar from './ReturnProgressBar';
import FilingStatus from './FilingStatus';
import "./FVUGenerationV1.scss";
import ShowTopperFound from './ShowTopperFound';

const FVU_BUTTON_TOOLTIP = 'Invalid quarter selected, statement cannot be prepared or filed before end of the respective quarter.';

const FVUGenerationV1 = (props) => {
    const { componentImport } = props;
    const location = useLocation();
    const params = queryString.parse(location.search);
    // let paramsVals = '';
    // if (location.state && location.state.paramsVals) {
    //   paramsVals = location.state.paramsVals;
    // } else {
    //   paramsVals = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
    // }
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [downloadFVU, setDownloadFVU] = useState(false);
    const [success, setSuccess] = useState(false);
    const [returnsFilingDataInfo, setReturnsFilingDataInfo] = useState({});
    const [verifiedChallanCountInfo, setVerifiedChallanCountInfo] = useState({});
    const [verifiedPanCountInfo, setVerifiedPanCountInfo] = useState({});
    const [lateFeeCountInfo, setLateFeeCountInfo] = useState({});
    const [deductorDetail, setDeductorDetail] = useState({});
    const [deductorAddressChangedVal, setDeductorAddressChangedVal] = useState(false);
    const [, setLastFillingVal] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showDeducteeForm, setShowDeducteeForm] = useState(false)
    const [showChallanEditForm, setShowChallanEditForm] = useState(false);
    const [editDeducteeTransaction, setEditDeducteeTransaction] = useState({});
    const [editdeducteeInfo, setEditDeducteeInfo] = useState({});
    const [deducteeTrnsactnInfo, setDeducteeTrnsactnInfo] = useState({});
    const [editChallanInfo, setEditChallanInfo] = useState({});
    const [salaryPopup, setSalaryPopup] = useState(false);
    const [salaryId, setSalaryId] = useState(false);
    const headerDataDetails = useSelector(selectHeaderData);
    const [progressBarLoader, setProgressBarLoader] = useState(false);

    if (headerDataDetails.financial_year_formatted) {
        let getYearIndex = headerDataDetails.financial_year_formatted.split('-');
        var getOtherFinancialYear = parseInt(getYearIndex[0]);
    }

    const financialYear = getOtherFinancialYear ? getOtherFinancialYear : getCurrentFinancialYear();
    const [, setPrnyesVal] = useState(false);
    const [prnVal, setPrnVal] = useState('');
    const [prnStaus, setPrnStaus] = useState(false);
    const [downloadCSI, setDownloadCSI] = useState(false);
    const [genrateFvuErrors, setGenrateFvuErrors] = useState([]);
    const [downloadCSIFileType, setDownloadCSIFileType] = useState('');
    const [isFvuSuccess, setIsFvuSuccess] = useState(null);
    const [efilingProcess, setEfilingProcess] = useState(false);
    const [port, setPort] = useState(0);
    const [efilingStatus, setEfilingStatus] = useState(null);
    const [efilingStatusIcon, setEfilingStatusIcon] = useState(null);
    const [efilingStatusText1, setEfilingStatusText1] = useState(null);
    const [efilingStatusText2, setEfilingStatusText2] = useState(null);
    const [actionId, setActionId] = useState(null);
    const [actionStatus, setActionStatus] = useState(null);
    const [confirmFvuDownload, setConfirmFvuDownload] = useState(false);
    const [showIncomeTaxCredentialPopup, setShowIncomeTaxCredentialPopup] = useState(false);
    const [deductorData, setDeductorData] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [showStoperList, setShowStoperList] = useState(null);
    const [showChallanForm, setShowChallanForm] = useState(false);
    const [disableFVUButton, setDisableFVUButton] = useState(false);
    const [showDisableButtonTooltip, setShowDisableButtonTooltip] = useState(false);
    const [showLowerDeductionPopup, setShowLowerDeductionPopup] = useState(false);
    const [ldcSummary, setLdcSummary] = useState({})
    const [ldcListData, setLdcListData] = useState([])
    const [fvufillingStatus, setFvufillingStatus] = useState(null)

    const getReturnsFilingDataFunc = (filterStr, headerData) => {
        async function fetchEverything() {
            async function fetchReturnsFilingData() {
                const result = await getReturnsFilingData(filterStr);
                setReturnsFilingDataInfo(result.data);
            }
            async function fetchVerifiedChallanCountData() {
                const result = await getVerifiedChallanCountData(filterStr);
                setVerifiedChallanCountInfo(result?.data ?? {});
            }
            async function fetchVerifiedPanCountData() {
                const result = await getVerifiedPanCountData(filterStr);
                setVerifiedPanCountInfo(result.data);
            }

            async function lateFeeCountData() {
                const result = await getLateFeeData(filterStr);
                setLateFeeCountInfo(result?.data);
            }

            async function certificateCountData() {
                const result = await getLowerDeductionCertificateData(filterStr);
                const data = result?.data ?? {};
                setLdcSummary(data?.summary)
                setLdcListData(data?.list)
            }

            async function fetchDeductorDetail() {
                // Dont update header in case of Regular return, since we are not passing return_id
                const result = componentImport ?
                    await getCorrectionDeductorById(params.deductor_id, params.return_id) :
                    await getDeductorById(params.deductor_id, false);
                setDeductorDetail(result.data);
                if (result?.data) {
                    setDeductorData(getFormObject(result?.data));
                    !componentImport &&  validateDeductorInformationForMissingDetails(result?.data, headerData);
                }
            }
            try {

                if (componentImport === true) {
                    //Correction
                    await Promise.all([
                        fetchDeductorDetail(),
                        fetchVerifiedChallanCountData(),

                    ]);
                } else {
                    //Regular
                    await Promise.all([
                        fetchReturnsFilingData(),
                        fetchVerifiedChallanCountData(),
                        fetchVerifiedPanCountData(),
                        fetchDeductorDetail(),
                        lateFeeCountData(),
                        certificateCountData(),
                    ]);
                }

                setError(null);
            } catch (err) {
                setError(err.toString());
            }
        }
        fetchEverything();
    };



    const handleOptionChange = (e, key) => {
        if (key === 'prncheckbox') {
            fvuGenerationFormFields.prncheckbox = e.target.value;
            changePRNVal(e);

        }
        if (key === 'address_changed') {
            fvuGenerationFormFields.deductor_address_changed = e.target.value;
            setDeductorAddressChangedVal(() => e.target.value);
            updateLastFilling(fvuGenerationFormFields)
        }

    }

    const changePRNVal = (e) => {
        if (String(e.target.value) === '0') {
            if (fvuGenerationFormFields.prn) updateLastFilling(fvuGenerationFormFields)
            setPrnStaus(false);
        } else {
            setPrnStaus(true);
        }
    };

    async function updateFilingDataAddress(data) {
        setError(null);
        setSuccess(null);
        const dataPost = {};
        dataPost.deductor_id = params.deductor_id;
        dataPost.return_id = params.return_id;
        if (deductorDetail) {
            dataPost.deductor_name = deductorDetail.deductor_name;
            dataPost.deductor_category = deductorDetail.deductor_category;
            dataPost.deductor_status = deductorDetail.deductor_status;
            dataPost.pan = deductorDetail.pan;
            dataPost.branch = deductorDetail.branch;
            dataPost.contact_info = {};
            dataPost.contact_info.primary_country_code = deductorDetail.contact_info.primary_country_code;
            dataPost.contact_info.alternate_country_code = deductorDetail.contact_info.alternate_country_code;
            dataPost.contact_info.primary_email = deductorDetail.contact_info.primary_email;
            dataPost.contact_info.alternate_email = deductorDetail.contact_info.alternate_email;
            dataPost.contact_info.primary_mobile_no = deductorDetail.contact_info.primary_mobile_no;
            dataPost.contact_info.alternate_mobile_no = deductorDetail.contact_info.alternate_mobile_no;
            dataPost.contact_info.person_name = deductorDetail.contact_info.person_name;
            dataPost.contact_info.person_name_color = deductorDetail.contact_info.person_name_color;
            if (deductorDetail && deductorDetail.contact_person && deductorDetail.contact_person.contact_info) {
                dataPost.contact_person = {
                    contact_info: {
                        person_name: deductorDetail.contact_person.contact_info.person_name,
                        primary_country_code: deductorDetail.contact_person.contact_info.primary_country_code,
                        primary_mobile_no: deductorDetail.contact_person.contact_info.primary_mobile_no,
                        alternate_country_code: deductorDetail.contact_person.contact_info.alternate_country_code,
                        alternate_mobile_no: deductorDetail.contact_person.contact_info.alternate_mobile_no,
                        primary_email: deductorDetail.contact_person.contact_info.primary_email,
                        alternate_email: deductorDetail.contact_person.contact_info.alternate_email,
                    }
                }
            }
        }

        if (deductorDetail && data.address_building_no && data.deductor_address_changed !== '0') {
            dataPost.address = {};
            dataPost.address.building_no = data.address_building_no;
            dataPost.address.building_name = data.address_building_name;
            dataPost.address.street_name = data.address_street_name;
            dataPost.address.area = data.address_area;
            dataPost.address.city = data.address_city;
            dataPost.address.state_id = data.address_state_id;
            dataPost.address.pincode = data.address_pincode;
        } else {
            dataPost.address = {};
            dataPost.address.building_no = deductorDetail.address.building_no;
            dataPost.address.building_name = deductorDetail.address.building_name;
            dataPost.address.street_name = deductorDetail.address.street_name;
            dataPost.address.area = deductorDetail.address.area;
            dataPost.address.city = deductorDetail.address.city;
            dataPost.address.state_id = deductorDetail.address.state_id;
            dataPost.address.pincode = deductorDetail.address.pincode;
        }

        if (deductorDetail && data.responsible_person_address_building_no && data.responsible_person_address_changed !== '0') {
            dataPost.responsible_person = {};
            dataPost.responsible_person.address = {};
            dataPost.responsible_person.contact_info = {};

            dataPost.responsible_person.contact_info.primary_country_code = deductorDetail.responsible_person.contact_info.primary_country_code;
            dataPost.responsible_person.contact_info.alternate_country_code = deductorDetail.responsible_person.contact_info.alternate_country_code;
            dataPost.responsible_person.contact_info.primary_email = deductorDetail.responsible_person.contact_info.primary_email;
            dataPost.responsible_person.contact_info.alternate_email = deductorDetail.responsible_person.contact_info.alternate_email;
            dataPost.responsible_person.contact_info.primary_mobile_no = deductorDetail.responsible_person.contact_info.primary_mobile_no;
            dataPost.responsible_person.contact_info.alternate_mobile_no = deductorDetail.responsible_person.contact_info.alternate_mobile_no;
            dataPost.responsible_person.contact_info.person_name = deductorDetail.responsible_person.contact_info.person_name;
            dataPost.responsible_person.contact_info.person_name_color = deductorDetail.responsible_person.contact_info.person_name_color;

            dataPost.responsible_person.address.building_no = data.responsible_person_address_building_no;
            dataPost.responsible_person.address.building_name = data.responsible_person_address_building_name;
            dataPost.responsible_person.address.street_name = data.responsible_person_address_street_name;
            dataPost.responsible_person.address.area = data.responsible_person_address_area;
            dataPost.responsible_person.address.city = data.responsible_person_address_city;
            dataPost.responsible_person.address.state_id = data.responsible_person_address_state_id;
            dataPost.responsible_person.address.pincode = data.responsible_person_address_pincode;

            dataPost.responsible_person.designation = deductorDetail.responsible_person.designation;
            dataPost.responsible_person.pan = deductorDetail.responsible_person.pan;

        } else {
            if (deductorDetail && deductorDetail.responsible_person && deductorDetail.responsible_person.address) {
                dataPost.responsible_person = {
                    address: {
                        building_no: deductorDetail.responsible_person.address.building_no,
                        building_name: deductorDetail.responsible_person.address.building_name,
                        street_name: deductorDetail.responsible_person.address.street_name,
                        area: deductorDetail.responsible_person.address.area,
                        city: deductorDetail.responsible_person.address.city,
                        state_id: deductorDetail.responsible_person.address.state_id,
                        pincode: deductorDetail.responsible_person.address.pincode,
                    },
                    contact_info: {
                        person_name: deductorDetail.responsible_person.contact_info.person_name,
                        primary_country_code: deductorDetail.responsible_person.contact_info.primary_country_code,
                        primary_mobile_no: deductorDetail.responsible_person.contact_info.primary_mobile_no,
                        alternate_country_code: deductorDetail.responsible_person.contact_info.alternate_country_code,
                        alternate_mobile_no: deductorDetail.responsible_person.contact_info.alternate_mobile_no,
                        primary_email: deductorDetail.responsible_person.contact_info.primary_email,
                        alternate_email: deductorDetail.responsible_person.contact_info.alternate_email,
                    },
                    designation: deductorDetail.responsible_person.designation,
                    pan: deductorDetail.responsible_person.pan,

                }
            }
        }

        async function callAPI() {
            async function editDeductorfunc() {
                const result1 = componentImport ? await editCorrectionDeductor(dataPost) : await editDeductor(dataPost);
                return result1;
            }
            try {
                await editDeductorfunc();
                if (parseInt(verifiedChallanCountInfo?.total_book_entry_challan) < parseInt(verifiedChallanCountInfo?.total_challan)) {
                    setDownloadCSI(true);
                }
                else {
                    callGenerateFVUFile();
                }

            } catch (err) {
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        callAPI();
    }

    const updateLastFilling = async (data) => {
        const fillDataPost = {};
        fillDataPost.deductor_id = params.deductor_id;
        fillDataPost.return_id = params.return_id;
        fillDataPost.previous_prn = data.previous_prn;
        fillDataPost.deductor_address_changed = data.deductor_address_changed;
        fillDataPost.responsible_person_address_changed = data.responsible_person_address_changed;
        if (data.prncheckbox === '1') {
            fillDataPost.previous_prn = data.prn;
        } else {
            fillDataPost.previous_prn = '';
        }
        try {
            await updateFiling(fillDataPost);

        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        }
    }

    async function updateFilingData(data, csi_type) {
        setError(null);
        setSuccess(null);
        async function callAPI() {
            try {

                // If Deductor address has changed, then we need to update the Deductor also
                if (parseInt(data.responsible_person_address_changed) !== 0
                    || parseInt(data.deductor_address_changed) !== 0) {
                    await updateFilingDataAddress(data);
                }

                if (parseInt(verifiedChallanCountInfo?.total_book_entry_challan) < parseInt(verifiedChallanCountInfo?.total_challan)) {
                    setDownloadCSI(true);
                    setDownloadCSIFileType(csi_type);
                }
                else {
                    callGenerateFVUFile();
                }
            } catch (err) {
                setError(err.toString());
            } finally {
                setLoading(false);
            }
        }
        setLoading(true);
        callAPI();
    }

    const callDownloadFVUFile = () => {
        setError(null);
        setSuccess(null);
        async function callDownloadFVUAPI() {
            async function downloadCSIFileFnc() {
                const downloadFileStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
                const result = await downloadFVUFile(downloadFileStr);
                return result;
            }
            try {
                const result = await downloadCSIFileFnc();
                const fullFileName = `${headerDataDetails.tan}_${headerDataDetails.form_type}_${headerDataDetails.financial_year_formatted}_${headerDataDetails.quarter}.zip`;
                const csiData = new Blob([result], { type: 'application/zip' });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(csiData);;
                link.setAttribute('download', fullFileName);
                document.body.appendChild(link);
                link.click();

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
                setDownloadFVU(false);
            }
        }
        setDownloadFVU(true);
        callDownloadFVUAPI();
    };

    const checkFVUFileStatusOnServer = async () => {
        const payload = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
        try {
            const result = await checkFVUFileStatus(payload);
            if (result.data?.process_failed_error) {
                setError(result?.data?.process_failed_error ?? "");
                setProgressBarLoader(false);
                setFvufillingStatus(null);

                return;
            } else if (!result.data?.csi || !result.data?.fvu || !result.data?.txt || result.data?.pb_val !== 100) {
                setFvufillingStatus(result?.data ?? null);
                setTimeout(() => checkFVUFileStatusOnServer(), 5000);
                return;
            }
            setFvufillingStatus(result?.data ?? null);

            setTimeout(() => {
                if (componentImport && result.status === 200) {
                    setSuccess('Correction TXT file generated successfully.');
                }
                if (result.data && result.data.errors) {
                    setGenrateFvuErrors(result.data.errors);
                    setIsFvuSuccess(false);
                } else {
                    setIsFvuSuccess(true);
                }
                if (result.data && result.data.warnings) {
                    setGenrateFvuErrors(result.data.warnings);
                }
                setDownloadCSI(false);
                setProgressBarLoader(false);
                setFvufillingStatus(null);
            }, 2000);
            
        } catch (err) {
            if (parseInt(err.code) === 412) {
                setDownloadCSI(false);
            } else {
                setError(err.message);
            }
            setProgressBarLoader(false);
            setFvufillingStatus(null);

        }
    }


    const callGenerateFVUFile = () => {
        async function callGenerateFVUFileAPI() {
            async function generateFVUFileFnc() {
                setLoading(true);
                const downloadFileStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
                const result = await generateFVUFile(downloadFileStr);
                return result;
            }
            try {
                setLoading(true);
                const result = await generateFVUFileFnc();
                setFvufillingStatus(result.data ?? null);

                setTimeout(() => checkFVUFileStatusOnServer(), 3000);
            } catch (err) {
                if (parseInt(err.code) === 412) {
                    setDownloadCSI(false);
                }; 

                setError(err.message);
                setProgressBarLoader(false);
                setFvufillingStatus(null);

            } finally {
                setLoading(false);
            }
        }
        setProgressBarLoader(true);
        callGenerateFVUFileAPI();
    };

    const updateTransactionInfo = (deducteeTransactionInfo) => {
        setEditDeducteeTransaction(deducteeTransactionInfo);
        async function getDeducteeTrnsactionDetail() {
            async function getDetail() {
                const result = componentImport ? await getCorrectionDeducteeTransactionDetail(`?deductee_transaction_id=${deducteeTransactionInfo.entity_id}&deductor_id=${params.deductor_id}&return_id=${params.return_id}`) : await getDeducteeTransactionDetail(`?deductee_transaction_id=${deducteeTransactionInfo.entity_id}&deductor_id=${params.deductor_id}&return_id=${params.return_id}`);
                setDeducteeTrnsactnInfo(result);
                setShowEditForm(true);
            }
            try {
                await Promise.all([
                    getDetail(),
                ]);
            } catch (err) {
            }
        }
        getDeducteeTrnsactionDetail();
    }

    const updateDeducteeInfo = (deducteeTransactionInfo) => {
        setEditDeducteeInfo(deducteeTransactionInfo);
        setShowDeducteeForm(true);
    };

    const updateChallanInfo = (challanInfo) => {
        setEditChallanInfo(challanInfo);
        setShowChallanEditForm(true);
    };

    const updateSalaryInfo = (salaryInfo) => {
        setSalaryId(salaryInfo.entity_id);
        setSalaryPopup(true);
    }

    // checking the error and get error id and error type and open the specific form by calling component 
    const viewError = (errorInfo) => {
        if (errorInfo.entity_id && errorInfo.entity_type === "DEDUCTOR") {
            const newPageUrl = componentImport ? `${BASE_PATH}correction?deductor_id=${params.deductor_id}&return_id=${params.return_id}&tab=edit_deductor` : `${BASE_PATH}edit-deductor/${errorInfo.entity_id}`;
            window.open(newPageUrl, "_blank") //to open new page
        } else if (errorInfo.entity_id && errorInfo.entity_type === "TRANSACTION") {
            updateTransactionInfo(errorInfo);
        } else if (errorInfo.entity_id && errorInfo.entity_type === "CHALLAN") {
            updateChallanInfo(errorInfo);
        } else if (errorInfo.entity_id && errorInfo.entity_type === "SALARY") {
            updateSalaryInfo(errorInfo);
        } else if (errorInfo.entity_id && errorInfo.entity_type === "DEDUCTEE") {
            updateDeducteeInfo(errorInfo);
        }
    }

    const onClose = (reload, msg = '') => {
        setShowDeducteeForm(false);
        setShowEditForm(false);
        setShowChallanEditForm(false);

        if (msg) {
            setSuccess(msg);
        }

    };

    const startEfilingProcess = (port) => {

        async function eFilingProcess(port) {

            try {
                setLoading(true);
                const payload = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
                const result = await generateRegularFVUFileWithConnector(payload);
                const responseData = result.data ?? null;
                const responseHeader = result.headers ?? null;

                //Connector request data
                const connectorRequest = {
                    "data": responseData.data
                };
                let extraHeader = {};
                extraHeader[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                try {
                    const connectorResponse = await uploadReturnFromConnector(port, connectorRequest, extraHeader);
                    if (Number(connectorResponse.status) === 1) {
                        const action_id = connectorResponse?.data?.action_id;
                        const action_status = connectorResponse?.data?.action_status;
                        if (action_id && action_status) {
                            setActionId(action_id);
                            setActionStatus(action_status);
                            setEfilingStatusBox(action_status);
                        }
                    }
                } catch (err) {
                    setError(err.message);
                    setEfilingStatus(null);
                }
            } catch (err) {
                setError(err.message);
                setEfilingStatus(null);
                if (err.code === 424) {
                    setShowIncomeTaxCredentialPopup(true);
                }
            } finally {
                setLoading(false);
            }
        }
        eFilingProcess(port);
    }

    const setEfilingStatusBox = (action_status, finalResponseData = {}) => {
        let statusIcon = null;
        let statusText1 = null;
        let statusText2 = null;
        if (action_status === 'IN_PROGRESS') {
            statusIcon = eFilingStatusInProgressIcon
            statusText1 = 'Filing is in progress in other browser window. Please do not click back or close any window.';
            statusText2 = 'Filing Status Kindly wait for the process to get completed and return back here to check the updated status.';
        } else if (action_status === 'COMPLETE') {
            const quarter = finalResponseData?.quarter ?? '';
            const formtype = finalResponseData?.formtype ?? '';
            const ack_no = finalResponseData?.ack_no ?? '';
            statusIcon = eFilingStatusSubmittedIcon
            statusText1 = `Submitted successfully for Form ${formtype} | Quarter ${quarter}`;
            statusText2 = `The Acknowledgement Number is ${ack_no}`;
        } else if (action_status === 'FAILURE') {
            statusIcon = eFilingStatusFailedIcon
            statusText1 = 'Return Filing was not successful.';
            statusText2 = 'Please try again after sometime.';
            if (Object.keys(finalResponseData).length > 0 && finalResponseData?.message) {
                statusText1 = finalResponseData?.message;
            }
        }

        setEfilingStatus((prev) => {
            prev = action_status
            return prev
        });
        setEfilingStatusIcon((prev) => {
            prev = statusIcon
            return prev
        });
        setEfilingStatusText1((prev) => {
            prev = statusText1
            return prev
        });
        setEfilingStatusText2((prev) => {
            prev = statusText2
            return prev
        });

    }

    const getFilingStatus = async (portNumber, actionId) => {
        async function fetchEverything() {
            try {
                const actionStatusResult = await geteFilingStatusFromConnector(portNumber, actionId);
                if (Number(actionStatusResult.status) === 1) {
                    const connectorResult = actionStatusResult.data;
                    const action_status = connectorResult['action_status'] ?? null;
                    setActionStatus(action_status);
                    let finalResponseData = {};
                    if (action_status === 'COMPLETE' || action_status === 'FAILURE') {
                        finalResponseData = connectorResult;
                    }
                    setTimeout(() => {
                        setEfilingStatusBox(action_status, finalResponseData);
                    }, 100)
                }
            } catch (err) {
                setActionStatus('FAILURE')
                setEfilingStatusBox('FAILURE');
                setError(CONNECTOR_ERRORS_MAPPING[err.message] ?? err.message ?? CONNECTOR_ERRORS_MAPPING['default']);

            }
        }
        fetchEverything();
    }

    

    const validateDeductorInformationForMissingDetails = (data, headerData) => {
        let count = 0;
        let validateStatus = false;

        if ((data?.deductor_name ?? "") === "") {
            validateStatus = true;
            count += 1;
        }
        if ((data?.deductor_category ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.contact_info?.primary_mobile_no ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.contact_info?.primary_email ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.address?.building_no ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.address?.city ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.address?.state_id ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.address?.pincode ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.responsible_person?.pan ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.responsible_person?.designation ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.responsible_person?.contact_info?.person_name ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.responsible_person?.contact_info?.primary_mobile_no ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.responsible_person?.contact_info?.primary_email ?? "") === "") {
            validateStatus = true;
            count += 1;
        } if ((data?.branch ?? "") === "") {
            validateStatus = true;
            count += 1;
        }

        const showStoperListData = showStoperList ?? {}; //[...showStoperList, 'DeductorProfile'];

        if (validateStatus) {
            showStoperListData['DeductorProfile'] = count;
            setShowStoperList({ ...showStoperListData });
        }

        if (headerData?.form_type) {

            if (headerData?.form_type === "24Q") {
                showStoperListData['DeducteeTransactions'] = 0;
                delete showStoperListData['DeducteeTransactions']
                setShowStoperList({ ...showStoperListData });
            } else {
                validateForShortAmountTransactions(params.deductor_id, params.return_id, showStoperListData);
            }
        }

    }

    const validateForShortAmountTransactions = async (deductor_id, return_id, showStoperList) => {
        try {
            const payload = {
                "deductor_id": deductor_id,
                "return_id": return_id,
                "additional_filter": "35",
            }
            const result = await getTransactionV3Summary(payload);
            if (result?.data) {
                if (result?.data?.transaction_count > 0) {
                    showStoperList['DeducteeTransactions'] = result?.data?.transaction_count;
                } else {
                    showStoperList['DeducteeTransactions'] = 0;
                    delete showStoperList['DeducteeTransactions']
                }
                setShowStoperList({ ...showStoperList });
            }
        } catch (err) {
            setError(err?.message);
        }
    }

    const showFormClose = () => {
        setShowForm(false);
    }

    const saveDeductor = (values) => {
        var requestData = getDeductorObject(values);
        requestData['deductor_id'] = params?.deductor_id;

        async function callAPI() {
            async function editDeductor2() {
                const result = await editDeductor(requestData);
                return result;
            }
            try {
                const result = await editDeductor2();
                if (result.status === 1) {
                    onClose();
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }

            } catch (err) {
                setError(err.toString());
            }
        }
        callAPI();
    }

    const getLdcDataForReturn = async (deductor_Id, returnId) => {

        const url = `/api/v1/report/health/ldc/list?deductor_id=${deductor_Id}&return_id=${returnId}`;

        try {
            const result = await gocallApi("get", url);
            const data = result?.data ?? {};
            setLdcSummary(data?.summary)
            setLdcListData(data?.list)
        } catch (err) {
            if (err.message) setError(err.message);
            else setError(err);
        }
    }

    useEffect(() => {
        const filterStr = `?return_id=${params.return_id}&deductor_id=${params.deductor_id}`;
        getReturnsFilingDataFunc(filterStr, headerDataDetails);
        //eslint-disable-next-line
    }, [params.return_id, params.deductor_id, headerDataDetails]);

    useEffect(() => {
        if (deductorDetail && deductorDetail.address) {
            fvuGenerationFormFields.address_building_no = deductorDetail.address.building_no;
            fvuGenerationFormFields.address_building_name = deductorDetail.address.building_name;
            fvuGenerationFormFields.address_street_name = deductorDetail.address.street_name;
            fvuGenerationFormFields.address_area = deductorDetail.address.area;
            fvuGenerationFormFields.address_city = deductorDetail.address.city;
            fvuGenerationFormFields.address_state_id = deductorDetail.address.state_id;
            fvuGenerationFormFields.address_pincode = deductorDetail.address.pincode;
        }
        if (deductorDetail && deductorDetail.responsible_person) {
            fvuGenerationFormFields.responsible_person_address_building_no = deductorDetail.responsible_person.address.building_no;
            fvuGenerationFormFields.responsible_person_address_building_name = deductorDetail.responsible_person.address.building_name;
            fvuGenerationFormFields.responsible_person_address_street_name = deductorDetail.responsible_person.address.street_name;
            fvuGenerationFormFields.responsible_person_address_area = deductorDetail.responsible_person.address.area;
            fvuGenerationFormFields.responsible_person_address_city = deductorDetail.responsible_person.address.city;
            fvuGenerationFormFields.responsible_person_address_state_id = deductorDetail.responsible_person.address.state_id;
            fvuGenerationFormFields.responsible_person_address_pincode = deductorDetail.responsible_person.address.pincode;
        }
        if (props.componentImport && (deductorDetail && deductorDetail.prn)) {
            fvuGenerationFormFields.prn = deductorDetail.prn;
        }
    }, [deductorDetail]);

    useEffect(() => {
        if (returnsFilingDataInfo) {
            if (returnsFilingDataInfo.deductor_address_changed) {
                setDeductorAddressChangedVal(true);
                fvuGenerationFormFields.deductor_address_changed = '1';
                setLastFillingVal(true);
                fvuGenerationFormFields.lastfilling = '1';
            }
            if (returnsFilingDataInfo.responsible_person_address_changed) {
                fvuGenerationFormFields.responsible_person_address_changed = '1';
                setLastFillingVal(true);
                fvuGenerationFormFields.lastfilling = '1';
            }
        }
        if (returnsFilingDataInfo && returnsFilingDataInfo.previous_prn) {
            setPrnyesVal(true);
            setPrnVal(returnsFilingDataInfo.previous_prn);
            fvuGenerationFormFields.prn = returnsFilingDataInfo.previous_prn;
            fvuGenerationFormFields.prncheckbox = '1';
            setPrnStaus(true);
        } else {
            setPrnyesVal(false);
            setPrnVal('');
            fvuGenerationFormFields.prn = '';
            fvuGenerationFormFields.prncheckbox = '0';
            setPrnStaus(false);
        }
    }, [returnsFilingDataInfo]);

    useEffect(() => {
        let handle;
        if (port > 0 && actionId !== null && actionStatus === 'IN_PROGRESS') {
            handle = setInterval(() => {
                getFilingStatus(port, actionId);
            }, 3000); // Check every 3 seconds
            return () => {
                clearInterval(handle);
                handle = 0;
            };
        }
    }, [port, actionId, actionStatus]);

    useEffect(() => {
        
        if (headerDataDetails?.quarter_end_date && new Date() <= new Date(headerDataDetails?.quarter_end_date)) {
            setDisableFVUButton(true);
            setShowDisableButtonTooltip(true);
        }else {
            setDisableFVUButton(false);
            setShowDisableButtonTooltip(false);
        }
    }, [headerDataDetails])

    return (
        <div className="fuvgeneration_tdsuser">
            {downloadFVU || loading ? <LoadingIndicator /> : null}
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />
            {efilingProcess
                ? <ConnectorAdapter
                    show={efilingProcess}
                    handleClose={() => setEfilingProcess(false)}
                    setPort={setPort}
                    handleProceed={(port) => startEfilingProcess(port)}
                />
                : null
            }
            {showEditForm
                ? (
                    <EditDeducteeTransactionsV2
                        onClose={onClose}
                        deductorId={params.deductor_id}
                        returnId={params.return_id}
                        editDeducteeTransaction={editDeducteeTransaction}
                        deducteeTrnsactnInfo={deducteeTrnsactnInfo}
                        deducteeTransactionID={editDeducteeTransaction.entity_id}
                        headerDataDetails={headerDataDetails}
                        componentImport={componentImport}
                        show={showEditForm}
                        setShowEditForm={setShowEditForm}
                    />
                )
                : null}

            {showDeducteeForm
                ? (

                    <EditDeductee
                        onClose={onClose}
                        deductorId={params?.deductor_id}
                        deducteeId={editdeducteeInfo?.entity_id}
                        deducteeInfo={false}
                        headerNotUpdate={true}
                        componentImport={componentImport}
                    />
                )
                : null}


            {showChallanEditForm
                ? (
                    <EditChallan
                        onClose={onClose}
                        deductorId={params.deductor_id}
                        returnId={params.return_id}
                        challanId={editChallanInfo.entity_id}
                        componentImport={componentImport}
                    />
                )
                : null}

            {
                confirmFvuDownload &&
                <ConfirmPopupNew
                    show={confirmFvuDownload}
                    handleProceed={() => {
                        callDownloadFVUFile()
                        setConfirmFvuDownload(false);
                    }}
                    handleProceed2={() => {
                        setConfirmFvuDownload(false);
                    }}
                    confirmText={`Errors found in this FUV file. It is suggested to fix all the error(s) before you proceed ahead.`}
                    confirmButtonText="Download anyway"
                    confirmButtonText2="Fix Now"
                    handleClose={() => {
                        setConfirmFvuDownload(false);
                    }}
                />
            }


            {downloadCSI && downloadCSIFileType === 'OLTAS'
                ? (
                    <DownloadCSIForFVU
                        show={downloadCSI}
                        handleClose={() => {
                            setDownloadCSI(false);
                            setDownloadCSIFileType("");
                        }}
                        handleProceed={() => setDownloadCSI(false)}
                        deductorId={params.deductor_id}
                        returnId={params.return_id}
                        callGenerateFVUFile={callGenerateFVUFile}
                        loadingError={loading}
                    />
                )
                : null}

            {downloadCSI && downloadCSIFileType === 'EFILING'
                ? (
                    <DownloadCSIFromEFilingPortalForFVU
                        show={downloadCSI}
                        handleClose={() => {
                            setDownloadCSI(false);
                            setDownloadCSIFileType("");
                        }}
                        handleProceed={() => setDownloadCSI(false)}
                        deductorId={params.deductor_id}
                        returnId={params.return_id}
                        callGenerateFVUFile={callGenerateFVUFile}
                        loadingError={loading}
                        setLoading={setLoading}
                    />
                )
                : null}


            {/* salary popup start */}
            {salaryPopup && <AddSalary
                show={salaryPopup}
                onHide={setSalaryPopup}
                salaryId={salaryId}
                deductor_id={params.deductor_id}
                return_id={params.return_id}
                getEmployeeData={callGenerateFVUFile}
                filterPostData=""
                setListSucess={setSuccess}
                financialYear={financialYear}
                showDefaultError={true}
                componentImport={componentImport}
            />}

            {
                showIncomeTaxCredentialPopup &&
                <UpdateEFilingCredsWithoutVerify
                    show={showIncomeTaxCredentialPopup}
                    handleClose={() => setShowIncomeTaxCredentialPopup(false)}
                    deductorId={params.deductor_id ?? ''}
                    tan={headerDataDetails.tan ?? ''}
                    handleProceed={() => {
                        ///start eFiling process 
                        setEfilingProcess(true);
                    }}
                />
            }

            {
                showChallanForm
                    ? (
                        <AddChallan
                            onClose={() => {
                                setShowChallanForm(false);
                            }}
                            deductorId={params?.deductor_id}
                            returnHealthSummary={
                                {
                                    challanDeposited: false,
                                    fees_amtp: Math.min(((lateFeeCountInfo?.late_fees_days ?? 0) * 200), (lateFeeCountInfo?.late_fees_amtp ?? 0)),
                                    interest_amtp: 0,
                                    tds_amtp: 0
                                }
                            }
                        />
                    )
                    : null
            }

            {showForm &&
                <EditDeductorForm
                    deductorId={params?.deductorId}
                    returnId={params?.returnId}
                    onClose={showFormClose}
                    deductorData={deductorData}
                    saveDeductor={saveDeductor}
                    error={error}
                    setError={setError}
                />}


            {showLowerDeductionPopup &&
                <LowerDeductionCertPopup
                    returnId={params.return_id}
                    deductorId={params.deductor_id}
                    onClose={() => setShowLowerDeductionPopup(false)}
                    ldcListData={ldcListData}
                    getLdcDataForReturn={getLdcDataForReturn} />
            }

            <span id="create_new_salary" onClick={() => { setSalaryId(0); setSalaryPopup(true); }}></span>
            {/* salary popup end */}

            {!componentImport && <ReturnFlowTimeLine returnId={params.return_id} deductorId={params.deductor_id} currentPage={`FVUgeneration`} />}
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-7 col-lg-7 col-md-12 col-sm-12">
                        <div className="row">
                            <AuthorizedSignatoryV1 deductorDetail={deductorDetail} params={params} />
                            {componentImport === undefined &&
                                <PrevFilingDetV1
                                    deductorAddressChangedVal={deductorAddressChangedVal}
                                    BASE_PATH={BASE_PATH}
                                    params={params}
                                    handleOptionChange={handleOptionChange}
                                    headerDataDetails={headerDataDetails}
                                    fvuGenerationFormFields={fvuGenerationFormFields}
                                    prnStaus={prnStaus}
                                    prnVal={prnVal}
                                    setPrnVal={setPrnVal}
                                    updateLastFilling={updateLastFilling}
                                />}
                        </div>
                    </div>

                    {showStoperList && Object.keys(showStoperList ?? {})?.length > 0 ?
                        <>
                            <ShowTopperFound
                                showStoperList={showStoperList}
                                deductorId={params?.deductor_id}
                                returnId={params?.return_id}
                                setShowForm={setShowForm}
                            />
                        </>
                        :
                        (showStoperList !== null) || (componentImport) ?
                            <div className="col-12 col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                <Tooltip anchorElement="target" position="right" >
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <div className="efiling_flow_generatefvuit_btnbox">
                                                <button
                                                    style={{ pointerEvents: 'auto' }}
                                                    title={showDisableButtonTooltip ? FVU_BUTTON_TOOLTIP : ''}
                                                    className={`mr-2 btn btn-default efiling_flow_generatefvuit_greybgclr mr-1 ${disableFVUButton || loading ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if (disableFVUButton || loading) {
                                                            return null;
                                                        } else {
                                                            updateFilingData(fvuGenerationFormFields, 'EFILING')
                                                        }
                                                        setIsFvuSuccess(null)
                                                    }}
                                                >
                                                    Generate FVU (IT Portal)
                                                </button>
                                                <button
                                                    style={{ pointerEvents: 'auto' }}
                                                    title={showDisableButtonTooltip ? FVU_BUTTON_TOOLTIP : ''}
                                                    className={`btn btn-default efiling_flow_generatefvuit_greybgclr ${disableFVUButton || loading ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if (disableFVUButton || loading) {
                                                            return null;
                                                        } else {
                                                            updateFilingData(fvuGenerationFormFields, 'OLTAS')
                                                        }
                                                        setIsFvuSuccess(null)
                                                    }}
                                                >
                                                    Generate FVU (OLTAS)
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            isFvuSuccess === false && genrateFvuErrors.length > 0 &&

                                            <div className="col-md-12 text-center pt-4">
                                                <button className="btn btn-default efiling_flow_generatefvuit_greybgclr mr-1"
                                                    onClick={() => {
                                                        setConfirmFvuDownload(true);
                                                    }}>
                                                    Download FVU
                                                </button>
                                                <button class="btn btn-default efiling_flow_generatefvuit_greybgclr disabled">E-File</button>
                                            </div>
                                        }
                                        {
                                            isFvuSuccess === null &&
                                            <div className="col-md-12 text-center pt-4">
                                                <button className="btn btn-default efiling_flow_generatefvuit_greybgclr mr-1 disabled">
                                                    Download FVU
                                                </button>
                                                <button className="btn btn-default efiling_flow_generatefvuit_greybgclr disabled">
                                                    E-File
                                                </button>
                                            </div>
                                        }
                                        {
                                            isFvuSuccess === true &&
                                            <div className="col-md-12 text-center pt-4">
                                                <button className={'btn btn-default efiling_flow_generatefvuit_greybgclr validup_downFVUbtn mr-1'} onClick={() => callDownloadFVUFile()}>
                                                    Download FVU
                                                </button>
                                                <Button
                                                    className={`btn btn-default efiling_flow_generatefvuit_greybgclr mr-1 ${actionStatus === 'IN_PROGRESS' ? 'disabled' : ''}`}
                                                    onClick={() => { setEfilingProcess(true) }}
                                                >
                                                    E-File
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </Tooltip>
                            </div>
                            :
                            null
                    }
                </div>

                {!componentImport && <LikelyDefault
                    deductorId={params.deductor_id}
                    returnId={params.return_id}
                    financialYear={financialYear}
                    verifiedChallanCountInfo={verifiedChallanCountInfo}
                    lateFeeCountInfo={lateFeeCountInfo}
                    verifiedPanCountInfo={verifiedPanCountInfo}
                    verifiedFilerCountInfo={verifiedPanCountInfo}
                    headerDataDetails={headerDataDetails}
                    setShowChallanForm={setShowChallanForm}
                    setShowLowerDeductionPopup={setShowLowerDeductionPopup}
                    ldcSummary={ldcSummary}
                />}

                {isFvuSuccess === false && genrateFvuErrors.length > 0 &&
                    <ReviewAndFixError genrateFvuErrors={genrateFvuErrors} viewError={viewError} callGenerateFVUFile={callGenerateFVUFile} />
                }

                {progressBarLoader && fvufillingStatus && <ReturnProgressBar status={fvufillingStatus} />}

                {
                    isFvuSuccess === true && port > 0 && efilingStatus !== null &&
                    <FilingStatus
                        efilingStatusIcon={efilingStatusIcon}
                        efilingStatusText1={efilingStatusText1}
                        efilingStatusText2={efilingStatusText2}
                    />
                }

            </div>
        </div>
    )
}

export default FVUGenerationV1
