import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import './UserList.css';
import "../../components/profile/Profile.css"
import "./UserManagement.scss"
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import queryString from 'query-string';
import { gocallApi } from '../../api/issueCertificateAPI';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { DEDUCTOR_CATEGORIES, DEDUCTOR_RECORD_IN_ONE_PAGE, BASE_PATH, DEDUCTOR_CATEGORIES_TYPES } from '../../app/constants';
import { Link } from 'react-router-dom';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';


const ClientUserMapListing = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [deductorList, setDeductorList] = useState(null);
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [dataState, setDataState] = useState({});
  const searchStr = location.search;
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const [filterPostData, setFilterPostData] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [userAssignFlag, setuserAssignFlag] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [selectKey, setSelectKey] = useState(Date.now());

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: '0px',
      borderColor: '#CCC', // or any other color you want
      boxShadow: 'none', // Remove box shadow on focus

      height: '30px',
      minHeight: '30px',
      '&:hover': {
        borderColor: '#CCC', // No border color on hover
        boxShadow: 'none', // No box shadow on hover
        padding: '0px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure menu appears above other elements
      fontSize: '10px', // Set the font size of options
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '10px', // Set the font size of options
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'gray', // Placeholder text color
      fontSize: '10px',
      padding: '0px', // Padding
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black', // Text color of selected value
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '2px 8px',
      "&:hover": {
        padding: '2px 8px',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      "&:hover": {
        padding: '2px 8px',
      },
    }),
  };


  const getDeductorList = async (postData) => {

    (deductorList?.cursor?.page && deductorList?.cursor?.page > 1) ? setShowMoreLoading(true) : setLoading(true);

    let url = `/api/v1/user/deductor-wise/list`
    try {
      const result = await gocallApi("post", url, postData)

      let newDeductorList = [];
      if (deductorList?.length > 0) newDeductorList = deductorList;
      if (result?.data?.cursor?.current_page > 1) {
        newDeductorList = newDeductorList.concat(result?.data?.list ?? []);
      } else {
        newDeductorList = result?.data?.list ?? [];
      }
      setDeductorList(newDeductorList ?? []);
      setCursor(result?.data?.cursor ?? {});
      setAllUsers(result?.data?.all_users ?? []);

    } catch (err) {
      setError(err.message);
    } finally {
      (deductorList?.cursor?.page && deductorList?.cursor?.page > 1) ? setShowMoreLoading(false) : setLoading(false);
    }
  }

  useEffect(() => {

    const postData = getParamsHistoryUrl();
    getDeductorList(postData);

    // eslint-disable-next-line
  }, [searchStr, userAssignFlag]);



  const getParamsHistoryUrl = () => {

    const postData = {
      //user_id: params.user_id,
      //assigned: 1,
    };

    if (params?.sort_on) {
      dataState.sort = [{ field: params.sort_on, dir: params.order_by ?? 'asc' }];
      postData.sort_on = params.sort_on ? params.sort_on : '';
      postData.order_by = params.order_by ? params.order_by : "";
    }

    if (params.filters) {
      dataState.filter = { filters: [] };
      dataState.filter.filters = JSON.parse(atob(params.filters));
      postData.filters = JSON.parse(atob(params.filters));
    }

    if (params.page) {
      postData.page = params.page;
    } else {
      postData.page = 1;
    }
    postData.limit = params.limit ?? DEDUCTOR_RECORD_IN_ONE_PAGE;

    setDataState(dataState);
    setFilterPostData(postData);
    return postData;
  };


  const toggleRowExpansion = (rowId) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId)); // Toggle expansion
  };


  const handleSelection = async (selectedOption, deductorId) => {
    if (!selectedOption) return;

    const url = `/api/v1/user/deductor/assign-unassign`
    const payload = {
      user_id: selectedOption.value,
      deductor_ids: [{
        deductor_id: deductorId,
        assign: 1,
      }],
    }
    try {
      const result = await gocallApi("post", url, payload);
      setuserAssignFlag(true);
      setSuccess(result?.message)
      setSelectKey(Date.now()); // Update key to force re-render
    } catch (err) {
      setError(err.message);
    }
  };

  const rowRender = (trElement, props) => {
    let dataItem = props.dataItem ?? {};
    const key = trElement._owner.index;
    const city = dataItem.city ? dataItem.city?.toLowerCase() : "";
    if (dataItem?.deductor_category === "Z") {
      dataItem.deductor_category = "";
    }
    // Calculate unassigned users
    const unassignedUsers = allUsers.filter(
      (user) =>
        !dataItem.assigned_users.some(
          (assigned) => assigned.mobile_no === user.mobile_no
        )
    );

    const unassignedUsersArray = [];
    unassignedUsers.forEach((item) => {
      unassignedUsersArray.push({ value: item.tenant_user_id, label: `${item.full_name}` })
    })

    console.log("unassignedUsersArray", unassignedUsersArray)
    const isExpanded = expandedRow === key;

    return (
      <>
        <tr key={key}>
          <td className="text-left sd_usermanage_textoverflow">
            <span data-toggle="tooltip" data-placement="top" title={dataItem?.deductor_name}>
              {dataItem?.deductor_name}
            </span>
          </td>
          <td className="text-center">
            <span
              className="clickablebtn dark_color_text"
              style={{ fontWeight: "700" }}
              onClick={() => toggleRowExpansion(key)}
            >
              {dataItem?.cnt_assigned_users}
            </span>
          </td>
          <td className="text-center">
            {dataItem?.tan}
          </td>
          <td className="text-left textoverflow overflowtooltipconttdsamt text-capitalize">
            {city}
          </td>
          <td className="text-left textoverflow overflowtooltipconttdsamt">
            {dataItem?.deductor_category && <span>{DEDUCTOR_CATEGORIES?.[dataItem.deductor_category] ?? "-"}</span>}
          </td>         
        </tr>
        {isExpanded && (
          <tr key={`${key}-expanded`}>
            <td colSpan={5} >
              <div className='card ' style={{ width: "50%", margin: "0 auto", padding: '0px' }}>
                <table className="table table-striped usermanagement_table mb-0 "  >
                  <thead>
                    <tr>
                      <th width="200">
                        User Name
                      </th>
                      <th width="150">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataItem?.assigned_users.map((item, index) => {
                      return (
                        <>
                          <tr className="usermanage_alternet_grey">
                            <td width="150" className="text-left usermanagement_textoverflow">
                              <span data-toggle="tooltip" data-placement="top" title="Agasthya gautam">
                                {item?.full_name}
                              </span>
                            </td>
                            <td width="100" className="text-center">
                              <input
                                type="checkbox"
                                checked={true}
                                onChange={(e) => {
                                  setuserAssignFlag(false);
                                  handleUnassignSubmit(e.target.checked, item?.mobile_no, dataItem?.deductor_id)
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      )
                    })}

                    {unassignedUsersArray?.length > 0 &&
                      <tr>
                        <td >

                          <Select
                            key={selectKey} // Forces re-render
                            options={unassignedUsersArray}
                            styles={customStyles}
                            className="form-group multi-select"
                            placeholder="Select new user"
                            isSearchable={true}
                            openMenuOnFocus={true}
                            onChange={(selectedOption) => {
                              setuserAssignFlag(false);
                              handleSelection(selectedOption, dataItem?.deductor_id)
                            }}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={true}
                          // isMulti
                          />

                        </td>
                        <td width={100}></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        )}
      </>
    )
  };

  // Submit selected deductor IDs
  const handleUnassignSubmit = async (checked, mobileNo, deductorId) => {

    //unchecked event to execute
    if (checked) {
      return
    }

    const url = `/api/v1/user/deductor/assign-unassign`
    const payload = {
      "user_id": allUsers.find(user => user?.mobile_no === mobileNo)?.tenant_user_id ?? null,
      "deductor_ids": [{
        "deductor_id": deductorId,
        "assign": 0,
      }],
    }

    try {
      const result = await gocallApi("post", url, payload);
      setuserAssignFlag(true);
      setSuccess(result?.message)
    } catch (err) {
      setError(err.message);
    }
  };

  const createHistoryUrl = (filters) => {

    let filterStr = `?page=1&assigned=1`
    if (params.user_id) {
      filterStr += `&user_id=${params.user_id}`;
    }

    if (filters.filter) {
      filterStr += `&filters=${btoa(JSON.stringify(filters.filter.filters))}`;
    }

    if (filters.sort && filters.sort.length > 0) {
      filterStr = filterStr + `&sort_on=${filters.sort[0].field}&order_by=${filters.sort[0].dir}`;
    }

    history.push(filterStr);
  };

  const dataStateChange = (dataState) => {
    createHistoryUrl(dataState);
    setDataState(dataState);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };

  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <div className="container mt-2">

        <div className="row ">
          <div className="col-xl-5 col-lg-5 col-md-4 col-md-12 col-12">

            <Link className="backtoclr" to={`${BASE_PATH}user-management`}>
              {'< '}
              Back to User Management
            </Link>

          </div>
          <div className="col-xl-3 col-lg-3 col-md-8 col-md-12 col-12">
            <h4 className="usermanagement_manageassing_title">
              Client User Mapping
            </h4>
          </div>

        </div>

        <div className="row mt-2 ">
          <div className="col-md-12">
            <div className='card pending-request-column' style={{ padding: '0px' }}>

              <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                <Grid
                  className={`table table-striped usermanagement_table mb-1`}
                  data={deductorList}
                  rowRender={rowRender}
                  {...dataState}
                  style={{ maxHeight: '555px' }}
                  sortable
                  onDataStateChange={(event) => dataStateChange(event.dataState)}
                  filterOperators={{
                    text: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                    ],
                    dropdown: [
                      { text: 'grid.filterEqOperator', operator: 'in' },
                    ],
                    numeric: [
                      { text: 'grid.filterGteOperator', operator: 'gte' },
                      { text: 'grid.filterLteOperator', operator: 'lte' },
                      { text: 'grid.filterLtOperator', operator: 'lt' },
                      { text: 'grid.filterGtOperator', operator: 'gt' },
                      { text: 'grid.filterEqOperator', operator: 'eq' },
                      { text: 'grid.filterNotEqOperator', operator: 'neq' },
                    ],
                    textWithEmpty: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                      { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                    ],
                  }}
                >

                  <Column
                    width="250"
                    field="deductor_name"
                    filter="text"
                    title="Deductor Name"
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('deductor_name') ? 'active' : ''}
                  />

                  <Column
                    width="120"
                    field="cnt_assigned_users"
                    title="Assigned Users"
                    filter="numeric"
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('cnt_assigned_users') ? 'active' : ''}
                  />

                  <Column
                    width="100"
                    field="tan"
                    filter="text"
                    title='TAN'
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('tan') ? 'active' : ''}
                  />

                  <Column
                    width="150"
                    field="city"
                    filter="text"
                    title='Location'
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter {...props} />)}
                    headerClassName={isColumnActive('city') ? 'active' : ''}

                  />
                  <Column
                    width="150"
                    field="deductor_category"
                    title='Company Type'
                    filter="dropdown"
                    columnMenu={dataState && ((props) => <ColumnMenu hideSecondFilter filterList={DEDUCTOR_CATEGORIES_TYPES} {...props} />)}
                    headerClassName={isColumnActive('deductor_category') ? 'active' : ''}
                  />


                </Grid>
                <ShowMorePagination
                  cursor={cursor}
                  fetchData={getDeductorList}
                  postData={filterPostData}
                  loading={showMoreLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientUserMapListing;
