import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { getFvuRegularOltasCSI, getFvuRegularOltasCaptcha } from '../../../api/FVUGeneration';

import './FVUGenerationNew.css';

const DownloadCSIForFVU = ({ history, ...props }) => {
  const {
    show, handleClose, deductorId, callGenerateFVUFile, loadingError
  } = props;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [oltasLoginId, setOltasLoginId] = useState(0);
  const [oltasCaptchaImg, setOltasCaptchaImg] = useState('');
  const [oltasCaptcha, setOltasCaptcha] = useState('');


  const getCaptcha = () => {
    async function fetchEverything() {
      async function fetchOltasCaptcha() {
        const result = await getFvuRegularOltasCaptcha();
        setOltasCaptchaImg(result.captcha_image);
        setOltasLoginId(result.opc_id);
      }

      try {
        await Promise.all([
          fetchOltasCaptcha(),
        ]);
        setError(null);
      } catch (err) {
        // console.error('error: ', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const verifyCatpcha = () => {
    if (oltasCaptcha.length === 0) {
      setError('Please enter captcha!');
      return;
    }

    const data = {
      deductor_id: deductorId,
      opc_id: oltasLoginId,
      captcha_text: oltasCaptcha,
      //action: 'CSI_DOWNLOAD',
    };

    async function fetchEverything() {
      async function getDataFromOltasFunc() {
        await getFvuRegularOltasCSI(data);
        callGenerateFVUFile();

      }
      try {
        await Promise.all([
          getDataFromOltasFunc(),
        ]);
        setError(null);
        handleClose();
      } catch (err) {
        // console.error('error: ', err);
        setError(err.message)
        if (parseInt(err.code, 10) === 412) {
          setError(err.message);
        }
        setError(err.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  useEffect(() => {
    getCaptcha('');
      //eslint-disable-next-line
  }, [deductorId]);

  return (
    <>
      <Modal
        className="modelbox_sm downloadcsiffvu_modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton modhepaddsm className="doncoltscsih">
          <Modal.Title>Get CSI from OLTAS</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0 px-0 ">
          <div className="container">
            <div className="card pb-0 border-0">
              <div className="row">
       
                    <div className="form-group col-md-12">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail5">
                            OLTAS Captcha
                          </label>
                          <div className="captcha_img"><img src={`data:image/png;base64, ${oltasCaptchaImg}`} alt="" /></div>
                        </div>
                        <div className="form-group col-md-6" >
                          <label htmlFor="captcha">
                            Enter OLTAS Captcha
                            <span className="red_text">*</span>
                            <input
                              type="text"
                              className="form-control"
                              id="captcha"
                              placeholder="Enter OLTAS Captcha"
                              value={oltasCaptcha}
                              onChange={(event) => setOltasCaptcha(event.target.value)}
                              style={{ marginTop: '12px' }}
                              autoComplete={false}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-md-12">
                        <p>
                          <small>
                            Can't read the captcha?
                            <Button variant="link" onClick={() => getCaptcha()} style={{ margin: '-9px', fontSize: 12 }}>Click here</Button>
                            to refresh
                          </small>
                        </p>
                        {/*<a
                          style={{ display: 'hidden', fontSize: '14px' }}
                          id="download-csi-filesss"
                          download="oltas_tan_based.csi"
                          type="text/csi"
                        >
                          This will fetch your latest CSI file from OLTAS
                        </a>*/}
                      </div>
                    </div>
                  

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="downlodcsifoot">
          {error 
            ? (
              <Alert variant="danger" style={{maxWidth:"200px"}}>{error}</Alert>
            )
            : null}

          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
       
              <Button variant="primary" onClick={verifyCatpcha} disabled={loading}>
                {(loading || loadingError) ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {' '}
                    Loading...
                  </>
                ) : 'Proceed'}
              </Button>
            

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadCSIForFVU;
