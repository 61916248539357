/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import './InviteUser.css';
import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import mailIcon from "../../images/mailw.png"
import ShowAlert from '../../components/ShowAlert';
import { inviteUser } from '../../api/profileAPI';
import NumericInput from '../../components/NumericInput';
import ListDeductorsPopup from "./ListDeductorsPopup"

const InviteUser = (props) => {
  const { show, handleClose } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    full_name: '',
    country_code: '+91',
    mobile_no: '',
    email: '',
    employee_no: null,
    designation: null,
    department: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [currentUnselectedIds, setCurrentUnselectedIds] = useState([]); // User-modified selection


  const addUser = async (e) => {
    e.preventDefault();

    const formErr = {};
    const alphaRegex = /^[A-Za-z0-9 .&_\\/-]+$/; 
    //const alphaNumericRegex = /^[a-zA-Z0-9\s]+$/;
   
    if (userData.full_name.trim().length === 0) {
      formErr.full_name = 'Name is required';
    } else if(!alphaRegex.test(userData.full_name)){
      formErr.full_name = 'Enter Valid Name';
    }

    if (!userData.mobile_no.trim().length > 0) {
      formErr.mobile_no = 'Mobile No. is required';
    } else if (userData.mobile_no.trim().length !== 10 || Number(userData.mobile_no) < 6000000000 || Number(userData.mobile_no) > 9999999999) {
      formErr.mobile_no = 'Enter Valid Mobile No';
    }

    var emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (userData.email.trim().length === 0) {
      formErr.email = 'Email is required';
    } else if (!emailRegex.test(userData.email)) {
      formErr.email = 'Enter Valid Email';
    }

    // if (userData.designation && userData.designation.trim().length >= 0 && !alphaNumericRegex.test(userData.designation)) {
    //   formErr.designation = 'Enter Valid Designation. No special characters';
    // }

    // if (userData.department && userData.department.trim().length >= 0 && !alphaNumericRegex.test(userData.department)) {
    //   formErr.department = 'Enter Valid Department. No special characters';
    // }    

    setFormErrors(formErr);

    if (Object.keys(formErr).length > 0) return;

    setLoading(true);
    const data = {
      ...userData,
      excluded_clients_ids: currentUnselectedIds?.length > 0 ? currentUnselectedIds.map((id) => ({
        client_id: id,
      })) : [],
    };
    try {
      const result = await inviteUser(data);
      setSuccess(result.message);
      setTimeout(() => {
        handleClose()
      }, 2000);
    } catch (err) {
      setError(err.message);
      console.error('Error in inviting user: ', err);
    }
    setLoading(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="usr-invite"
      backdrop="static"
      keyboard={false}
      size="lg"
      enforceFocus={false} // Disable focus trapping
    >
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <Modal.Header closeButton className="headerinvite">
        <Modal.Title className=" text-center addpusecent justify-content-center">Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="container">
          
            <div className="row">
              <div className="col-sm-12">
                <div className="row mt-2">

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">
                          Name
                          <span className="red_text">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name"
                          maxLength="30"
                          value={userData.full_name}
                          onChange={(e) => setUserData({ ...userData, full_name: e.target.value })}
                        />
                        {formErrors.full_name ? <span className="error_message">{formErrors.full_name}</span> : null}
                      </div>
                      <div className="form-group col-sm-4">
                        <label htmlFor="exampleFormControlSelect1">Mobile Number</label>
                        <span className="red_text">*</span>
                        <div className="input-group ">
                          <div className="input-group-prepend inpgpbx profileuseraddmob">
                            <img className="flag_icon" src={Indiaflagexp} alt="mobile" />
                          </div>
                          <NumericInput
                            type="tel"
                            className="form-control"
                            name="Mobile"
                            placeholder="Enter Mobile Number"
                            maxLength="10"
                            value={userData.mobile_no}
                            onChange={(e) => setUserData({ ...userData, mobile_no: e.target.value })}
                          />
                        </div>
                        {formErrors.mobile_no ? <span className="error_message">{formErrors.mobile_no}</span> : null}
                      </div>
                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">
                          Email Address
                          <span className="red_text">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Address"
                          autoComplete="new-password"
                          maxLength="50"
                          value={userData.email}
                          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        />
                        {formErrors.email ? <span className="error_message">{formErrors.email}</span> : null}
                      </div>

                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">Employee No.</label>
                        <input
                          type="text"
                          className="form-control"
                          id=""
                           maxLength="30"
                          placeholder="Enter Employee No."
                          value={userData.employee_no}
                          onChange={(e) => {
                            setUserData({ ...userData, employee_no: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">Designation</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Designation"
                          maxLength="30"
                          value={userData.designation}
                          onChange={(e) => setUserData({ ...userData, designation: e.target.value })}
                        />
                         {formErrors.designation ? <span className="error_message">{formErrors.designation}</span> : null}
                      </div>
                      <div className="form-group col-sm-4">
                        <label htmlFor="inputEmail4">Department</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Department"
                          maxLength="30"
                          value={userData.department}
                          onChange={(e) => setUserData({ ...userData, department: e.target.value })}
                        />
                         {formErrors.department ? <span className="error_message">{formErrors.department}</span> : null}
                      </div>
                    </div>

                    <ListDeductorsPopup
                      setCurrentUnselectedIds={setCurrentUnselectedIds}
                      currentUnselectedIds={currentUnselectedIds}
                    />
                    <div className='col-sm-12 mb-2'></div>

                    <div className="col-sm-12">
                      <div className="text-center mb-2">
                        <button className="light_color_bg inviteuser_btnbgclr" 
                        onClick={(e) => addUser(e)}
                        type="button" disabled={loading}>
                          <span class="pr-1">
                            <img src={mailIcon} width="16" alt="" />
                          </span>
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              {' '}
                              Loading...
                            </>
                          ) : <span className='pt-2'>Send Invitation</span>}
                        </button>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>
        
        </div>

      </Modal.Body>

    </Modal>
  );
};

export default InviteUser;
