/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle, faExclamationCircle, faSquare
} from '@fortawesome/free-solid-svg-icons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ColumnMenu } from '../../components/grid/ColumnMenu';
import { AddDeducteeTransactionsIcon } from '../../components/grid/CustomCell';
import { getter } from "@progress/kendo-react-common";
import { Tooltip } from '@progress/kendo-react-tooltip';
import queryString from 'query-string';
import './DeducteeList.css';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import editIcon from '../../images/icons/edit.svg';
import AddDeductee from './AddDeductee';
import EditDeductee from './EditDeductee';
import {
  getCorrectionDeducteesV1, updateCorrectionDeductee
} from '../../api/deducteeAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import { BASE_PATH, CATEGOTY_LIST, DEDUCTEE_TYPE_CORRECTION_RETURN } from '../../app/constants';
import { selectHeaderData } from '../../features/header/headerSlice';
import ShowMorePagination from '../../components/pagination/ShowMorePagination';
import { capitalizeEachWordFirstLetter, getCurrentFinancialYear } from '../../utils/UtilityFunctions';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import ProgressBarForPAN from '../../components/verifyPAN/ProgressBarForPAN';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import BulkPanVerify from '../../components/verifyPAN/BulkPanVerify';

const DEDUCTEE_TYPE = [
  { label: 'Company', value: 'COMPANY' },
  { label: 'Non-Company', value: 'OTHER_THAN_COMPANY' },
];

const DeducteeCorrectionList = ({ history, ...props }) => {
  const { deductorId } = props.match.params;
  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);
  const DATA_ITEM_KEY = "deductee_id";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const { componentImport, consoStatus } = props;

  const headerDataDetails = useSelector(selectHeaderData);
  const finanicalYear = headerDataDetails?.financial_year_formatted ? headerDataDetails?.financial_year_formatted?.split("-")?.[0] : getCurrentFinancialYear();

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editDeductee, setEditDeductee] = useState({});
  const [loading, setLoading] = useState(false);
  const [deducteeList, setDeducteeList] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  const [currentDeductee, setCurrentDeductee] = useState(0);
  const [cursor, setCursor] = useState({ current_page: 1, next_page_url: null });
  const [horizontalView, setHorizontalView] = useState(false);

  const [showBulkPAN, setShowBulkPAN] = useState(false);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [dataState, setDataState] = useState({});
  const [checkedDeducteeData, setCheckedDeducteeData] = useState({});
  const [allCheckData, setAllCheckData] = useState(false);
  const [selectedDeducteeCount, setSelectedDeducteeCount] = useState(0);
  const [filterPostData, setFilterPostData] = useState(false);
  const [deducteeSummary, setDeducteeSummary] = useState({});

  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [checkTracesCredentials, setCheckTracesCredentials] = useState(false);
  const [bulkPanVerificationStatusData, setBulkPanVerificationStatusData] = useState(null);
  const [reloadPanList, setReloadPanList] = useState(false);
  const [disableBulkPanVerificationButton, setDisableBulkPanVerificationButton] = useState(false);
  const [deducteeTypes, setDeducteeTypes] = useState(DEDUCTEE_TYPE)
  const [deducteeTypeOldList, setDeducteeTypeOldList] = useState(true)
  const limit = 15;

  const statusKey = `${headerDataDetails['mobile_number']}_${headerDataDetails['tan']}_${headerDataDetails['financial_year_formatted']}_${headerDataDetails['form_type']}_${headerDataDetails['quarter']}`;

  // We should get Localstorage captcha ref only once as statusKey is generated
  useEffect(() => {
    if (!statusKey) return;

    setBulkPanVerificationStatusData(JSON.parse(localStorage.getItem(statusKey)) ?? null);
  }, [statusKey]);

  const PAN_LIST = [
    { label: 'PAN APPLIED', value: 'PANAPPLIED' },
    { label: 'PAN INVALID', value: 'PANINVALID' },
    { label: 'PAN NOT AVAILABLE', value: 'PANNOTAVBL' },
  ];


  //const DEDUCTEE_TYPE = DEDUCTEE_TYPE_CORRECTION_RETURN;  

  const STATUS_LIST = [
    { label: 'Unverified', value: 'panUnverified' },
    { label: 'PAN Active and Name Mismatched', value: 'panNameMismatch' },
    { label: 'PAN Active and Name Matched', value: 'panMatched' },
    { label: 'Pan Not Found', value: 'panNotFound ' },
    { label: 'PAN Applied, PAN not Available, PAN Invalid', value: 'panDefaultValue' },
  ];

  const ACTIVITY_STATUSES = [
    {
      label: "Unverified",
      color: "#808080",
      icon: faCircle,
      class: "iocnfsuppot"
    },
    {
      label: "PAN Active and Name Mismatched",
      color: "#800080",
      icon: faCircle,
      class: "iocnfsuppot"
    },
    {
      label: "PAN Active and Name Matched",
      color: "#08C802",
      icon: faCircle,
      class: "iocnfsuppot"
    },
    {
      label: "Pan Not Found",
      color: "#ff0000",
      icon: faCircle,
      class: "iocnfsuppot"
    },
    {
      label: "PAN Applied, PAN not Available, PAN Invalid",
      color: "#ff8c00",
      icon: faCircle,
      class: "iocnfsuppot"
    },

  ];


  const getDeducteeLists = (data) => {
    setLoading(true);
    async function fetchEverything() {
      async function fetchDeductees() {
        const result = await getCorrectionDeducteesV1(data);
        if (result.header.form_type === "27EQ" || result.header.form_type === "27Q") {
          if (result.header.financial_year_formatted.split("-")[0] >= 2023) {
            setDeducteeTypeOldList(false)
            setDeducteeTypes(DEDUCTEE_TYPE_CORRECTION_RETURN)
          }
        }
        let new_deducteeList = result?.data?.data ?? [];
        if (result?.data?.cursor.current_page > 1) {
          setDeducteeList(deducteeList.concat(result?.data?.data ?? []));
        } else {
          setDeducteeList(result?.data?.data ?? []);
        }
        setCursor(result?.data?.cursor ?? {});


        setDeducteeSummary(result?.data?.summary ? result.data.summary : {
          total_num_rows: '',
          total_num_rows_color: ''
        });

        const checkedData = checkedDeducteeData;

        new_deducteeList.map((deductee) => {
          if (deductee && deductee.pan_status && deductee.pan_status.text === "PAN Active and Name Mismatched") {
            checkedData[deductee.deductee_id] = false;
          }
          return true;
        });
        setCheckedDeducteeData(checkedData);
      }
      try {
        await Promise.all([
          fetchDeductees(),
        ]);
        setError(null);
      } catch (err) {
        console.error('error: ', err);
        setError(err.message);
      } finally {
        setLoading(false);
        setShowMoreLoading(false);
      }
    }

    data.page && data.page > 1 ? setShowMoreLoading(true) : setLoading(true);
    fetchEverything();
  };

  useEffect(() => {
    const postData = getParamsHistoryUrl();
    getDeducteeLists(postData);
  }, [searchStr, deducteeTypes, deducteeTypeOldList]);


  const getParamsHistoryUrl = () => {

    const postData = {
      deductor_id: deductorId,
    };

    if (params.return_id) {
      postData.return_id = params.return_id;
    }

    if (params.sort) {
      dataState.sort = JSON.parse(atob(params.sort));
      postData.sort = JSON.parse(atob(params.sort));
    }
    if (params.filters) {
      dataState.filter = { filters: [] };
      dataState.filter.filters = JSON.parse(atob(params.filters));
      postData.filters = JSON.parse(atob(params.filters));
    }
    if (params.page) {
      postData.page = params.page;
    }
    if (params.action) {
      postData.action = params.action.split(",");
    }
    postData.limit = params.limit ?? limit;
    setDataState(dataState);
    setFilterPostData(postData);
    return postData;
  };

  const dataStateChange = (dataState) => {
    setDataState(dataState);
    createHistoryUrl(dataState);
  };

  const createHistoryUrl = (dataState = [], action = '') => {
    let filterStr = `?deductor_id=${deductorId}`;
    if (params.return_id) {
      filterStr += `&return_id=${params.return_id}`;
    }
    if (params.tab) {
      filterStr += `&tab=${params.tab}`;
    }

    if (params.action) {
      let all_status = params.action.split(",");
      let array_index = all_status.indexOf(action);
      if (array_index !== -1) {
        all_status.splice(array_index, 1);
      }
      else {
        all_status.push(action);
      }
      filterStr += all_status.length > 0 ? `&action=${all_status.join(",")}` : '';
    }
    else {
      filterStr += `&action=${action}`;
    }

    if (dataState.filter) {
      filterStr += `&filters=${btoa(JSON.stringify(dataState.filter.filters))}`;
    }
    if (dataState.sort && dataState.sort.length > 0) {
      filterStr += `&sort=${btoa(JSON.stringify(dataState.sort))}`;
    }
    history.push(filterStr);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      })
    }
    return active;
  };
  const handleDeducteePopup = (info = false) => {
    setShowCreateForm(true);
  }

  const panCell = (val) => (
    <td className='text-center'>
      {val.pan.name}
    </td>
  );

  const deducteeType = (type) => (
    <td className='text-left'>{
      deducteeTypeOldList ? type.deductee_type === "COMPANY" ? "Company" : type.deductee_type === "OTHER_THAN_COMPANY" ? "Non Company" : '' : deducteeTypeValue(type.deductee_type)
    }
    </td>
  );
  const deducteeTypeValue = (typeValue) => {
    return DEDUCTEE_TYPE_CORRECTION_RETURN.filter(v => v.value === typeValue)?.[0]?.label ?? '';
  }

  const salaryCategory = (category) => (
    <td className='text-left'>
      {category.salary_category === "G" ? "Other" : category.salary_category === "W" ? "Woman" : category.salary_category === "S" ? "Senior Citizen" : category.salary_category === "O" ? "Super Senior Citizen" : ''}

    </td>
  );

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    $('[data-toggle="tooltip"]').click(() => {
      $('[data-toggle="tooltip"]').tooltip('hide');
    });

    if (params.page) delete params.page;
    if (params.open && params.open === 'create-deductee') {
      setShowCreateForm(true);
      delete params.open;
    }
    if (params.open && params.open === 'bulk-pan-verification') {
      setShowBulkPAN(true);
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('open');
      history.replace({
        search: queryParams.toString(),
      });
    }


  }, [searchStr, deductorId]);


  const updateDeducteeInfo = (deducteeInfo, view = false) => {
    setLoading(true);
    setShowEditForm(false);
    setEditDeductee(deducteeInfo);
    setHorizontalView(view);
    setTimeout(function () { setShowEditForm(true); setLoading(false); }, 1);
  };

  useEffect(() => {
    let action = params.action ? params.action.split(",") : [];
    setStatusFilter(action);
  }, [searchStr]);


  const editInfo = (props) => (
    <td className="saldtddeleditsect text-center">
      {/* {console.log("props...",props.dataItem,props)} */}
      {' '}

      <span
        onClick={() => { setCurrentDeductee(props.dataIndex); updateDeducteeInfo(props.dataItem); }}
      >
        <img src={editIcon} alt="" width="14" />
      </span>
      {!componentImport ? (
        <span>
          <span className="border_left mx-1"></span>

          <span className="" data-toggle="modal" data-target=".bd-example-modal-lg">
            <label className="switch listofdempswitch">
              <input type="checkbox" checked={!props.dataItem.deductee_status} onChange={() => updateDeducteeStatus(props.dataItem, !props.dataItem.deductee_status)} />
              <span className="slider round listofdempswround" />
            </label>
          </span>
        </span>
      ) : null}

      {/**
        <FontAwesomeIcon icon={faPencilAlt} onClick={() => { handleSalaryPopup(props.dataItem) }} />
        <FontAwesomeIcon icon={faTrash} style={{ marginLeft: '10px' }} onClick={() => { setShowConfirm(true); setDeleteData(props.dataItem); }} />
        */}

    </td>
  )

  const updateDeducteeStatus = (deducteeInfo, deducteeStatus) => {
    setError(null);
    setSuccess(null);
    const deducteeData = {
      deductee_id: deducteeInfo.deductee_id,
      deductor_id: deductorId,
      deductee_status: deducteeStatus ? 1 : 0,
    };

    if (params.return_id) {
      deducteeData.return_id = params.return_id;
    }

    deducteeInfo.deductee_status = deducteeStatus;

    const index = _.findIndex(deducteeList, { deductee_id: deducteeInfo.deductee_id });

    // Replace item at index using native splice
    deducteeList.splice(index, 1, deducteeInfo);
    setDeducteeList(deducteeList);

    async function callAPI() {
      async function createDeductee2() {
        const result = await updateCorrectionDeductee(deducteeData);
        return result;
      }
      try {
        const result = await createDeductee2();
        setSuccess(result.message);
        setTimeout(() => {
          setSuccess(null);
        }, 3000);
      } catch (err) {
        console.error('error: ', err);
        setError(err.toString());
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    callAPI();
  };

  const toggleAllSelect = (checkedD = {}) => {
    const unMatchedSelect = { ...checkedDeducteeData, ...checkedD };

    _.map(unMatchedSelect, (value, key) => {
      unMatchedSelect[key] = !allCheckData;
    });

    if (!allCheckData) {
      setSelectedDeducteeCount(Object.keys(unMatchedSelect).length);
    } else {
      setSelectedDeducteeCount(0);
    }

    setCheckedDeducteeData(unMatchedSelect);
    setAllCheckData(!allCheckData);
  };

  const handleDeducteeToggle = (event) => {
    let key = event.dataItem.deductee_id;
    const newValue = !checkedDeducteeData[key];
    setCheckedDeducteeData({ ...checkedDeducteeData, [key]: newValue });
    let allMatched = true;

    _.map(checkedDeducteeData, (value, key1) => {
      if (key1 !== key && value !== newValue) {
        allMatched = false;
      }
    });

    if (newValue) {
      setSelectedDeducteeCount(selectedDeducteeCount + 1);
    } else {
      setSelectedDeducteeCount(selectedDeducteeCount - 1);
    }

    if (allMatched && newValue) {
      setAllCheckData(true);
    } else {
      setAllCheckData(false);
    }
  };

  const onClose = (reload, msg = '') => {
    setShowCreateForm(false);
    setShowEditForm(false);
    if (msg) {
      setSuccess(msg);
    }

    if (reload) {
      setTimeout(() => {
        history.go();
      }, 300);
    }
  };

  const ShowBulkPANPopup = () => {

    setShowBulkPAN(true);
  };

  const StatusCell = (dataItem) => {
    return (
      <td className="text-center">
        <div className="Stsonhoverboxshow">
          <span className="stserrorboxclr">
            <span className="iocnfsuppot">
              <FontAwesomeIcon icon={faCircle} style={{ color: `#${dataItem && dataItem.pan_status && dataItem.pan_status.color}` }} />
            </span>
          </span>
          <span className="Ststooltiptext">
            <ul className="Stsstsboxlist">
              <li>
                <span className="form-check-inline">
                  <label className="form-check-label stserrorboxclr" htmlFor="">
                    <span className="iocnfsuppot">
                      <FontAwesomeIcon icon={faCircle} style={{ color: `#${dataItem && dataItem.pan_status && dataItem.pan_status.color}` }} />
                    </span>
                    <span className="ststextfs" style={{ color: `#${dataItem && dataItem.pan_status && dataItem.pan_status.color}` }}>{dataItem && dataItem.pan_status && dataItem.pan_status.text}</span>
                  </label>
                </span>
              </li>
            </ul>
          </span>
        </div>
      </td>
    );
  };

  const rowRender = (trElement, props) => {
    const deductee_status = !props.dataItem.deductee_status;
    const action = props.dataItem.action;

    const disabled = {
      opacity: "0.5",
    };
    const green = {
      color: "#08C802",
    };
    const blue = {
      color: "#0000ff",
    };
    const black = {
      color: "#00000",
    };
    if (componentImport) {
      const trProps = {
        style: action === 'ADDED' ? green : action === 'UPDATED' ? blue : black,
      };
      return React.cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children
      );
    } else {
      const trProps = {
        style: deductee_status ? disabled : null,
      };
      return React.cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children
      );
    }
  };

  if (reloadPanList) {
    window.location.reload();
  }

  return (
    <div>
      {loading ? <LoadingIndicator /> : null}
      {showCreateForm
        ? (
          <AddDeductee
            onClose={onClose}
            deductorId={deductorId}
            componentImport={componentImport}
          />
        )
        : null}
      {showEditForm
        ? (
          <EditDeductee
            onClose={onClose}
            deductorId={deductorId}
            deducteeInfo={editDeductee}
            componentImport={componentImport}
            updateDeducteeInfo={updateDeducteeInfo}
            deducteeList={deducteeList}
            currentDeductee={currentDeductee}
            setCurrentDeductee={setCurrentDeductee}
            horizontalView={horizontalView}
          />
        )
        : null}
      <ShowAlert
        error={error}
        success={success}
        autoHideTime={7000}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {showBulkPAN
        ? <ConnectorAdapter
          show={showBulkPAN}
          handleClose={() => setShowBulkPAN(false)}
          setPort={setPort}
          handleProceed={() => setCheckTracesCredentials(true)}
        />
        : null
      }

      {checkTracesCredentials && port
        ? <TracesConnector
          deductorId={deductorId}
          returnId={params.return_id}
          handleProceed={() => {
            setShowBulkPAN(false);
            setCheckTracesCredentials(false);
          }}
          port={port}
          setCaptchaRef={setCaptchaRef}
          setVerifyCreds={() => null}
          creds={null}
        />
        : null
      }

      {captchaRef && port
        ? <BulkPanVerify
          deductorId={deductorId}
          returnId={params.return_id}
          port={port}
          captchaRef={captchaRef}
          setError={setError}
          setSuccess={setSuccess}
          statusKey={statusKey}
          setBulkPanVerificationStatusData={setBulkPanVerificationStatusData}
        />
        : null
      }


      <div className="container-fluid w-1300 mt-4">
        <div className="row">
          <div className="col-sm-8">
            <span className="text-left">
              <Button className="f-12" style={props.componentImport ? { visibility: 'hidden' } : { color: '#FC7D72' }} variant="link" onClick={() => history.push(`${BASE_PATH}deductor?deductor_id=${deductorId}&financial_year=${finanicalYear}`)}>
                {'< '}
                Back to Deductor Dashboard
              </Button>
            </span>
            <span className="SSS listofdeititle">
              List Of Deductees/Employees
            </span>

          </div>
          {componentImport ? (
            <div className="col-md-4 text-right pt-1">
              <div className="form-check-inline">
                <label className="form-check-label" for="check1" onClick={() => { createHistoryUrl(dataState, 'ADDED') }}>
                  <input type="checkbox" className="form-check-input" checked={statusFilter.includes('ADDED') ? true : false} style={{ verticalAlign: 'sub' }} /> New Added
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label" for="check1" onClick={() => { createHistoryUrl(dataState, 'UPDATED') }}>
                  <input type="checkbox" className="form-check-input" checked={statusFilter.includes('UPDATED') ? true : false} style={{ verticalAlign: 'sub' }} /> Updated
                </label>
              </div>
            </div>
          ) : null}

        </div>
      </div>

      <div className="container-fluid w-1300 mt-2">
        <div className="mt-2">
          <div className="card padding_top_manage">
            <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
              <Tooltip anchorElement="pointer" position="right">
                <Grid
                  className="table table-striped listofsalarydetailstb pb-0"
                  style={componentImport ? { height: '300px', overflowX: 'auto' } : { height: '400px', overflowX: 'auto' }}
                  rowRender={rowRender}

                  data={deducteeList.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: checkedDeducteeData[idGetter(item)],
                  }))}
                  {...dataState}
                  onDataStateChange={(event) => dataStateChange(event.dataState)}
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                  }}
                  onSelectionChange={handleDeducteeToggle}
                  onHeaderSelectionChange={() => toggleAllSelect()}
                  filterOperators={{
                    text: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                    ],
                    numeric: [
                      { text: 'grid.filterGteOperator', operator: 'gte' },
                      { text: 'grid.filterLteOperator', operator: 'lte' },
                      { text: 'grid.filterLtOperator', operator: 'lt' },
                      { text: 'grid.filterGtOperator', operator: 'gt' },
                      { text: 'grid.filterEqOperator', operator: 'eq' },
                      { text: 'grid.filterNotEqOperator', operator: 'neq' },
                    ],
                    textWithEmpty: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                      { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                    ],
                    dropdown: [
                      { text: 'grid.filterContainsOperator', operator: 'in' },
                    ],
                    pan: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                      { text: 'grid.filterContainsOperator', operator: 'in' },
                    ],
                  }}
                  sortable={{
                    allowUnsort: true,
                  }}
                >
                  <Column
                    style={{ zIndex: '55 !important', top: '5px!important' }}
                    field="pan_info_status"
                    width="70"
                    title={
                      <div className="stsicon onhoverboxshow">
                        <span>
                          <FontAwesomeIcon icon={faExclamationCircle} /></span>
                        <span className="tooltiptext">
                          <ul className="stsboxlist">
                            <li>Status:</li>
                            {ACTIVITY_STATUSES.map((status) => {
                              return (
                                <li>
                                  <span className="form-check-inline">
                                    <label className="form-check-label stsboxclrtext">
                                      <span className={status.class}><FontAwesomeIcon icon={status.icon} color={status.color} /></span>
                                      <span className="ststextfs" style={{ color: status.color }}>{status.label}</span>
                                    </label>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </span>
                      </div>
                    }
                    cell={(event) => StatusCell(event.dataItem)}
                    headerClassName={isColumnActive('pan_info_status') ? 'thdisplaycontent active' : 'thdisplaycontent'}
                    filter="dropdown"
                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={STATUS_LIST} />}
                    sortable={false}
                  />
                  <Column
                    width="250"
                    field="deductee_name"
                    headerClassName={isColumnActive('deductee_name') ? 'active' : ''}
                    title={
                      <AddDeducteeTransactionsIcon field="deductee_name" dataState={dataState}
                        dataStateChange={dataStateChange}
                        handleClick={() => handleDeducteePopup()}
                        title="Deductee Name"
                        subtextData={{ subText: deducteeSummary.total_num_rows, color: deducteeSummary.total_num_rows_color }}
                        headClass={true}
                        addTooltip="Click to add new deductee"
                      />
                    }
                    cell={(event) => (
                      <td title={capitalizeEachWordFirstLetter(event.dataItem.deductee_name)} className="overflowtooltiplistofDE text-left " style={{ color: event.dataItem.action === 'ADDED' ? "#08C802" : event.dataItem.action === 'UPDATED' ? '#0000ff' : '#000000' }}>
                        {' '}
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title={capitalizeEachWordFirstLetter(event.dataItem.deductee_name)}   >
                          {capitalizeEachWordFirstLetter(event.dataItem.deductee_name)}
                        </span>
                        {(event.dataItem && event.dataItem.pan_status && event.dataItem.pan_status.text === "PAN Active and Name Mismatched") ? (
                          <div style={{ color: `#${event.dataItem.name_on_pan.color}`, display: 'block', textAlign: 'right' }}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={event.dataItem.name_on_pan.name}>
                            {event.dataItem.name_on_pan.name}
                          </div>
                        ) : null}
                      </td>
                    )}
                    columnMenu={(props) => <ColumnMenu hideSecondFilter {...props} />}
                    sortable={false}
                  />
                  <Column
                    field="pan"
                    headerClassName={isColumnActive('pan') ? 'active' : ''}
                    title="PAN"
                    width="260"
                    cell={(event) => panCell(event.dataItem)}
                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter panFilter={true} filterList={PAN_LIST} />}
                    filter="pan"
                  />
                  <Column
                    field="category "
                    headerClassName={isColumnActive('category ') ? 'active' : ''}
                    title={"Category"}
                    filter="dropdown"
                    width="250"
                    cell={(event) => salaryCategory(event.dataItem)}
                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={CATEGOTY_LIST} />}
                  />
                  <Column
                    field="deductee_type"
                    headerClassName={isColumnActive('deductee_type') ? 'active' : ''}
                    title={"Deductee Type"}
                    filter="dropdown"
                    width="250"
                    cell={(event) => deducteeType(event.dataItem)}
                    columnMenu={(props) => <ColumnMenu {...props} hideSecondFilter filterList={deducteeTypes} />}
                  />
                  <Column
                    field="editIcon" sortable={false}
                    title="Actions"
                    cell={editInfo}
                    width="130"
                  />
                </Grid>
              </Tooltip>

              {
                bulkPanVerificationStatusData?.captcha_ref
                  ? <ProgressBarForPAN
                    bulkPanVerificationStatusData={bulkPanVerificationStatusData}
                    statusKey={statusKey}
                    setPort={setPort}
                    setBulkPanVerification={setCheckTracesCredentials}
                    setReloadPanList={setReloadPanList}
                    setDisableBulkPanVerificationButton={setDisableBulkPanVerificationButton}
                  />
                  : null
              }

              <div className="row">
                <div className="col-sm-3 pb-2">
                </div>
                <div className="col-sm-6 pb-1">
                  <ShowMorePagination
                    cursor={cursor}
                    fetchData={getDeducteeLists}
                    postData={filterPostData}
                    loading={showMoreLoading}
                  />
                </div>
                <div className="col-sm-3 pb-2">
                  <button
                    type="button"
                    className="dark_color_bg rounded_btn float-right smpaddbulkpv mr-3"
                    onClick={() => ShowBulkPANPopup()}
                    disabled={disableBulkPanVerificationButton}
                    style={disableBulkPanVerificationButton === true ? { opacity: '0.5' } : { opacity: '1' }}
                  >
                    Bulk PAN verification
                  </button>
                </div>
              </div>
              <div className="row ml-3 pb-1">
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <span style={{ color: '#333' }}><FontAwesomeIcon icon={faSquare} /></span>
                    <span className="pl-1">All </span>
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <span style={{ color: '#08C802' }}><FontAwesomeIcon icon={faSquare} /></span>
                    <span className="pl-1">New Added</span>
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <span style={{ color: '#0000ff' }}><FontAwesomeIcon icon={faSquare} /></span>
                    <span className="pl-1">Updated</span>
                  </label>
                </div>
              </div>

              <div className="form-check-inline" style={{ display: 'inline-block', width: '100%', textAlign: 'right' }}>
                <label className="form-check-label">
                  <span className="pl-1 text-warning pr-3">{consoStatus && consoStatus.conso_file_status === 'IMPORT_IN_PROGRESS' ? 'IMPORT IN PROGRESS PLEASE WAIT....' : null}</span>
                </label>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeducteeCorrectionList;