import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import Indiaflagexp from '../../images/loginicon/India-flag-exp.png';
import addBtnRoundIcon from '../../images/plusW.png';
import usersetIcon from '../../images/userset.png';
import editIcon from '../../images/icons/edit.svg';
import mail from "../../images/email_icon.png"
import checkIcon from "../../images/checkicon.png"
import closeRedIcon from "../../images/close_red.png"
import { getUsersOfOrganization, updateUserProfile, sendOtpToVerifyUserMobileEmail, updateUserVerifiedMobileEmail } from '../../api/profileAPI';
import { verifyOTP } from '../../api/authenticationAPI';
import ShowAlert from '../../components/ShowAlert';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import { BASE_PATH } from '../../app/constants';
import InviteUser from './InviteUser';
import UserSettings from './UserSettings';
import OtpVerifiyPopup from '../../components/profile/OtpVerifiyPopup';
import mobile from "../../images/loginicon/mobile.svg";
import UnVerifyEmailPopup from './UnVerifyEmailPopup';
import './UserList.css';
import "../../components/profile/Profile.css"
import "./UserManagement.scss"
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const UserList = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [userList, setUserList] = useState([]);
  const [headerInfo, setHeaderInfo] = useState({})
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [otpVerifiyPopup, setOtpVerifiyPopup] = useState(false);
  const [unVerifiedEmailPopup, setUnVerifiedEmailPopup] = useState(false);
  const userStatus = { "1": "Active", "0": "In-active", "2": "Invitation Pending" }
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showNewDeductorSetting, setShowNewDeductorSetting] = useState(false);


  const getUsers = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getUsersOfOrganization();
      setUserList(result.data);
      setHeaderInfo(result.header ?? {})
      if (result.header.user_role.toUpperCase() !== 'OWNER') {
        history.push(BASE_PATH);
      }

      result?.data.forEach((item) => {
        if (item?.user_role === "OWNER") {
          setShowNewDeductorSetting(item.show_new_deductor ?? false)
        }
      })

    } catch (err) {
      // console.error('Error in getting organization users: ', err);
    }
    setLoading(false);
  }, [history]);

  const invitedUserStatusUpdate = (e, user) => {
    if (!user.user_organization_status) {
      let data = {
        user_id: user?.user_id,
        country_code: user.country_code,
        mobile_no: user.mobile_no
      }
      updateMobileEmail(data)
    } else {
      toggleUserStatus(e, user)
    }
  }

  const toggleUserStatus = async (e, user) => {
    e && e.preventDefault();
    setLoading(true);
    const data = {
      user_id: user.user_id,
    };
    if (!user.user_organization_status) {
      data["otp_verification_id"] = user.otp_verification_id;
    }
    data["user_organization_status"] = !user.user_organization_status;
    try {
      const result = await updateUserProfile(data);
      setSuccess(result.message);
      setTimeout(() => getUsers(), 500);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }

  };

  const updateMobileEmail = async (data) => {
    setLoading(true);
    try {
      const result = await sendOtpToVerifyUserMobileEmail(data);
      if (data.email) {
        let otpData = {
          Title: "Email Address",
          userId: data?.user_id,
          user: data?.email,
          country_code: data?.country_code,
          icon: mail,
          id: result?.data?.otp_verification_id ?? "",
          key: "email",
          verify: true
        }
        setOtpVerifiyPopup(otpData)
      } else if (data.mobile_no) {
        let otpData = {
          Title: "Mobile Number",
          userId: data?.user_id,
          user: data?.mobile_no ?? "",
          country_code: data?.country_code ?? "",
          icon: mobile,
          id: result?.data?.otp_verification_id ?? "",
          key: "mobile_no",
          verify: "activate_user"
        }
        setOtpVerifiyPopup(otpData)
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const OtpVerification = async (data, verify) => {

    setLoading(true);
    try {
      const otpResult = await verifyOTP(data);

      setSuccess(otpResult.message);
      setOtpVerifiyPopup(false);
      if (verify === "activate_user") {
        //let user = userList.filter((item) => Number(item.mobile_no) === Number(data.mobile_no_or_email))
        let user = userList.filter((item) => item?.user_id === data?.user_id)
        let payload = {
          user_id: user?.[0]?.user_id ?? "",
          user_organization_status: user?.[0]?.user_organization_status ?? "",
          otp_verification_id: otpResult?.data?.otp_verification_id ?? ""

        }

        toggleUserStatus("", payload)

      } else {
        setTimeout(() => {
          updateEmailOtpVerification(data);
        }, 1000)
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const updateEmailOtpVerification = async (data) => {
    setLoading(true)
    let apiData = {}
    apiData["country_code"] = data?.country_code ?? "";
    apiData["otp_verification_id"] = data?.otp_verification_id ?? "";
    apiData["otp"] = data?.otp ?? ""
    if (isNaN(Number(data.mobile_no_or_email))) apiData["email"] = data.mobile_no_or_email ?? ""
    else {
      apiData["mobile_no"] = data?.mobile_no_or_email ?? ""
    }
    try {
      await updateUserVerifiedMobileEmail(apiData);
      setTimeout(() => {
        setShowInviteUser(true);
      }, 250)
    } catch (err) {
      // setError(err.message);
    } finally {
      setLoading(false)
    }
  }

  const userProfileVerify = () => {
    const userEmail = headerInfo?.email;
    if (userList?.length > 0) {
      userList.forEach((item) => {
        if (item.email === userEmail) {

          if (item?.email_verified) {
            setShowInviteUser(true);
          } else if (!item?.email_verified) {
            let data = {
              country_code: item.country_code,
              email: item.email
            }
            setUnVerifiedEmailPopup(data);

          }
        }
      })
    }
  }

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const rowRender = (trElement, props) => {

    const key = trElement._owner.index;
    const deactiveCss = props?.dataItem?.user_organization_status !== 1 ? "deactive-text" : "";

    return (
      <tr key={key}>
        <td className={`text-left ${deactiveCss}`}>{props?.dataItem?.full_name}</td>

        <td className={`text-right ${deactiveCss}`}>
          {props?.dataItem?.mobile_no_verified ?
            <img src={checkIcon} width="18" class="cursorpointer" alt="" title='Mobile Verified' />
            :
            <img src={closeRedIcon} width="14" class="cursorpointer" alt="" title='Mobile Not Verified' />
          }
        </td>

        <td className={`text-left ${deactiveCss}`}>
          {/* <span style={{ verticalAlign: 'revert' }}>
            <img className="flag_icon" src={Indiaflagexp} alt="mobile" />
          </span> */}
          {props?.dataItem?.mobile_no}
        </td>

        <td className={`text-center ${deactiveCss}`}>
          {props?.dataItem?.email_verified ?
            <img src={checkIcon} width="18" class="cursorpointer" alt="" title='Email Verified' />
            :
            <img src={closeRedIcon} width="14" class="cursorpointer" alt="" title='Email Not Verified' />

          }
        </td>
        <td className={`text-left ${deactiveCss}`}>{props?.dataItem?.email}</td>

        <td className={`text-center ${deactiveCss}`}>{props?.dataItem?.user_role}</td>

        <td className={`text-center ${deactiveCss}`}>
          {(props?.dataItem?.user_role.toUpperCase() !== 'OWNER' && [0, 1].includes(props.dataItem.user_organization_status)) ?
            <Link to="#" onClick={(e) => invitedUserStatusUpdate(e, props?.dataItem)}
              title='Update Status'
              style={{ textDecoration: 'underline', color: "#31719B" }}>
              {userStatus[props?.dataItem?.user_organization_status]}
            </Link>
            :
            userStatus[props?.dataItem?.user_organization_status]
          }

          {/* <input type="checkbox" style={{cursor: "pointer"}} 
        checked={user.user_organization_status} onChange={(e) => invitedUserStatusUpdate(e, user)} /> */}

        </td>
        <td className={`text-center ${deactiveCss}`}>{props?.dataItem?.assigned_deductors}</td>
        <td className="text-center">
          {props?.dataItem?.user_role !== "OWNER" ? (
            props?.dataItem?.assigned_deductors === 0 ? (
              props?.dataItem?.user_organization_status === 1 ? (
                <Link to={`${BASE_PATH}user-management/manage-clients?tab=3&user_id=${props?.dataItem.user_id}`}>
                  <span className="dark_color_text">Assign Clients</span>
                </Link>
              ) : (
                <span className="deactive-text">Assign Clients</span>
              )
            ) : (
              props?.dataItem?.user_organization_status === 1 ? (
                <Link to={`${BASE_PATH}user-management/manage-clients?tab=1&user_id=${props?.dataItem.user_id}`}>
                  <span className="dark_color_text">Manage</span>
                </Link>
              ) : (
                <span className="deactive-text">Manage</span>
              )
            )
          ) : (
            <span className="deactive-text">
              <FontAwesomeIcon icon={faCog} color="#31719b" cursor="pointer" title='Owner Authorization' onClick={() => { setShowSettingsPopup(true) }} />
            </span>
          )}
        </td>
        <td className="text-center">
          {props?.dataItem?.user_role === "OWNER" ? (
            <Link to={`${BASE_PATH}profile`}>
              <span>
                <img
                  src={editIcon}
                  alt="Edit User Profile"
                  width="14"
                  title="Edit User Profile"
                />
              </span>
            </Link>
          ) : props?.dataItem?.user_id ? (
            <Link to={`${BASE_PATH}user-profile?user_id=${props?.dataItem?.user_id}`}>
              <span>
                <img
                  src={editIcon}
                  alt="Edit User Profile"
                  width="14"
                  title="Edit User Profile"
                />
              </span>
            </Link>
          ) : (
            <span>
              <img
                src={editIcon}
                alt="Edit User Profile"
                width="14"
                title="Edit User Profile"
              />
            </span>
          )}
        </td>
      </tr>
    )
  };



  return (
    <>
      {loading ? <LoadingIndicator /> : null}
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      {showInviteUser && (
        <InviteUser show={showInviteUser} handleClose={() => setShowInviteUser(false)} />
      )}

      {showSettingsPopup && (
        <UserSettings show={showSettingsPopup} showNewDeductorSetting={showNewDeductorSetting}
          handleSuccess={() => {
            setShowSettingsPopup(false);
            setSuccess("Authorization updated successfully");
            setTimeout(() => getUsers(), 500);
          }}
          handleClose={() => {
            setShowSettingsPopup(false);
          }} />
      )}

      {unVerifiedEmailPopup && <UnVerifyEmailPopup
        unVerifiedEmailPopup={unVerifiedEmailPopup}
        handleClose={() => {
          setUnVerifiedEmailPopup(false);
        }}
        verifiedEmail={(data) => {
          setUnVerifiedEmailPopup(false);
          setTimeout(() => updateMobileEmail(data), 300);
        }}
      />}

      {otpVerifiyPopup && <OtpVerifiyPopup
        data={otpVerifiyPopup}
        handleClose={() => {
          setOtpVerifiyPopup(false);
        }}
        verifiyApi={OtpVerification}
        resendOtP={updateMobileEmail}
        error={error}
        setError={setError}
      />}
      <div className="container mt-2">

        <div className="row ">
          <div className="col-xl-5 col-lg-5 col-md-4 col-md-12 col-12">

            <Link className="backtoclr" to={BASE_PATH}>
              {'< '}
              Back to Deductor List
            </Link>

          </div>
          <div className="col-xl-3 col-lg-3 col-md-8 col-md-12 col-12">
            <h4 className="usermanagement_manageassing_title">
              User Management
            </h4>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-md-12 col-12 text-right">
            <div className="d-flex justify-content-end">
              <nav className="usermanagement_nav">
                <div className="nav nav-tabs usermanagement_tabs" id="nav-tab" role="tablist" >
                  <a className="nav-item nav-link usermanagement_tabbox usermanagement_tabbox_leftborder_round  active "
                    data-toggle="tab" href="#tab_01"
                    role="tab"
                    title='Invite User'
                    aria-controls="nav-home"
                    onClick={() => userProfileVerify()}
                    aria-selected="false">
                    <span className="pr-1">
                      <img src={addBtnRoundIcon} width="16" alt="" />
                    </span>
                    Invite User
                  </a>
                  <Link className="backtoclr nav-item nav-link usermanagement_tabbox usermanagement_borderleft"
                    title='Client User Mapping'
                    to={`${BASE_PATH}user-management/client-user-map`}>
                    <span className="pr-1">
                      <img src={usersetIcon} width="16" alt="" />
                    </span>
                    Client User Map
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-12">
            <div className='card pending-request-column' style={{ padding: '0px' }}>


              <div className="table-responsive table-responsive-xs table-responsive-sm table-responsive-md">
                <Grid
                  className="table table-striped usermanagetb"
                  data={userList}
                  filterOperators={{
                    text: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                    ],
                    numeric: [
                      { text: 'grid.filterGteOperator', operator: 'gte' },
                      { text: 'grid.filterLteOperator', operator: 'lte' },
                      { text: 'grid.filterLtOperator', operator: 'lt' },
                      { text: 'grid.filterGtOperator', operator: 'gt' },
                      { text: 'grid.filterEqOperator', operator: 'eq' },
                      { text: 'grid.filterNotEqOperator', operator: 'neq' },
                    ],
                    textWithEmpty: [
                      { text: 'grid.filterContainsOperator', operator: 'contains' },
                      { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
                    ]
                  }}
                  rowRender={rowRender}
                  sortable
                >
                  <Column
                    width="170"
                    field="full_name"
                    title="User Name"
                  />
                  <Column
                    width="23"
                    field=""
                    title=""
                  />
                  <Column
                    width="100"
                    field="mobile_no"
                    title="Mobile No."
                  />
                  <Column
                    width="23"
                    field=""
                    title=""
                  />
                  <Column
                    width="220"
                    field="email"
                    title="Email Address"
                  />

                  <Column
                    width="80"
                    field="user_role"
                    title="Role"
                  />

                  <Column
                    width="120"
                    field="user_organization_status"
                    title="Status"
                  />

                  <Column
                    width="120"
                    field="assigned_deductors"
                    title="Assigned Deductor"
                  />

                  <Column
                    width="100"
                    field=""
                    title="Action"
                  />

                  <Column
                    width="40"
                    field=""
                    title=""
                  />


                </Grid>
              </div>




            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default UserList;
