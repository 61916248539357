import axios from 'axios';
import { BASE_URL_GO } from '../app/constants';
import {
  handleSuccess, handleError, handleErrorWithCode, handleAllErrorWithCode, getFirstError,
} from './responseHandler';
import { getConfig } from './common';


export async function addReturn(data) {
  const url = `${BASE_URL_GO}/api/v1/returns`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function checkConso(getParams) {
  const url = `${BASE_URL_GO}/api/v1/correction/check-conso-available${getParams}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getCorrectionTransactionOverview(queryParam) {
  const url = `${BASE_URL_GO}/api/v1/correction/summary/transactions-summary${queryParam}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getReturnCorrectionSummary(queryParam) {
  const url = `${BASE_URL_GO}/api/v1/correction/summary/correction-summary${queryParam}`;


  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getCorrectionNatureOfPaymentSummary(queryParam) {
  const url = `${BASE_URL_GO}/api/v1/correction/summary/payment-summary${queryParam}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getCorrectionTopDeductees(queryParam) {
  const url = `${BASE_URL_GO}/api/v1/correction/summary/top-deductees${queryParam}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getCorrectionChallanUtilizationBreakup(queryParam) {
  const url = `${BASE_URL_GO}/api/v1/correction/summary/challan-utilization-breakup${queryParam}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function importReturnData(data) {
  const url = `${BASE_URL_GO}/api/v1/return/regular/import`;
  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleAllErrorWithCode(err);
  }
}

export async function importRaiseRequestData(data) {
  const url = `${BASE_URL_GO}/api/v1/raise-request/import-file-pre-data`;

  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getDownloadConsoForRaiseRequest(getParams) {
  const url = `${BASE_URL_GO}/api/v1/raise-request/download${getParams}`;
  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    return result;

  } catch (err) {
    throw handleError(err);
  }
}

export async function getRaiseReturnsRequestV2(data) {
  const url = `${BASE_URL_GO}/api/v2/raise-request/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function raiseRequestViaConnector(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/traces/raise-request`;

  try {
    const raiseRequestData = await axios.post(url, data, getConfig());
    if (Number(raiseRequestData.data.status) !== 1) {
      throw (raiseRequestData.data.message);
    }
    return raiseRequestData;
  } catch (err) {
    throw handleError(err);
  }
}


export async function uploadManualConsoFile(data) {
  let  url = `${BASE_URL_GO}/api/v1/return/correction/import-conso`;

  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }

}
export async function getPreRaiseRequestData(getParams) {
  const url = `${BASE_URL_GO}/api/v1/raise-request/pre-data${getParams}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function importRegularReturn(data) {
  const url = `${BASE_URL_GO}/api/v1/return/regular/import`;

  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleAllErrorWithCode(err);
  }
}
export async function importCorrectionReturn(data) {
  const url = `${BASE_URL_GO}/api/v1/return/correction/import`;

  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleAllErrorWithCode(err);
  }
}


export async function exportRegularReturn(params) {
  const url = `${BASE_URL_GO}/api/v1/return/regular/export${params}`;
  try {
    const config = { ...getConfig() };
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function exportCorretionReturn(params) {
  const url = `${BASE_URL_GO}/api/v1/return/correction/export${params}`;
  try {
    const config = { ...getConfig() };
    const result = await axios.get(url, config);
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function downloadRegularReturnErrorFile(params) {
  const url = `${BASE_URL_GO}/api/v1/return/regular/download-error-file${params}`;

  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    // console.log("res",result);
    return result;
  } catch (err) {
    let errorString = ""
    if (
      err.request.responseType === 'blob' &&
      err.response.data instanceof Blob &&
      err.response.data.type &&
      err.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      errorString = getFirstError(JSON.parse(await err.response.data.text()));
    } else {
      errorString = handleError(err);
    }
    throw errorString;

  }
}


export async function getImportReturnStatus(deductorId, returnId) {
  const url = `${BASE_URL_GO}/api/v1/return/regular/import-return-status?deductor_id=${deductorId}&return_id=${returnId}`;
  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return handleSuccess(deductorData.data);
  } catch (err) {
    throw handleError(err);
  }
}


export async function getOverValuesChallan(deductorId, returnId) {
  const url = `${BASE_URL_GO}/api/v1/challan/get-overutilized-challans?deductor_id=${deductorId}&return_id=${returnId}`;
  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return handleSuccess(deductorData.data);
  } catch (err) {
    throw handleError(err);
  }
}

export async function importSalaryAnexureLiteData(data) {
  const url = `${BASE_URL_GO}/api/v2/salary/import-lite`;
  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleAllErrorWithCode(err);
  }
};

export async function importSalaryAnexureDetailedData(data) {
  const url = `${BASE_URL_GO}/api/v2/salary/import-detailed`;
  try {
    const config = { ...getConfig(), 'Content-Type': 'multipart/form-data' };
    const result = await axios.post(url, data, config);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return result.data;
  } catch (err) {
    throw handleAllErrorWithCode(err);
  }
};

export async function downloadRegularReturnErrorFileV2(params) {
  const url = `${BASE_URL_GO}/api/v2/salary/download-error-file${params}`;

  try {
    const config = { ...getConfig() };
    config.responseType = 'blob';
    const result = await axios.get(url, config);
    // console.log("res",result);
    return result;
  } catch (err) {
    let errorString = ""
    if (
      err.request.responseType === 'blob' &&
      err.response.data instanceof Blob &&
      err.response.data.type &&
      err.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      errorString = getFirstError(JSON.parse(await err.response.data.text()));
    } else {
      errorString = handleError(err);
    }
    throw errorString;

  }
}

export async function getImportReturnStatusV2(deductorId, returnId) {
  const url = `${BASE_URL_GO}/api/v2/salary/import-status?deductor_id=${deductorId}&return_id=${returnId}`;
  try {
    const deductorData = await axios.get(url, getConfig());
    if (Number(deductorData.data.status) !== 1) {
      throw (deductorData.data.message);
    }
    return handleSuccess(deductorData.data);
  } catch (err) {
    throw handleError(err);
  }
}