/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "./UserManagement.scss"
import ShowAlert from '../../components/ShowAlert';
import { gocallApi } from '../../api/issueCertificateAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';

const UserSettings = (props) => {
  const { show, handleClose, showNewDeductorSetting, handleSuccess } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);


  const saveUserSettings = async (checked, userType) => {
    setLoading(true);


    const request = {
      "respective": checked && userType === "respective" ? 1 : 0,
    }

    try {
      let url = `/api/v1/user/deductor/set-respective`
      const result = await gocallApi("post", url, request)
      if (result.data) {
        handleSuccess();
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }


  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="usr-auth-settings"
      size="sm"
      enforceFocus={false} // Disable focus trapping

    >
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      {loading && <LoadingIndicator />}
      <Modal.Header closeButton className="headerinvite">
        <Modal.Title className=" text-center addpusecent justify-content-center">Authorization</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs text-center" >
          <table class="table table-striped usermanagement_table mb-0">
            <thead>
              <tr>
                <th width="200">
                  Access Controls
                </th>
                <th width="150">
                  Respective User
                </th>

                <th width="150">
                  All Users
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="inactive_dark_color">
                    New Deductor Creation Assignment
                  </span>
                </td>
                <td>
                  <input
                    type="radio"
                    checked={showNewDeductorSetting}
                    onChange={(e) => saveUserSettings(e.target.checked, 'respective')}
                    value={true}
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    checked={!showNewDeductorSetting ? true : false}
                    onChange={(e) => saveUserSettings(e.target.checked, 'all')}
                    value={false}
                  />
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div className="row mb-5"></div>
      </Modal.Body>

    </Modal>
  );
};

export default UserSettings;
