const {
  DOMAIN_COBRAND_MAPPING,

  API_BASE_URL,
  SITE_BASE_PATH,
  SITE_LOGO,

  CFG_DEFAULT_UTM_SOURCE = "",

  CFG_GLOBAL_DV_CODE = "",

  IN_MAINTENANCE_MODE = false,
  MAINTENANCE_MESSAGE = "",

  CFG_CONNECTOR_VERSION_URL = "https://uti.kdkupdate.com/express-connector-2/update.json",
  CFG_CONNECTOR_FAQ_LINK = "",

  CFG_NETBANKING_BANKS = [],
  CFG_DEBIT_CARD_BANKS = [],
  CFG_MODE_OF_PAYMENT = [],
  CFG_PAYMENT_GATEWAY_BANKS = [],
  CFG_MAJOR_HEAD = [],
  CFG_PAY_AT_COUNTER_BANKS = [],
  RAZORPAY_KEY = "",
  API_BASE_URL_GO,
  CFG_MAX_IMPORT_FILE_SIZE_MB = 5, // Size in MB
  CFG_BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY = "bulk-client-update-return-page-",
  CFG_PASSWORD_ENCRYPTION_KEY = "",
  CFG_PASSWORD_ENCRYPTION_IV = "",
  CFG_SALARY_HEADER_ROW_COLUMNS = {},
  CFG_SALARY_HEADER_ROW_ALLOWED_COLUMNS_COUNT = 3,
  CFG_MAX_SALARY_ROW_CHECK = {},
  CFG_MAX_SALARY_TDS_CAL_TEMPLATE_COUNT = 5,
  CFG_CONNECTOR_LOCAL_PORTS = [],
} = require("../config");

export const DEDUCTOR_RECORD_IN_ONE_PAGE = 17;

export const RAZORPAY_PAYMENT_KEY = RAZORPAY_KEY;

export const MAX_IMPORT_FILE_SIZE_MB = CFG_MAX_IMPORT_FILE_SIZE_MB;

export const LIST_OF_BANKS = CFG_NETBANKING_BANKS;
export const PAYMENT_MODE = CFG_MODE_OF_PAYMENT;
export const PAYMENT_LIST = CFG_PAYMENT_GATEWAY_BANKS;
export const BANKS_LIST = CFG_DEBIT_CARD_BANKS;
export const MAJOR_HEAD_LIST = CFG_MAJOR_HEAD;
export const PAY_AT_COUNTER = CFG_PAY_AT_COUNTER_BANKS;

const current_sub_domain = window.location.host.split(".")[1]
  ? window.location.host.split(".")[0]
  : false;
const current_domain = window.location.hostname.split(".").slice(-2).join(".");

const cobrand_mapping_touse =
  DOMAIN_COBRAND_MAPPING.find((detail, index) => {
    if (current_sub_domain) {
      return (
        detail.domain === current_domain &&
        detail.sub_domain === current_sub_domain
      );
    }
    return detail.domain === current_domain && detail.sub_domain === "";
  }) ?? {};

export const BASE_URL = cobrand_mapping_touse.api_base_url ?? API_BASE_URL;
export const BASE_URL_GO = API_BASE_URL_GO;

export const BASE_PATH = cobrand_mapping_touse.site_base_path ?? SITE_BASE_PATH;
export const LOGO = cobrand_mapping_touse.site_logo ?? SITE_LOGO;

export const IS_COBRAND = Boolean(
  Object.keys(cobrand_mapping_touse).length > 0
);
export const DEFAULT_UTM_SOURCE = CFG_DEFAULT_UTM_SOURCE ?? null;
export const COBRAND_UTM_SOURCE = cobrand_mapping_touse.utm_source ?? null;
export const SIGNUP_AVAILABLE = cobrand_mapping_touse.signup_available ?? true;

export const GLOBAL_DV_CODE = CFG_GLOBAL_DV_CODE;

export const IS_MAINTENANCE_MODE_ENABLED = IN_MAINTENANCE_MODE;
export const MAINTENANCE_MESSAGE_TO_SHOW = MAINTENANCE_MESSAGE;

// OLD Connector (V1) Related - DEPRECATED
export const HEADER_FILE_NAME = "k-file-name";
export const ADD_CLIENT_AUTO_SCAN = "add-client-auto-scan-";

export const BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY =
  CFG_BULK_CLIENT_RESYNC_RETURN_PAGE_LOCAL_STORAGE_KEY;

// NEW CONNECTOR (V2) Related
export const CONNECTOR_LOCAL_PORTS = CFG_CONNECTOR_LOCAL_PORTS;
export const HEADER_CONNECTOR_DIGEST_KEY = "k-data-dt";
export const CONNECTOR_VERSION_URL = CFG_CONNECTOR_VERSION_URL;
export const CONNECTOR_FAQ_LINK = CFG_CONNECTOR_FAQ_LINK;
export const CONNECTOR_ERRORS_MAPPING = {
  default: "Something went wrong! Please try again after some time.",
  "traces.invalid_credentials":
    "Invalid Credentials! Please enter the correct credentials of TRACES portal.",
  "traces.invalid_captcha_text": "Enter correct captcha as shown.",
  "traces.unknown_error":
    "Please ensure that your internet connection is stable and the TRACES website is accessible.",
  "traces.invalid_captcha_ref":
    "Invalid data found in the request, Please try again.",
  "traces.mismatch_tan":
    "TAN did not match with the already logged-in TRACES session.",
  "traces.session_timeout":
    "Your session has expired! Please re-login with your credentials.",
  "traces.pan.verify.failed": "PAN verification process failed",
  "traces.captcha_failure":
    "Please ensure that your internet connection is stable and the TRACES website is accessible.",
  "traces.raise.request.failed": "Raise Request process failed",
  "itefil.unknown_error":
    "Sorry! It seems the Income Tax Portal is down at the moment. Please try again after some time.",
  "itefil.invalid_password":
    "Invalid Password! Please enter the correct password of Income Tax e-Filing portal.",
  "itefil.invalid_userid":
    "Oops! It seem that your TAN is not registered at the Income Tax Efiling Portal yet.",
  "failure.invalid_process_id":
    "Oops! It seem that your Process Id is Invalid , Please Refresh the page.",
  "failure.unknown_error": "Something went wrong, Plese come after some time.",
  "itefil.inactive_userid":
    "Oops! It seem that your TAN is not activated at the Income Tax Efiling Portal yet.",
  "insights.invalid_credentials":
    "Invalid Insights Login Credentials! Please enter correct credentials.",
  "insights.pan_file_blank":
    "Oops! It seems that you uploaded an empty file. Please insert PANs first.",
  "insights.xls_file_not_supported": "Uploaded file format is not supported.",
  "insights.pan_file_not_found":
    "Sorry! File not uploaded properly. Please try again.",
  "insights.captcha_failure":
    "Invalid Captcha! Please enter correct Captcha as shown in the image.",
  "insights.invalid_pan": "Invalid PAN number, Please enter correct PAN number",
  "insights.unknown_error":
    "Sorry! It seems Insights Portal did not respond well, Please try again.",
  "insights.already_registered": "Already registered",
  "insights.new_request_status.failed":
    "Something went wrong! Please try again after some time.",
  "insights.request_status.failed":
    "Something went wrong! Please try again after some time.",
  "insights.request_status.failed_to_check":
    "Something went wrong! Please try again after some time.",
  "insights.request_status.found_with_unknown_status":
    "Something went wrong! Please try again after some time.",
  "insights.request_status.avbl_not_downloaded":
    "It may take a while to update the status of all records. Kindly Check after sometime.",
  "insights.request_status.not_avbl_for_download":
    "The requested status is not available on the government portal yet. Please check again later after a while. Thank you for your patience.",
  "insights.request_status.NOT_FOUND":
    "The request ID isn't found in the Insights Login. Check if you're using the same credentials as when the request was placed",
  "insights.request_status.R": "Your request for insights has been rejected.",
  "insights.request_status.E": "Your request for insights has been expired.",
  "insights.login_failed":
    "We are not able to verify your Insights Username and Password. This might be due to wrong credentials or some other reason. Please update these from Edit Deductor Page.",
};

export const STATES = {
  1: "ANDAMAN AND NICOBAR ISLANDS",
  2: "ANDHRA PRADESH",
  3: "ARUNACHAL PRADESH",
  4: "ASSAM",
  5: "BIHAR",
  6: "CHANDIGARH",
  7: "DADRA & NAGAR HAVELI AND DAMAN & DIU",
  // 8: "DAMAN & DIU",
  9: "DELHI",
  10: "GOA",
  11: "GUJARAT",
  12: "HARYANA",
  13: "HIMACHAL PRADESH",
  14: "JAMMU & KASHMIR",
  15: "KARNATAKA",
  16: "KERALA",
  17: "LAKSHWADEEP",
  18: "MADHYA PRADESH",
  19: "MAHARASHTRA",
  20: "MANIPUR",
  21: "MEGHALAYA",
  22: "MIZORAM",
  23: "NAGALAND",
  24: "ODISHA",
  25: "PONDICHERRY",
  26: "PUNJAB",
  27: "RAJASTHAN",
  28: "SIKKIM",
  29: "TAMILNADU",
  30: "TRIPURA",
  31: "UTTAR PRADESH",
  32: "WEST BENGAL",
  33: "CHHATISHGARH",
  34: "UTTARAKHAND",
  35: "JHARKHAND",
  36: "TELANGANA",
  37: "LADAKH",
  99: "OTHERS",
};

export const STATE_LIST = [
  { label: "ANDAMAN AND NICOBAR ISLANDS", value: 1 },
  { label: "ANDHRA PRADESH", value: 2 },
  { label: "ARUNACHAL PRADESH", value: 3 },
  { label: "ASSAM", value: 4 },
  { label: "BIHAR", value: 5 },
  { label: "CHANDIGARH", value: 6 },
  { label: "DADRA & NAGAR HAVELI AND DAMAN & DIU", value: 7 },
  // { label: "DAMAN & DIU", value: 8 },
  { label: "DELHI", value: 9 },
  { label: "GOA", value: 10 },
  { label: "GUJARAT", value: 11 },
  { label: "HARYANA", value: 12 },
  { label: "HIMACHAL PRADESH", value: 13 },
  { label: "JAMMU & KASHMIR", value: 14 },
  { label: "KARNATAKA", value: 15 },
  { label: "KERALA", value: 16 },
  { label: "LAKSHWADEEP", value: 17 },
  { label: "MADHYA PRADESH", value: 18 },
  { label: "MAHARASHTRA", value: 19 },
  { label: "MANIPUR", value: 20 },
  { label: "MEGHALAYA", value: 21 },
  { label: "MIZORAM", value: 22 },
  { label: "NAGALAND", value: 23 },
  { label: "ODISHA", value: 24 },
  { label: "PONDICHERRY", value: 25 },
  { label: "PUNJAB", value: 26 },
  { label: "RAJASTHAN", value: 27 },
  { label: "SIKKIM", value: 28 },
  { label: "TAMILNADU", value: 29 },
  { label: "TRIPURA", value: 30 },
  { label: "UTTAR PRADESH", value: 31 },
  { label: "WEST BENGAL", value: 32 },
  { label: "CHHATISHGARH", value: 33 },
  { label: "UTTARAKHAND", value: 34 },
  { label: "JHARKHAND", value: 35 },
  { label: "TELANGANA", value: 36 },
  { label: "LADAKH", value: 37 },
  { label: "OTHERS", value: 99 },
];

export const DEPARTMENT_NAMES = {
  1: "Agriculture",
  2: "Atomic Energy",
  3: "Fertilizers",
  4: "Chemicals and Petrochemicals",
  5: "Civil Aviation and Tourism",
  6: "Coal",
  7: "Consumer Affairs, Food and Public Distribution",
  8: "Commerce and Textiles",
  9: "Environment and Forests and Ministry of Earth Science",
  10: "External Affairs and Overseas Indian Affairs",
  11: "Finance",
  12: "Central Board of Direct Taxes",
  13: "Central Board of Excise and Customs",
  14: "Contoller of Aid Accounts and Audit",
  15: "Central Pension Accounting Office",
  16: "Food Processing Industries",
  17: "Health and Family Welfare",
  18: "Home Affairs and Development of North Eastern Region",
  19: "Human Resource Development",
  20: "Industry",
  21: "Information and Broadcasting",
  22: "Telecommunication and Information Technology",
  23: "Labour",
  24: "Law and Justice and Company Affairs",
  25: "Personnel, Public Grievances and Pesions",
  26: "Petroleum and Natural Gas",
  27: "Plannning, Statistics and Programme Implementation",
  28: "Power",
  29: "New and Renewable Energy",
  30: "Rural Development and Panchayati Raj",
  31: "Science And Technology",
  32: "Space",
  33: "Steel",
  34: "Mines",
  35: "Social Justice and Empowerment",
  36: "Tribal Affairs",
  37: "D/o Commerce (Supply Division)",
  38: "Shipping and Road Transport and Highways",
  39: "Urban Development, Urban Employment and Poverty Alleviation",
  40: "Water Resources",
  41: "President's Secretariat",
  42: "Lok Sabha Secretariat",
  43: "Rajya Sabha secretariat",
  44: "Election Commission",
  45: "Ministry of Defence (Controller General of Defence Accounts)",
  46: "Ministry of Railways",
  47: "Department of Posts",
  48: "Department of Telecommunications",
  49: "Andaman and Nicobar Islands Administration",
  50: "Chandigarh Administration",
  51: "Dadra and Nagar Haveli",
  52: "Goa, Daman and Diu",
  53: "Lakshadweep",
  54: "Pondicherry Administration",
  55: "Pay and Accounts Officers (Audit)",
  56: "Ministry of Non-conventional energy sources",
  57: "Government Of NCT of Delhi",
  99: "Others",
};

export const GOVERNMENT_DEDUCTOR_TYPES = [
  "A",
  "S",
  "D",
  "E",
  "G",
  "H",
  "L",
  "N",
];

export const CENTER_GOVERNMENT_DEDUCTOR_TYPES = ["A", "D", "G"];

export const DEDUCTOR_CATEGORIES_TYPES = [
  { label: "Central Government", value: "A" },
  { label: "State Government", value: "S" },
  { label: "Statutory body (Central Govt.)", value: "D" },
  { label: "Statutory body (State Govt.)", value: "E" },
  { label: "Autonomous body (Central Govt.)", value: "G" },
  { label: "Autonomous body (State Govt.)", value: "H" },
  { label: "Local Authority (Central Govt.)", value: "L" },
  { label: "Local Authority (State Govt.)", value: "N" },
  { label: "Company", value: "K" },
  { label: "Branch / Division of Company", value: "M" },
  { label: "Association of Person (AOP)", value: "P" },
  { label: "Association of Person (Trust)", value: "T" },
  { label: "Artificial Juridical Person", value: "J" },
  { label: "Body of Individuals", value: "B" },
  { label: "Individual/HUF", value: "Q" },
  { label: "Firm", value: "F" },
];

export const DEDUCTOR_CATEGORIES = {
  A: "Central Government",
  S: "State Government",
  D: "Statutory body (Central Govt.)",
  E: "Statutory body (State Govt.)",
  G: "Autonomous body (Central Govt.)",
  H: "Autonomous body (State Govt.)",
  L: "Local Authority (Central Govt.)",
  N: "Local Authority (State Govt.)",
  K: "Company",
  M: "Branch/Div of Company",
  P: "Association of Person (AOP)",
  T: "AOP (Trust)",
  J: "Artificial Juridical Person",
  B: "Body of Individuals",
  Q: "Individual/HUF",
  F: "Firm",
};

// export const DEDUCTOR_CATEGORIES_LIST = [
//   { label: "Central Government", value: "A" },
//   { label: "State Government", value: "S" },
//   { label: "Statutory body (Central Govt.)", value: "D" },
//   { label: "Statutory body (State Govt.)", value: "E" },
//   { label: "Autonomous body (Central Govt.)", value: "G" },
//   { label: "Autonomous body (State Govt.)", value: "H" },
//   { label: "Local Authority (Central Govt.)", value: "L" },
//   { label: "Local Authority (State Govt.)", value: "N" },
//   { label: "Company", value: "K" },
//   { label: "Branch / Division of Company", value: "M" },
//   { label: "Association of Person (AOP)", value: "P" },
//   { label: "Association of Person (Trust)", value: "T" },
//   { label: "Artificial Juridical Person", value: "J" },
//   { label: "Body of Individuals", value: "B" },
//   { label: "Individual/HUF", value: "Q" },
//   { label: "Firm", value: "F" },
// ];

export const DEDUCTOR_CATEGORIES_LIST = {
  A: "Central Government",
  S: "State Government",
  D: "Statutory body (Central Govt.)",
  E: "Statutory body (State Govt.)",
  G: "Autonomous body (Central Govt.)",
  H: "Autonomous body (State Govt.)",
  L: "Local Authority (Central Govt.)",
  N: "Local Authority (State Govt.)",
  K: "Company",
  M: "Branch / Division of Company",
  P: "Association of Person (AOP)",
  T: "Association of Person (Trust)",
  J: "Artificial Juridical Person",
  B: "Body of Individuals",
  Q: "Individual/HUF",
  F: "Firm",
};

export const MONTH_LIST = [
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
];

export const MONTH_NUMBER_TO_FULL_TEXT = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const NATUREOFPAYMENT = {
  "92A": "0",
  193: "7.5",
  194: "7.5",
  "94A": "7.5",
  "94B": "30",
  "94BB": "30",
  "94C": "0",
  "94D": "7.5",
  "94DA": "3.75",
  "94EE": "7.5",
  "94F": "15",
  "94G": "3.75",
  "94H": "3.75",
  "94I": "1.5",
  "94IA": "0.75",
  "94IC": "7.5",
  "94J(a)": "1.5",
  "94J": "7.5",
  "94LA": "7.5",
  "94LBA": "7.5",
  "94LBB": "22.5",
  "94LBC": "22.5",
  "94N": "2",
};

export const initialHeaderData = {
  deductor_name: "",
  tan: "",
  financial_year_formatted: "",
  quarter: "",
  form_type: "",
  full_name: "",
  user_role: "",
  header_fail: null,
};

export const CATEGOTY_LIST = [
  { label: "Woman", value: "W" },
  { label: "Other", value: "G" },
  { label: "Senior Citizen", value: "S" },
  { label: "Super Senior Citizen", value: "O" },
];

export const NATURE_COLLECTIONS = {
  193: "193",
  194: "194",
  195: "195",
  "2AA": "192A",
  "4BA": "194LBA",
  "4BB": "194BB",
  "4DA": "194DA",
  "4EE": "194EE",
  "4IA": "194I(a)",
  "4IB": "194I(b)",
  "4IC": "194IC",
  "4JA": "194J(a)",
  "4JB": "194J(b)",
  "4LB": "194LB",
  "4LC": "194LC",
  "4LD": "194LD",
  "4NF": "194NF",
  "92A": "192A",
  "92B": "192B",
  "92C": "192C",
  "94A": "194A",
  "94B": "194B",
  "94C": "194C",
  "94D": "194D",
  "94E": "194E",
  "94F": "194F",
  "94G": "194G",
  "94H": "194H",
  "94I": "194I",
  "94J": "194J",
  "94K": "194K",
  "94L": "194LA",
  "94N": "194N",
  "94O": "194O",
  "96A": "196A",
  "96B": "196B",
  "96C": "196C",
  "96D": "196D",
  "9IA": "194IA",
  A: "6CA",
  B: "6CB",
  BA1: "194LBA(a)",
  BA2: "194LBA(b)",
  C: "6CC",
  D: "6CD",
  E: "6CE",
  F: "6CF",
  G: "6CG",
  H: "6CH",
  I: "6CI",
  J: "6CJ",
  K: "6CK",
  L: "6CL",
  LBB: "194LBB",
  LBC: "194LBC",
  M: "6CM",
  N: "6CN",
  O: "6CO",
  P: "6CP",
  Q: "6CQ",
  R: "6CR",
  "94Q": "194Q",
  "94P": "194P",
  BA3: "194LBA(c)",
  "6DA": "196D(1A)",
  "94R": "194R",
  "94S": "194S",
  "4BP": "194B-P",
  "4RP": "194RP",
  "4SP": "194SP",
  "9BA": "194BA",
  "4AP": "194BA-P",
  "4NC": "194NC",
  "9FT": "194N-FT",
  LC1: "194LC(2)(i) and (ia)",
  LC2: "194LC(2)(ib)",
  LC3: "194LC(2)(ic)",
  T: "6CT",
};

export const NATURE_OF_REMITTANCE_LIST = [
  { value: "DIVIDEND", label: "Dividend" },
  { value: "FEES_SERVICES", label: "Fees From Technical Service" },
  { value: "INTEREST_PAYMENT", label: "Interest" },
  { value: "INVEST_INCOME", label: "Investment Income" },
  { value: "LTCG", label: "Long Term Capital Gain" },
  { value: "ROYALTY", label: "Royalty" },
  { value: "STCG", label: "Short Term Capital Gain" },
  { value: "OTHER", label: "Other Income" },
];

export const NATURE_OF_REMITTANCE_LIST_NEW = [
  { value: "", label: "Select" },
  { value: "DIVIDEND", label: "Dividend" },
  {
    value: "FEES_SERVICES",
    label: "Fees for Technical Services / Fees for Included Services",
  },
  { value: "INTEREST_PAYMENT", label: "Interest Payment" },
  { value: "INVEST_INCOME", label: "Investment Income" },
  { value: "LTCG", label: "Long Term Capital Gain (Others)" },
  {
    value: "LTCG_115E_NRI",
    label:
      "Long Term Capital Gain u/s 115E in case of Non Resident Indian Citizen",
  },
  {
    value: "LTCG_1121CIII",
    label: "Long Term Capital Gain u/s 112(1)(c)(iii)",
  },
  { value: "LTCG_112", label: "Long Term Capital Gain u/s 112" },
  { value: "LTCG_112A", label: "Long Term Capital Gain u/s 112A" },
  { value: "ROYALTY", label: "Royalty" },
  { value: "STCG", label: "Short Term Capital Gain" },
  { value: "STCG_111A", label: "Short Term Capital Gain u/s 111A" },
  {
    value: "OTHER",
    label: "Other Income / Other (Not in the Nature of Income)",
  },
];

export function getNatureOfRemittanceList(fy, quarter) {
  const newNatureOfRemittanceArray = [...NATURE_OF_REMITTANCE_LIST_NEW];

  if (
    Number(fy) > 2024 ||
    (Number(fy) === 2024 && ["Q2", "Q3", "Q4"].includes(quarter))
  ) {
    newNatureOfRemittanceArray.push(
      {
        value: "LTCG_115AB",
        label:
          "Long-Term Capital Gains arising from Transfer of units referred to in section 115AB",
      },
      {
        value: "LTCG_115AC",
        label:
          "Long-Term Capital Gains arising from Transfer of such bond or Global Depository Receipts referred to in section 115AC",
      }
    );
    return newNatureOfRemittanceArray;
  } else {
    return NATURE_OF_REMITTANCE_LIST_NEW;
  }
}

export const TYPE_OF_PAYMENT = [
  { value: "", label: "Select" },
  { value: "TDS_TCS_ADVANCE_TAX_100", label: "100 - Advance Tax" },
  { value: "TDS_TCS_PAYABLE_BY_TAXPAYER_200", label: "200 - Taxpayer Payable" },
  {
    value: "TDS_TCS_REGULAR_ASSESSMENT_400",
    label: "400 - Regular Assessment",
  },
];

export const TYPE_OF_PAYMENT_24Q = [
  { value: "", label: "Select" },
  { value: "TDS_TCS_PAYABLE_BY_TAXPAYER_200", label: "200 - Taxpayer Payable" },
  {
    value: "TDS_TCS_REGULAR_ASSESSMENT_400",
    label: "400 - Regular Assessment",
  },
];

export const MINOR_HEAD = [
  { value: "", label: "Select" },
  { value: "TDS_TCS_PAYABLE_BY_TAXPAYER_200", label: "200 - Taxpayer Payable" },
];

export const COUNTRTY_LIST = [
  {
    label: "Afghanistan",
    value: 1,
  },
  {
    label: "Akrotiri",
    value: 2,
  },
  {
    label: "Albania",
    value: 3,
  },
  {
    label: "Algeria",
    value: 4,
  },
  {
    label: "American samoa",
    value: 5,
  },
  {
    label: "Andorra",
    value: 6,
  },
  {
    label: "Angola",
    value: 7,
  },
  {
    label: "Anguilla",
    value: 8,
  },
  {
    label: "Antarctica",
    value: 9,
  },
  {
    label: "Antigua and barbuda",
    value: 10,
  },
  {
    label: "Argentina",
    value: 11,
  },
  {
    label: "Armenia",
    value: 12,
  },
  {
    label: "Aruba",
    value: 13,
  },
  {
    label: "Ashmore and cartier islands",
    value: 14,
  },
  {
    label: "Australia",
    value: 15,
  },
  {
    label: "Austria",
    value: 16,
  },
  {
    label: "Azerbaijan",
    value: 17,
  },
  {
    label: "Bahrain",
    value: 18,
  },
  {
    label: "Bailiwick of guernsey",
    value: 19,
  },
  {
    label: "Bailiwick of jersey",
    value: 20,
  },
  {
    label: "Baker island",
    value: 21,
  },
  {
    label: "Bangladesh",
    value: 22,
  },
  {
    label: "Barbados",
    value: 23,
  },
  {
    label: "Belarus",
    value: 24,
  },
  {
    label: "Belgium",
    value: 25,
  },
  {
    label: "Belize",
    value: 26,
  },
  {
    label: "Benin porto",
    value: 27,
  },
  {
    label: "Bermuda",
    value: 28,
  },
  {
    label: "Bhutan",
    value: 29,
  },
  {
    label: "Bolivia",
    value: 30,
  },
  {
    label: "Bosniaand herzegovina",
    value: 31,
  },
  {
    label: "Botswana",
    value: 32,
  },
  {
    label: "Bouvet island",
    value: 33,
  },
  {
    label: "Brazil",
    value: 34,
  },
  {
    label: "British indian ocean territory",
    value: 35,
  },
  {
    label: "Brunei",
    value: 36,
  },
  {
    label: "Bulgaria",
    value: 37,
  },
  {
    label: "Burkina faso",
    value: 38,
  },
  {
    label: "Burundi",
    value: 39,
  },
  {
    label: "Cambodia",
    value: 40,
  },
  {
    label: "Cameroon",
    value: 41,
  },
  {
    label: "Canada",
    value: 42,
  },
  {
    label: "Cape verde",
    value: 43,
  },
  {
    label: "Cayman islands",
    value: 44,
  },
  {
    label: "Central african republic",
    value: 45,
  },
  {
    label: "Chad",
    value: 46,
  },
  {
    label: "Chile",
    value: 47,
  },
  {
    label: "China",
    value: 48,
  },
  {
    label: "Christmas island",
    value: 49,
  },
  {
    label: "Clipperton island",
    value: 50,
  },
  {
    label: "Cocos (keeling) islands",
    value: 51,
  },
  {
    label: "Colombia",
    value: 52,
  },
  {
    label: "Commonwealth of puerto rico",
    value: 53,
  },
  {
    label: "Commonwealth of the northern mariana islands",
    value: 54,
  },
  {
    label: "Comoros",
    value: 55,
  },
  {
    label: "Congo,democratic republic of the",
    value: 56,
  },
  {
    label: "Congo,republic of the",
    value: 57,
  },
  {
    label: "Cook islands",
    value: 58,
  },
  {
    label: "Coral sea islands",
    value: 59,
  },
  {
    label: "Coral sea islands territory",
    value: 60,
  },
  {
    label: "Costa rica",
    value: 61,
  },
  {
    label: "Cote d'ivoire",
    value: 62,
  },
  {
    label: "Croatia",
    value: 63,
  },
  {
    label: "Cuba",
    value: 64,
  },
  {
    label: "Cyprus",
    value: 65,
  },
  {
    label: "Czech republic",
    value: 66,
  },
  {
    label: "Denmark",
    value: 67,
  },
  {
    label: "Departmental collectivity of mayotte",
    value: 68,
  },
  {
    label: "Dhekelia",
    value: 69,
  },
  {
    label: "Djibouti",
    value: 70,
  },
  {
    label: "Dominica",
    value: 71,
  },
  {
    label: "Dominican republic",
    value: 72,
  },
  {
    label: "East timor (timorlaste)",
    value: 73,
  },
  {
    label: "Ecuador",
    value: 74,
  },
  {
    label: "Egypt",
    value: 75,
  },
  {
    label: "Elsalvador",
    value: 76,
  },
  {
    label: "Equatorial guinea",
    value: 77,
  },
  {
    label: "Eritrea",
    value: 78,
  },
  {
    label: "Estonia",
    value: 79,
  },
  {
    label: "Ethiopia",
    value: 80,
  },
  {
    label: "Falkland islands (islas malvinas)",
    value: 81,
  },
  {
    label: "Faroe islands",
    value: 82,
  },
  {
    label: "Fiji",
    value: 83,
  },
  {
    label: "Finland",
    value: 84,
  },
  {
    label: "France",
    value: 85,
  },
  {
    label: "French guiana",
    value: 86,
  },
  {
    label: "French polynesia",
    value: 87,
  },
  {
    label: "French southern islands",
    value: 88,
  },
  {
    label: "Gabon",
    value: 89,
  },
  {
    label: "Georgia",
    value: 90,
  },
  {
    label: "Germany",
    value: 91,
  },
  {
    label: "Geurnsey",
    value: 92,
  },
  {
    label: "Ghana",
    value: 93,
  },
  {
    label: "Gibraltar",
    value: 94,
  },
  {
    label: "Greece",
    value: 95,
  },
  {
    label: "Greenland",
    value: 96,
  },
  {
    label: "Grenada",
    value: 97,
  },
  {
    label: "Guadeloupe",
    value: 98,
  },
  {
    label: "Guam",
    value: 100,
  },
  {
    label: "Guatemala",
    value: 101,
  },
  {
    label: "Guernsey",
    value: 102,
  },
  {
    label: "Guinea",
    value: 103,
  },
  {
    label: "Guineabissau",
    value: 104,
  },
  {
    label: "Guyana",
    value: 105,
  },
  {
    label: "Haiti",
    value: 106,
  },
  {
    label: "Heard island and mcdonald islands",
    value: 107,
  },
  {
    label: "Honduras",
    value: 108,
  },
  {
    label: "Hong kong",
    value: 109,
  },
  {
    label: "Howland island",
    value: 110,
  },
  {
    label: "Hungary",
    value: 111,
  },
  {
    label: "Iceland",
    value: 112,
  },
  {
    label: "India",
    value: 113,
  },
  {
    label: "Indonesia",
    value: 114,
  },
  {
    label: "Iran",
    value: 115,
  },
  {
    label: "Iraq",
    value: 116,
  },
  {
    label: "Ireland",
    value: 117,
  },
  {
    label: "Isle of man",
    value: 118,
  },
  {
    label: "Israel",
    value: 119,
  },
  {
    label: "Italy",
    value: 120,
  },
  {
    label: "Jamaica",
    value: 121,
  },
  {
    label: "Jan mayen",
    value: 122,
  },
  {
    label: "Japan",
    value: 123,
  },
  {
    label: "Jarvis island",
    value: 124,
  },
  {
    label: "Jersey",
    value: 125,
  },
  {
    label: "Johnston atoll",
    value: 126,
  },
  {
    label: "Jordan",
    value: 127,
  },
  {
    label: "Kazakhstan",
    value: 128,
  },
  {
    label: "Kenya",
    value: 129,
  },
  {
    label: "Kingman reef",
    value: 130,
  },
  {
    label: "Kiribati",
    value: 131,
  },
  {
    label: "Korea, north",
    value: 132,
  },
  {
    label: "Korea, south",
    value: 133,
  },
  {
    label: "Kosovo",
    value: 134,
  },
  {
    label: "Kuwait",
    value: 135,
  },
  {
    label: "Kyrgyzstan",
    value: 136,
  },
  {
    label: "Laos",
    value: 137,
  },
  {
    label: "Latvia",
    value: 138,
  },
  {
    label: "Lebanon",
    value: 139,
  },
  {
    label: "Lesotho",
    value: 140,
  },
  {
    label: "Liberia",
    value: 141,
  },
  {
    label: "Libya",
    value: 142,
  },
  {
    label: "Liechtenstein",
    value: 143,
  },
  {
    label: "Lithuania",
    value: 144,
  },
  {
    label: "Luxembourg",
    value: 145,
  },
  {
    label: "Macau",
    value: 146,
  },
  {
    label: "Macedonia",
    value: 147,
  },
  {
    label: "Madagascar",
    value: 148,
  },
  {
    label: "Malawi",
    value: 149,
  },
  {
    label: "Malaysia",
    value: 150,
  },
  {
    label: "Malaysia (labuan)",
    value: 151,
  },
  {
    label: "Maldives",
    value: 152,
  },
  {
    label: "Mali",
    value: 153,
  },
  {
    label: "Malta",
    value: 154,
  },
  {
    label: "Marshallislands",
    value: 155,
  },
  {
    label: "Martinique",
    value: 156,
  },
  {
    label: "Mauritania",
    value: 157,
  },
  {
    label: "Mauritius",
    value: 158,
  },
  {
    label: "Mayotte",
    value: 159,
  },
  {
    label: "United mexican states",
    value: 160,
  },
  {
    label: "Micronesia, federated states of",
    value: 161,
  },
  {
    label: "Midway islands",
    value: 162,
  },
  {
    label: "Moldova",
    value: 163,
  },
  {
    label: "Monaco",
    value: 164,
  },
  {
    label: "Mongolia",
    value: 165,
  },
  {
    label: "Montenegro",
    value: 166,
  },
  {
    label: "Montserrat",
    value: 167,
  },
  {
    label: "Morocco",
    value: 168,
  },
  {
    label: "Mozambique",
    value: 169,
  },
  {
    label: "Myanmar (burma)",
    value: 170,
  },
  {
    label: "Namibia",
    value: 171,
  },
  {
    label: "Nauru",
    value: 172,
  },
  {
    label: "Navassa island",
    value: 173,
  },
  {
    label: "Nepal",
    value: 174,
  },
  {
    label: "Netherlands",
    value: 175,
  },
  {
    label: "Netherlands antilles",
    value: 176,
  },
  {
    label: "New caledonia",
    value: 177,
  },
  {
    label: "Newzealand",
    value: 178,
  },
  {
    label: "Nicaragua",
    value: 179,
  },
  {
    label: "Niger",
    value: 180,
  },
  {
    label: "Nigeria",
    value: 181,
  },
  {
    label: "Niue",
    value: 182,
  },
  {
    label: "Norfolk island",
    value: 183,
  },
  {
    label: "Northern mariana islands",
    value: 184,
  },
  {
    label: "Norway",
    value: 185,
  },
  {
    label: "Oman",
    value: 186,
  },
  {
    label: "Pakistan",
    value: 187,
  },
  {
    label: "Palau",
    value: 188,
  },
  {
    label: "Palmyra atoll",
    value: 189,
  },
  {
    label: "Panama",
    value: 190,
  },
  {
    label: "Papua new guinea",
    value: 191,
  },
  {
    label: "Paracel islands",
    value: 192,
  },
  {
    label: "Paraguay",
    value: 193,
  },
  {
    label: "Peru",
    value: 194,
  },
  {
    label: "Philippines",
    value: 195,
  },
  {
    label: "Pitcairn islands",
    value: 196,
  },
  {
    label: "Pitcairn, henderson, ducie, and oeno islands",
    value: 197,
  },
  {
    label: "Poland",
    value: 198,
  },
  {
    label: "Portugal",
    value: 199,
  },
  {
    label: "Puerto rico",
    value: 200,
  },
  {
    label: "Qatar",
    value: 201,
  },
  {
    label: "Reunion",
    value: 202,
  },
  {
    label: "Romania",
    value: 203,
  },
  {
    label: "Russia",
    value: 204,
  },
  {
    label: "Rwanda",
    value: 205,
  },
  {
    label: "Saint barthelemy",
    value: 206,
  },
  {
    label: "Saint helena",
    value: 207,
  },
  {
    label: "Saint kitts and nevis",
    value: 208,
  },
  {
    label: "Saint lucia",
    value: 209,
  },
  {
    label: "Saint martin",
    value: 210,
  },
  {
    label: "Saint pierre and miquelon",
    value: 211,
  },
  {
    label: "Saint vincent and the grenadines",
    value: 212,
  },
  {
    label: "Samoa",
    value: 213,
  },
  {
    label: "Sanmarino",
    value: 214,
  },
  {
    label: "Sao tome and principe",
    value: 215,
  },
  {
    label: "Saudi arabia",
    value: 216,
  },
  {
    label: "Senegal",
    value: 217,
  },
  {
    label: "Serbia",
    value: 218,
  },
  {
    label: "Seychelles",
    value: 219,
  },
  {
    label: "Sierra leone",
    value: 220,
  },
  {
    label: "Singapore",
    value: 221,
  },
  {
    label: "Slovakia",
    value: 222,
  },
  {
    label: "Slovenia",
    value: 223,
  },
  {
    label: "Solomon islands",
    value: 224,
  },
  {
    label: "Somalia",
    value: 225,
  },
  {
    label: "South africa",
    value: 226,
  },
  {
    label: "South georgia and south sandwich islands",
    value: 227,
  },
  {
    label: "Spratly islands",
    value: 228,
  },
  {
    label: "Spain",
    value: 229,
  },
  {
    label: "Sri lanka",
    value: 230,
  },
  {
    label: "St. vincent & grenadines",
    value: 231,
  },
  {
    label: "Sudan",
    value: 232,
  },
  {
    label: "Suriname",
    value: 233,
  },
  {
    label: "Svalbard",
    value: 234,
  },
  {
    label: "Swaziland",
    value: 235,
  },
  {
    label: "Sweden",
    value: 236,
  },
  {
    label: "Switzerland",
    value: 237,
  },
  {
    label: "Syria",
    value: 238,
  },
  {
    label: "Taiwan",
    value: 239,
  },
  {
    label: "Tajikistan",
    value: 240,
  },
  {
    label: "Tanzania",
    value: 241,
  },
  {
    label: "Territorial collectivity of st. pierre & miquelon",
    value: 242,
  },
  {
    label: "Territory of american samoa",
    value: 243,
  },
  {
    label: "Territory of ashmore and cartier islands",
    value: 244,
  },
  {
    label: "Territory of christmas island",
    value: 245,
  },
  {
    label: "Territory of cocos (keeling) islands",
    value: 246,
  },
  {
    label: "Territory of guam",
    value: 247,
  },
  {
    label: "Territory of heard island & mcdonald islands",
    value: 248,
  },
  {
    label: "Territory of norfolk island",
    value: 249,
  },
  {
    label: "Thailand",
    value: 250,
  },
  {
    label: "The bahamas",
    value: 251,
  },
  {
    label: "The gambia",
    value: 252,
  },
  {
    label: "Togo",
    value: 253,
  },
  {
    label: "Tokelau",
    value: 254,
  },
  {
    label: "Tonga",
    value: 255,
  },
  {
    label: "Trinidad and tobago",
    value: 256,
  },
  {
    label: "Tunisia",
    value: 257,
  },
  {
    label: "Turkey",
    value: 258,
  },
  {
    label: "Turkmenistan",
    value: 259,
  },
  {
    label: "Turks and caicos islands",
    value: 260,
  },
  {
    label: "Tuvalu",
    value: 261,
  },
  {
    label: "Uganda",
    value: 262,
  },
  {
    label: "Ukraine",
    value: 263,
  },
  {
    label: "United arab emirates",
    value: 264,
  },
  {
    label: "United kingdom",
    value: 265,
  },
  {
    label: "United states virgin islands",
    value: 266,
  },
  {
    label: "United states of america",
    value: 267,
  },
  {
    label: "Uruguay",
    value: 268,
  },
  {
    label: "Uzbekistan",
    value: 269,
  },
  {
    label: "Vanuatu",
    value: 270,
  },
  {
    label: "Vatican city (holysee)",
    value: 271,
  },
  {
    label: "Venezuela",
    value: 272,
  },
  {
    label: "Vietnam",
    value: 273,
  },
  {
    label: "Virgin islands, british",
    value: 274,
  },
  {
    label: "Virgin islands, u.s.",
    value: 275,
  },
  {
    label: "Wake island",
    value: 276,
  },
  {
    label: "Wallis and futuna",
    value: 277,
  },
  {
    label: "Western sahara",
    value: 278,
  },
  {
    label: "Yemen",
    value: 279,
  },
  {
    label: "Zambia",
    value: 280,
  },
  {
    label: "Zimbabwe",
    value: 281,
  },
  {
    label: "Combodia",
    value: 282,
  },
  {
    label: "Congo",
    value: 283,
  },
  {
    label: "Ivory coast",
    value: 284,
  },
  {
    label: "West indies",
    value: 285,
  },
  {
    label: "British virgin islands",
    value: 286,
  },
];

export const PLACE_OF_SUPPLY_LIST = [
  { label: "01-Jammu and Kashmir", value: "01" },
  { label: "02-Himachal Pradesh", value: "02" },
  { label: "03-Punjab", value: "03" },
  { label: "04-Chandigarh", value: "04" },
  { label: "05-Uttarakhand", value: "05" },
  { label: "06-Haryana", value: "06" },
  { label: "07-Delhi", value: "07" },
  { label: "08-Rajasthan", value: "08" },
  { label: "09-Uttar Pradesh", value: "09" },
  { label: "10-Bihar", value: "10" },
  { label: "11-Sikkim", value: "11" },
  { label: "12-Arunachal Pradesh", value: "12" },
  { label: "13-Nagaland", value: "13" },
  { label: "14-Manipur", value: "14" },
  { label: "15-Mizoram", value: "15" },
  { label: "16-Tripura", value: "16" },
  { label: "17-Meghalaya", value: "17" },
  { label: "18-Assam", value: "18" },
  { label: "19-West Bengal", value: "19" },
  { label: "20-Jharkhand", value: "20" },
  { label: "21-Odisha", value: "21" },
  { label: "22-Chattisgarh", value: "22" },
  { label: "23-Madhya Pradesh", value: "23" },
  { label: "24-Gujarat", value: "24" },
  { label: "26-Dadra and Nagar Haveli and Daman and Diu", value: "26" },
  { label: "27-Maharashtra", value: "27" },
  { label: "28-Andhra Pradesh", value: "28" },
  { label: "29-Karnataka", value: "29" },
  { label: "30-Goa", value: "30" },
  { label: "31-Lakshadweep", value: "31" },
  { label: "32-Kerala", value: "32" },
  { label: "33-Tamil Nadu", value: "33" },
  { label: "34-Puducherry", value: "34" },
  { label: "35-Andaman AND Nicobar Islands", value: "35" },
  { label: "36-Telangana", value: "36" },
  { label: "37-Andhra Pradesh", value: "37" },
  { label: "38-Ladakh", value: "38" },
  { label: "97-Other Territory", value: "97" },
];
export const ADDON_GST_TAX_RATE = 18.0;
export const MAX_ENT_ALLOWANCE_DEDUCTION = "5000.00";
export const MAX_PROFESSIONAL_ALLOWANCE_DEDUCTION = "5000.00";

export const SHORT_DEDUCTIONS_26Q = [
  { label: "Short Rate Due To", value: "36", child: ["41", "42", "43", "44"] },
  {
    label: "PANNOTAVBL",
    value: "41",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Inoperative",
    value: "42",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Non Filer PAN",
    value: "43",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Others",
    value: "44",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  { label: "Short Amount", value: "35" },
];

export const SHORT_DEDUCTIONS_27Q = [
  { label: "Short Rate Due To", value: "36", child: ["41", "42", "43", "44"] },
  {
    label: "PANNOTAVBL",
    value: "41",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Inoperative",
    value: "42",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Non Filer PAN",
    value: "43",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Others",
    value: "44",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  { label: "Short Amount", value: "35" },
];

export const SHORT_DEDUCTIONS_27EQ = [
  { label: "Short Rate Due To", value: "36", child: ["41", "42", "43", "44"] },
  {
    label: "PANNOTAVBL",
    value: "41",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Inoperative",
    value: "42",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Non Filer PAN",
    value: "43",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  {
    label: "Others",
    value: "44",
    parent: "36",
    allChild: ["41", "42", "43", "44"],
  },
  { label: "Short Amount", value: "35" },
];

// export const SHORT_DEDUCTIONS_26Q = [
//   { label: 'Non Filer (206CCA/AB)', value: '1' },
//   { label: 'Inoperative', value: '34' },
//   { label: 'Short Rate', value: '36' },
//   { label: 'Short Amount', value: '35' },
//   { label: 'PANNOTAVBL', value: '37' },
//   { label: 'LDC', value: '39' },
// ];

// export const SHORT_DEDUCTIONS_27Q = [
//   { label: 'Non Filer as per 206AB', value: '1' },
//   { label: 'Inoperative', value: '34' },
//   { label: 'Short Rate', value: '36' },
//   { label: 'Short Amount', value: '35' },
//   { label: 'PANNOTAVBL', value: '37' },
//   { label: 'LDC', value: '39' },
// ];

// export const SHORT_DEDUCTIONS_27EQ = [
//   { label: 'Inoperative PAN', value: '34' },
//   { label: 'Short Rate', value: '36' },
//   { label: 'Short Amount', value: '35' },
//   { label: 'PANNOTAVBL', value: '37' },
//   { label: 'LDC', value: '39' },
// ];

export const HIGHER_DEDUCTIONS_26Q = [
  { label: "Due to Rate", value: "45" },
  { label: "Due to Amount", value: "46" },
];

export const HIGHER_DEDUCTIONS_27Q = [
  { label: "Due to Rate", value: "45" },
  { label: "Due to Amount", value: "46" },
];

export const HIGHER_DEDUCTIONS_27EQ = [
  { label: "Due to Rate", value: "45" },
  { label: "Due to Amount", value: "46" },
];

// export const HIGHER_DEDUCTIONS_26Q = [
//   { label: "Non-Availability of PAN", value: "2" },
//   { label: "Section 206AB", value: "3" },
//   { label: "Excess TDS Deducted", value: "38" },
// ];

// export const HIGHER_DEDUCTIONS_27Q = [
//   { label: "Non-Availability of PAN", value: "2" },
//   { label: "Section 206AB", value: "3" },
//   //{ label: "Section 206CCA", value: "4" },
//   { label: "Excess TDS Deducted", value: "38" },
// ];
// export const HIGHER_DEDUCTIONS_27EQ = [
//   { label: "Non-Availability of PAN", value: "2" },
//   { label: "Section 206AB", value: "3" },
//   { label: "Section 206CCA", value: "4" },
//   { label: "Excess TDS Deducted", value: "38" },
// ];

export const LOWER_DEDUCTIONS = [
  { label: "Due to 197", value: "5" },
  { label: "Payment made to notified Person", value: "6" },
  { label: "Second Provison of 194N", value: "7" },
];

export const NO_DEDUCTIONS_27EQ = [
  { label: "Due to 206C(1A)", value: "8" },
  { label: "Due to first proviso to 206C(1G)", value: "9" },
  { label: "Due to fourth proviso to 206C(1G)", value: "10" },
  { label: "Due to clauses of Fifth proviso to 206(1G)", value: "11" },
  { label: "Due to Second Proviso to 206(IH)", value: "12" },
  { label: "Due to A, B, C Subclause", value: "13" },
];

export const NO_DEDUCTIONS_27Q = [
  { label: "Due to 197A", value: "14" },
  { label: "Due to Clause of provision 194N", value: "15" },
  { label: "Due to 194LBA(2A)", value: "16" },
  { label: "Due to Second Provision to 194N", value: "17" },
  { label: "Due to clause of 197A(1D)", value: "18" },
  { label: "Due to 196D(2)", value: "19" },
  { label: "Due to proviso to 196D(1A)", value: "20" },
];

export const NO_DEDUCTIONS_26Q = [
  { label: "Due to 197A", value: "21" },
  { label: "Transporter", value: "22" },
  { label: "Due to 197A(1F)", value: "23" },
  { label: "Due to Clause of provision 194N", value: "24" },
  { label: "Due to 194(5)", value: "25" },
  { label: "Due to 194LBA(2A)", value: "26" },
  { label: "Due to Second Provision to 194N", value: "27" },
  { label: "Due to payment made as mentioned in circular", value: "28" },
  { label: "Due to Clause of second proviso to 194", value: "29" },
  { label: "Due to Clause of 194A(3)", value: "30" },
];

export const NO_DEDUCTIONS_24Q = [{ label: "Due to 197", value: "31" }];

export const FORM_TYPE_LIST = [
  { label: "26Q", value: "26Q" },
  { label: "24Q", value: "24Q" },
  { label: "27EQ", value: "27EQ" },
  { label: "27Q", value: "27Q" },
];
export const QUARTER_LIST = [
  { label: "Q1", value: "Q1" },
  { label: "Q2", value: "Q2" },
  { label: "Q3", value: "Q3" },
  { label: "Q4", value: "Q4" },
];

export const DEDUCTEE_TYPE_REGULAR_RETURN = [
  { label: "Company, other than domestic company", value: "COMPANY_01" },
  { label: "Individual", value: "INDIVIDUAL_02" },
  { label: "Hindu Undivided Family", value: "HUF_03" },
  { label: "Association of Persons (AOP)-No Company Member", value: "AOP_04" },
  {
    label: "Association of Persons (AOP)-Only Company Members",
    value: "AOP_05",
  },
  { label: "Co-operative Society", value: "COOP_06" },
  { label: "Firm", value: "FIRM_07" },
  { label: "Body of individuals", value: "BOI_08" },
  { label: "Artificial juridical person-AJP", value: "AJP_09" },
  { label: "Others", value: "OTH_10" },
];
export const DEDUCTEE_TYPE_CORRECTION_RETURN = [
  { label: "Company", value: "COMPANY" },
  { label: "Other than Company", value: "OTHER_THAN_COMPANY" },
  { label: "Company, other than domestic company", value: "COMPANY_01" },
  { label: "Individual", value: "INDIVIDUAL_02" },
  { label: "Hindu Undivided Family", value: "HUF_03" },
  { label: "Association of Persons (AOP)-No Company Member", value: "AOP_04" },
  {
    label: "Association of Persons (AOP)-Only Company Members",
    value: "AOP_05",
  },
  { label: "Co-operative Society", value: "COOP_06" },
  { label: "Firm", value: "FIRM_07" },
  { label: "Body of individuals", value: "BOI_08" },
  { label: "Artificial juridical person-AJP", value: "AJP_09" },
  { label: "Others", value: "OTH_10" },
];

export const CHART_LIST = {
  cnt_valid_pan: "PAN Valid",
  cnt_unverified_pan: "Unverified PAN",
  cnt_applied_pan: "PAN Applied",
  cnt_invalid_pan: "PAN Invalid",
  cnt_not_available_pan: "PAN Not Available",
  cnt_inactive_pan: "PAN Inactive",
};

export const PIECHART_LIST = {
  cnt_inactive_pan: "Inoperative",
  cnt_valid_pan: "Operative",
  cnt_traces_invalid_pan: "Invalid",
  cnt_unverified_pan: "Unverified",
  cnt_not_available_pan: "PANNOTAVBL",
};

export const COMPLIANCE_PIECHART_LIST = {
  cnt_insights_regular_filer_operative: "Regular Filer (Operative)",
  cnt_insights_regular_filer_inoperative: "Regular Filer (Inoperative)",
  cnt_insights_non_filer_operative: "Non Filer (Operative)",
  cnt_insights_non_filer_inoperative: "Non Filer (Inoperative)",
  cnt_insights_pending: "Pending",
  cnt_traces_invalid_pan: "Invalid",
};

export const LATE_DEDUCTION_INTEREST_RATE = 1.0;
export const LATE_PAYMENT_INTEREST_RATE = 1.5;
export const DASHBOARD_PATH = `${BASE_PATH}deductor`;

export const FILE_LIST_PROTOTYPE = {
  "24Q": { Q1: null, Q2: null, Q3: null, Q4: null },
  "26Q": { Q1: null, Q2: null, Q3: null, Q4: null },
  "27EQ": { Q1: null, Q2: null, Q3: null, Q4: null },
  "27Q": { Q1: null, Q2: null, Q3: null, Q4: null },
};

export const DIFF_LAST_SYNC_DAYS = 7;
export const DIFF_LAST_QTRSYNC_DAYS = 3;

export const PASSWORD_ENCRYPTION_KEY = CFG_PASSWORD_ENCRYPTION_KEY;
export const PASSWORD_ENCRYPTION_IV = CFG_PASSWORD_ENCRYPTION_IV;

export const ACCOUNT_LOCKED_MESSAGE =
  "Your account is locked due to incorrect password attempts. Please reset your password.";

export const SALARY_HEADER_ROW_COLUMNS = CFG_SALARY_HEADER_ROW_COLUMNS;
export const SALARY_HEADER_ROW_ALLOWED_COLUMNS_COUNT =
  CFG_SALARY_HEADER_ROW_ALLOWED_COLUMNS_COUNT;
export const MAX_SALARY_ROW_CHECK = CFG_MAX_SALARY_ROW_CHECK;
export const MAX_SALARY_TDS_CAL_TEMPLATE_COUNT =
  CFG_MAX_SALARY_TDS_CAL_TEMPLATE_COUNT;

//Form3CD start year
export const FORM_3CD_START_YEAR = 2023;

export const INSIGHT_NEW_REG_STATE_LIST = [
  { value: 1, label: "ANDAMAN AND NICOBAR ISLANDS" },
  { value: 2, label: "ANDHRA PRADESH" },
  { value: 3, label: "ARUNACHAL PRADESH" },
  { value: 4, label: "ASSAM" },
  { value: 5, label: "BIHAR" },
  { value: 6, label: "CHANDIGARH" },
  { value: 33, label: "CHHATTISGARH" },
  { value: 7, label: "DADRA & NAGAR HAVELI" },
  { value: 8, label: "DAMAN & DIU" },
  { value: 9, label: "DELHI" },
  { value: 10, label: "GOA" },
  { value: 11, label: "GUJARAT" },
  { value: 12, label: "HARYANA" },
  { value: 13, label: "HIMACHAL PRADESH" },
  { value: 14, label: "JAMMU & KASHMIR" },
  { value: 35, label: "JHARKHAND" },
  { value: 15, label: "KARNATAKA" },
  { value: 16, label: "KERALA" },
  { value: 37, label: "LADAKH" },
  { value: 17, label: "LAKSHADWEEP" },
  { value: 18, label: "MADHYA PRADESH" },
  { value: 19, label: "MAHARASHTRA" },
  { value: 20, label: "MANIPUR" },
  { value: 21, label: "MEGHALAYA" },
  { value: 22, label: "MIZORAM" },
  { value: 23, label: "NAGALAND" },
  { value: 24, label: "ORISSA" },
  { value: 25, label: "PONDICHERRY" },
  { value: 26, label: "PUNJAB" },
  { value: 27, label: "RAJASTHAN" },
  { value: 28, label: "SIKKIM" },
  { value: 29, label: "TAMILNADU" },
  { value: 36, label: "TELANGANA" },
  { value: 30, label: "TRIPURA" },
  { value: 34, label: "UTTARAKHAND" },
  { value: 31, label: "UTTAR PRADESH" },
  { value: 32, label: "WEST BENGAL" },
  { value: 99, label: "STATE OUTSIDE INDIA" },
];

export const INSIGHT_NEW_REG_COUNTRTY_LIST = [
  { value: 91, label: "INDIA" },
  { value: 93, label: "AFGHANISTAN" },
  { value: 1907, label: "ALASKA" },
  { value: 355, label: "ALBANIA" },
  { value: 213, label: "ALGERIA" },
  { value: 376, label: "ANDORRA" },
  { value: 244, label: "ANGOLA" },
  { value: 1264, label: "ANGUILLA" },
  { value: 1268, label: "ANTIGUA" },
  { value: 54, label: "ARGENTINA" },
  { value: 374, label: "ARMENIA" },
  { value: 297, label: "ARUBA" },
  { value: 247, label: "ASCENSION" },
  { value: 61, label: "AUSTRALIA" },
  { value: 43, label: "AUSTRIA" },
  { value: 994, label: "AZERBAIJAN REPUBLIC" },
  { value: 351, label: "AZORES" },
  { value: 1242, label: "BAHAMAS" },
  { value: 973, label: "BAHARIN" },
  { value: 880, label: "BANGLADESH" },
  { value: 1246, label: "BARBADOS" },
  { value: 375, label: "BELARUS" },
  { value: 32, label: "BELGIUM" },
  { value: 501, label: "BELIZE" },
  { value: 229, label: "BENIN" },
  { value: 1441, label: "BERMUDA" },
  { value: 975, label: "BHUTAN" },
  { value: 591, label: "BOLIVIA" },
  { value: 4, label: "BOPUPATSWANA" },
  { value: 387, label: "BOSNIA & HERZEGOVINA" },
  { value: 267, label: "BOTSWANA, REPUBLIC OF" },
  { value: 55, label: "BRAZIL" },
  { value: 673, label: "BRUNEI" },
  { value: 359, label: "BULGARIA" },
  { value: 226, label: "BURKINA FASSO" },
  { value: 257, label: "BURUNDI" },
  { value: 237, label: "CAMEROON" },
  { value: 1, label: "CANADA" },
  { value: 34, label: "CANARY ISLAND" },
  { value: 238, label: "CAPE VERDE" },
  { value: 1345, label: "CAYMAN ISLAND" },
  { value: 236, label: "CENTRAL AFRICAN REPUBLIC" },
  { value: 235, label: "CHAD" },
  { value: 56, label: "CHILE" },
  { value: 86, label: "CHINA" },
  { value: 9, label: "CHRISTMAS ISLAND" },
  { value: 27, label: "CISKEI" },
  { value: 672, label: "COCOSKEELING ISLAND" },
  { value: 57, label: "COLOMBIA" },
  { value: 270, label: "COMOROS" },
  { value: 242, label: "CONGO" },
  { value: 682, label: "COOK ISLAND" },
  { value: 506, label: "COSTA RICA" },
  { value: 385, label: "CROATIA" },
  { value: 53, label: "CUBA" },
  { value: 357, label: "CYPRUS" },
  { value: 420, label: "CZECH REPUBLIC" },
  { value: 45, label: "DENMARK" },
  { value: 246, label: "DIEGO GARCIA" },
  { value: 253, label: "DJIBOUTI" },
  { value: 1809, label: "DOMANICCAN REPUBLIC" },
  { value: 1767, label: "DOMINICA ISLAND" },
  { value: 670, label: "EAST TIMOR" },
  { value: 593, label: "ECUADOR" },
  { value: 20, label: "EGYPT" },
  { value: 503, label: "EL SALVADOR" },
  { value: 240, label: "EQUATORIAL GUINEA" },
  { value: 291, label: "ERITREA" },
  { value: 372, label: "ESTONIA" },
  { value: 251, label: "ETHIOPIA" },
  { value: 500, label: "FALKLAND ISLAND" },
  { value: 298, label: "FAROE ISLAND" },
  { value: 679, label: "FIJI REPUBLIC" },
  { value: 358, label: "FINLAND" },
  { value: 33, label: "FRANCE" },
  { value: 594, label: "FRENCH GUIANA" },
  { value: 689, label: "FR POLYNESIA" },
  { value: 241, label: "GABONESE REPUBLIC" },
  { value: 220, label: "GAMBIA" },
  { value: 995, label: "GEORGIA" },
  { value: 49, label: "GERMANY" },
  { value: 233, label: "GHANA" },
  { value: 350, label: "GIBRALTOR" },
  { value: 30, label: "GREECE" },
  { value: 299, label: "GREENLAND" },
  { value: 1473, label: "GRENEDA" },
  { value: 590, label: "GUADELOPE" },
  { value: 1671, label: "GUAM" },
  { value: 502, label: "GUATEMALA" },
  { value: 245, label: "GUINEA BISSAU" },
  { value: 224, label: "GUINEA REPUBLIC" },
  { value: 592, label: "GUYANA REPUBLIC" },
  { value: 509, label: "HAITI REPUBLIC" },
  { value: 1808, label: "HAWAII" },
  { value: 504, label: "HONDURAS" },
  { value: 852, label: "HONGKONG" },
  { value: 36, label: "HUNGARY" },
  { value: 354, label: "ICELAND" },
  { value: 62, label: "INDONESIA" },
  { value: 98, label: "IRAN" },
  { value: 964, label: "IRAQ" },
  { value: 353, label: "IRELAND" },
  { value: 972, label: "ISRAEL" },
  { value: 5, label: "ITALY" },
  { value: 225, label: "IVORY COAST (COTE D' IVORIE)" },
  { value: 1876, label: "JAMAICA" },
  { value: 81, label: "JAPAN" },
  { value: 962, label: "JORDAN" },
  { value: 855, label: "KAMPUCHEA (CAMBODIA)" },
  { value: 7, label: "KAZAKISTAN" },
  { value: 254, label: "KENYA" },
  { value: 996, label: "KIRGHISTAN" },
  { value: 686, label: "KIRIBATI" },
  { value: 965, label: "KUWAIT" },
  { value: 856, label: "LAOS" },
  { value: 371, label: "LATVIA" },
  { value: 961, label: "LEBANON" },
  { value: 266, label: "LESOTHO" },
  { value: 231, label: "LIBERIA" },
  { value: 218, label: "LIBYA" },
  { value: 423, label: "LIECHTENSTEIN" },
  { value: 370, label: "LITHVANIA" },
  { value: 352, label: "LUXUMBURG" },
  { value: 853, label: "MACAO" },
  { value: 389, label: "MACEDONIA" },
  { value: 261, label: "MADAGASCAR" },
  { value: 13, label: "MADEIRA ISLAND" },
  { value: 265, label: "MALAWI" },
  { value: 60, label: "MALAYSIA" },
  { value: 960, label: "MALDIVES" },
  { value: 223, label: "MALI" },
  { value: 356, label: "MALTA" },
  { value: 976, label: "MANGOLIA" },
  { value: 1670, label: "MARIANA ISLAND" },
  { value: 692, label: "MARSHALL ISLAND" },
  { value: 596, label: "MARTINIQUE" },
  { value: 222, label: "MAURITANIA" },
  { value: 230, label: "MAURITIUS" },
  { value: 269, label: "MAYOTTE" },
  { value: 52, label: "MEXICO" },
  { value: 691, label: "MICRONESIA" },
  { value: 373, label: "MOLDOVA" },
  { value: 377, label: "MONACO" },
  { value: 382, label: "MONTENEGRO" },
  { value: 1664, label: "MONTSERRAT" },
  { value: 212, label: "MOROCCO" },
  { value: 258, label: "MOZAMBIQUE" },
  { value: 95, label: "MYANMAR" },
  { value: 264, label: "NAMIBIA" },
  { value: 674, label: "NAURU" },
  { value: 977, label: "NEPAL" },
  { value: 31, label: "NETHERLANDS" },
  { value: 599, label: "NETHERLANDS ANTHILLES" },
  { value: 687, label: "NEW CALEDONIA" },
  { value: 64, label: "NEW ZEALAND" },
  { value: 505, label: "NICARAGUA" },
  { value: 227, label: "NIGER" },
  { value: 234, label: "NIGERIA" },
  { value: 683, label: "NIUE ISLAND" },
  { value: 15, label: "NORFORK ISLAND" },
  { value: 850, label: "NORTH KOREA" },
  { value: 47, label: "NORWAY" },
  { value: 968, label: "OMAN" },
  { value: 9999, label: "OTHERS" },
  { value: 92, label: "PAKISTAN" },
  { value: 680, label: "PALAU" },
  { value: 970, label: "PALESTINE" },
  { value: 507, label: "PANAMA" },
  { value: 675, label: "PAPUA NEW GUINEA" },
  { value: 595, label: "PARAGUAY" },
  { value: 51, label: "PERU" },
  { value: 63, label: "PHILIPPINES" },
  { value: 48, label: "POLAND" },
  { value: 14, label: "PORTUGAL" },
  { value: 1787, label: "PUERTO RICO" },
  { value: 974, label: "QATAR" },
  { value: 262, label: "REUNION" },
  { value: 10, label: "RODRIGUES ISLAND" },
  { value: 40, label: "ROMANIA" },
  { value: 8, label: "RUSSIA" },
  { value: 250, label: "RWANDESE REPUBLIC" },
  { value: 684, label: "SAMOA AMERICAN" },
  { value: 685, label: "SAMOA WESTERN" },
  { value: 378, label: "SAN MARINO" },
  { value: 966, label: "SAUDI ARABIA" },
  { value: 221, label: "SENEGAL" },
  { value: 381, label: "SERBIA" },
  { value: 248, label: "SEYCHELLES" },
  { value: 232, label: "SIERRALEONE" },
  { value: 65, label: "SINGAPORE" },
  { value: 421, label: "SLOVAK REPUBLIC" },
  { value: 386, label: "SLOVENIA" },
  { value: 677, label: "SOLOMAN ISLAND" },
  { value: 252, label: "SOMALIA DEMOCRATIC REPUBLIC" },
  { value: 28, label: "SOUTH AFRICA" },
  { value: 82, label: "SOUTH KOREA" },
  { value: 211, label: "SOUTH SUDAN" },
  { value: 35, label: "SPAIN" },
  { value: 94, label: "SRILANKA" },
  { value: 290, label: "ST. HELENA" },
  { value: 1869, label: "ST. KITTS/NAVIS ISLAND" },
  { value: 1758, label: "ST. LUCIA" },
  { value: 508, label: "ST. PIERRE & MIQUELIOM" },
  { value: 239, label: "ST. TOME & PRINCEP" },
  { value: 1784, label: "ST. VINCENT & THE GRENADIAN" },
  { value: 249, label: "SUDAN" },
  { value: 597, label: "SURINAM" },
  { value: 268, label: "SWAZILAND" },
  { value: 46, label: "SWEDEN" },
  { value: 41, label: "SWITZERLAND" },
  { value: 963, label: "SYRIA" },
  { value: 886, label: "TAIWAN" },
  { value: 255, label: "TANZANIA" },
  { value: 992, label: "TAZAKISTAN" },
  { value: 66, label: "THAILAND" },
  { value: 228, label: "TOGOLESE REPUBLIC" },
  { value: 690, label: "TOKELAU ISLAND" },
  { value: 676, label: "TONGA" },
  { value: 29, label: "TRANSKEI" },
  { value: 1868, label: "TRINIDAD & TOBAGO" },
  { value: 216, label: "TUNISIA" },
  { value: 90, label: "TURKEY" },
  { value: 993, label: "TURKMENISTAN" },
  { value: 1649, label: "TURKS & CAICOS ISLANDS" },
  { value: 688, label: "TUVALU" },
  { value: 971, label: "UAE" },
  { value: 256, label: "UGANDA" },
  { value: 44, label: "UK" },
  { value: 380, label: "UKRAINE" },
  { value: 598, label: "URUGUAY" },
  { value: 2, label: "USA" },
  { value: 998, label: "UZBEKISTAN" },
  { value: 678, label: "VANAUTU" },
  { value: 6, label: "VATICAN CITY" },
  { value: 3, label: "VENDA" },
  { value: 58, label: "VENEZUELA" },
  { value: 84, label: "VIETNAM" },
  { value: 1284, label: "VIRGIN ISLAND (BRI)" },
  { value: 1340, label: "VIRGIN ISLAND (USA)" },
  { value: 681, label: "WALLIS & FUTUNA ISLAND" },
  { value: 967, label: "YEMEN" },
  { value: 243, label: "ZAIRE" },
  { value: 260, label: "ZAMBIA" },
  { value: 263, label: "ZIMBABWE" },
];

export const SECTION195_CHANGESRATES = {
   68: {
    description: "195 - Payment for LTCG as per Section 112A",
    rate: "12.5",
   },
   70: {
    description: "195 - Payment for LTCG (except section 10(33), 10(36) and 112A)",
    rate: "12.5",
   },
   69: {
    description: "195 - Payment for STCG as per Section 111A",
    rate: "20",
   },
   67: {
    description: "195 - Payment to NRI for unlisted securities / shared of non-public company",
    rate: "12.5",
   },
}
export const NATURE_OF_REMITTANCE_RATE = {
  LTCG_115E_NRI: {
    description: "Long Term Capital Gain u/s 115E in case of Non Resident Indian Citizen",
    rate: "12.5",
   },
   LTCG_112A: {
    description: "Long Term Capital Gain u/s 112A",
    rate: "12.5",
   },
   STCG_111A: {
    description: "Short Term Capital Gain u/s 111A",
    rate: "20",
   },
   LTCG_1121CIII: {
    description: "Long Term Capital Gain u/s 112(1)(c)(iii)",
    rate: "12.5",
   },
}


