import axios from "axios";
import { BASE_URL, BASE_URL_GO } from "../app/constants";
import {
  handleSuccess,
  handleErrorWithCode,
  getFirstErrorWithCode,
} from "./responseHandler";
import { getConfig } from "./common";

export async function getChallansDetails(data) {
  const url = `${BASE_URL_GO}/api/v1/challan/get-details`;

  try {
    const challans = await axios.post(url, data, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw (challans.data.message);
    }

    return handleSuccess(challans.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getCorrectionChallans(data) {
  const url = `${BASE_URL_GO}/api/v1/correction/challan/list`;

  try {
    const challans = await axios.post(url, data, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw (challans.data.message);
    }

    return handleSuccess(challans.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function addChallan(data) {
  const url = `${BASE_URL_GO}/api/v1/challan`;

  try {
    const challans = await axios.post(url, data, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw challans.data.errors;
    }

    return challans.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateChallan(data) {
  const url = `${BASE_URL_GO}/api/v1/challan`;

  try {
    const challans = await axios.put(url, data, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw getFirstErrorWithCode(challans.data.errors);
    }

    return challans.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateCorrectionChallan(data) {
  const url = `${BASE_URL_GO}/api/v1/correction/challan`;

  try {
    const challans = await axios.put(url, data, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw getFirstErrorWithCode(challans.data.errors);
    }

    return challans.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getNatureOfPaymentCodes(getParams = "") {
  const url = `${BASE_URL_GO}/api/v1/nature-of-payment/all${getParams}`;

  try {
    const challans = await axios.get(url, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw (challans.data.message);
    }

    return challans.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getChallanDetail(deductorId, challanId, returnId = '') {
  const url = `${BASE_URL}/api/v1/challan/details?deductor_id=${deductorId}&challan_id=${challanId}${returnId && '&return_id=' + returnId}`;

  try {
    const challans = await axios.get(url, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw (challans.data.message);
    }

    return challans.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getCorrectionChallanDetail(deductorId, challanId, returnId = '') {
  const url = `${BASE_URL_GO}/api/v1/correction/challan/details?deductor_id=${deductorId}&challan_id=${challanId}${returnId && '&return_id=' + returnId}`;

  try {
    const challans = await axios.get(url, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw (challans.data.message);
    }

    return challans.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function searchBSRCodes(bsrCode) {
  const url = `${BASE_URL_GO}/api/v1/bsr-codes?bsr_code=${bsrCode}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function challanUsingConnector(data) {
  const url = `${BASE_URL_GO}/api/v1/usuc/efiling/create-online-challan`;

  try {
    const challans = await axios.post(url, data, getConfig());
    if (Number(challans.data.status) !== 1) {
      throw challans.data.message;
    }

    return challans;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getChallansListV2(data) {
  const url = `${BASE_URL_GO}/api/v1/challan/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getMappedTransactionListV2(data) {
  const url = `${BASE_URL_GO}/api/v2/transaction/mapped-trans`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function returnHealthGetChallanWiseMappedTransactionList(data) {
  const url = `${BASE_URL_GO}/api/v1/report/health/challan-summary`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getChallansInterestAllocationList(data) {
  const url = `${BASE_URL_GO}/api/v1/challan/list-for-interest-allocation`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

 export async function getInterestMappingLateLiabilitySmmary(getParams) {
   const url = `${BASE_URL_GO}/api/v2/transaction/late-liability${getParams}`;

   try {
     const result = await axios.get(url, getConfig());
     if (Number(result.data.status) !== 1) {
       throw (result.data.message);
     }

     return handleSuccess(result.data);
   } catch (err) {
     throw handleErrorWithCode(err);
   }
 }

 export async function saveInterestAllocationData(data) {
  const url = `${BASE_URL_GO}/api/v1/challan/interest-allocation`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getConsumptionDetailsInChallan(data) {
  const url = `${BASE_URL_GO}/api/v1/challan/get-consumption-detail`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}